import ISelect from "@/components/i-components/ISelect/ISelect";
import { usePlatform } from "@/utils/composables";
import { computed } from "@vue/reactivity";
import {
    defineComponent,
    onMounted,
    PropType,
    ref,
    toRaw,
    watch,
    watchEffect,
} from "vue";
import ICascader from "../ICascader/ICascader";
import { ICheckboxGroup } from "../ICheckbox";
import { IRadioGroup } from "../IRadio";
import {
    OptionLoader,
    OptionLoaderParams,
    OptionType,
    ReferLoaderAction,
    TreeReferItem,
    ValidationStatus,
} from "../Presenters/declare";
import "./style.less";
import { debounce } from "lodash-es";
import ISelectTree from "@/components/i-components/ISelectTree/ISelectTree";
import { LegacyDataNode } from "@intasect/ant-design-vue/es/vc-tree-select/TreeSelect";
import ITree from "../ITree/ITree";
import { EventDataNode } from "@intasect/ant-design-vue/es/tree";

const loadingOptions: OptionType = {
    label: "载入中...",
    value: "__loading%$",
    disabled: true,
};

const defaultFieldNames = {
    label: "label",
    value: "value",
    key: "id",
    children: "children",
};

const initEmpty: any[] = [];

export default defineComponent({
    name: "i-refer",
    components: {
        ISelect,
        ICascader,
        ISelectTree,
    },
    props: {
        //选项加载的钩子函数 用于【最终】处理选项结果
        referResolve: {
            type: Function as PropType<
                (items: OptionType[]) => Promise<OptionType[]> | OptionType[]
            >,
        },
        //是否多选
        multi: { type: Boolean, default: false },
        //值
        value: {
            type: [Array, String, Number, Boolean] as PropType<
                Array<string | number | boolean> | string | number | boolean
            >,
        },
        //空白显示描述
        placeholder: { type: String, default: "请选择" },
        //是否禁用
        disabled: { type: Boolean, default: false },
        //引用类型 字典/自定义/资源/默认（defaultOptions不为空）
        referType: {
            type: String as PropType<"enum" | "dict" | "custom" | "resource">,
            default: "custom",
        },
        //显示类型 下拉/复选框/单选框/级联选择/树形选择
        displayType: {
            type: String as PropType<
                | "select"
                | "checkbox"
                | "radio"
                | "cascader"
                | "treeSelect"
                | "tree"
            >,
            default: "select",
        },
        //默认的选项
        defaultOptions: {
            type: Array as PropType<OptionType[]>,
            default: () => [],
        },
        //字典类型
        dictType: String,
        //字典编码
        dictCode: String,
        //字典参数
        dicParams: {
            type: Function as PropType<() => Promise<any[]> | any[]>,
        },
        //资源编码
        resourceCode: {
            type: String,
        },
        //资源参数
        resourceParams: {
            type: Function as PropType<() => Promise<any[]> | any[]>,
            default: () => [],
        },
        //企业id
        tenantsId: String,
        allowClear: {
            type: Boolean,
            default: true,
        },
        status: {
            type: String as PropType<ValidationStatus>,
            default: "default",
        },
        //选项的自定义加载逻辑
        loader: {
            type: Function as PropType<OptionLoader>,
        },
        maxTagCount: {
            type: [Number, String] as PropType<number | "responsive">,
            default: "responsive",
        },
        maxTagTextLength: {
            type: Number,
            default: 6,
        },
        showSearch: {
            type: Boolean,
            default: false,
        },
        valueResolve: {
            type: Function as PropType<(option: OptionType) => void>,
        },
        autoClear: {
            type: Boolean,
            default: true,
        },
        fieldNames: {
            type: Object as PropType<{
                label: string;
                value: string;
                key: string;
                children: string;
            }>,
            default: () => defaultFieldNames,
        },
    },
    emits: ["update:value", "change", "init", "optionsInit"],
    setup(props, context) {
        const classNames = computed(() => {
            return `i-refer i-refer-${props.status}`;
        });
        const optionsInit = (e?: any) => {
            context.emit("optionsInit", e);
        };
        const change = (e?: any) => {
            if (props.value !== e) {
                context.emit("update:value", e);
                context.emit("change", e);
            }
        };
        const loading = ref(false);
        const platform = usePlatform();
        const options = ref<OptionType[]>(props.defaultOptions);
        const loaded = ref(false);
        const appendEnd = ref(false);
        const isProcess = ref(false);
        const referValue = ref();
        const referDisabled = ref(false);
        let lasteTriggler: ReferLoaderAction = "init";
        const loadDicOptions = async () => {
            let dicList: OptionType[] = [];
            if (props.dicParams) {
                const params = await props.dicParams.call(undefined);
                dicList = (await platform.getDicCache(...params)).filter(a => {
                    if (props.dictCode) {
                        return a.dictDataCode === props.dictCode;
                    }
                    return true;
                });
            } else if (props.dictType) {
                dicList = (
                    await platform.getDicCache(props.dictType, props.tenantsId)
                ).filter(a => {
                    if (props.dictCode) {
                        return a.dictDataCode === props.dictCode;
                    }
                    return true;
                });
            }
            return dicList;
        };

        const commonLoader = async (
            action: OptionLoader,
            { trigger, parentNode, keyword }: OptionLoaderParams
        ) => {
            const oldList: OptionType[] = options.value;
            switch (trigger) {
                case "append":
                    if (!appendEnd.value) {
                        options.value = [...oldList, loadingOptions];
                    } else {
                        return;
                    }
                    break;
                case "init":
                    loading.value = true;
                    break;
                case "show":
                    if (!loaded.value || lasteTriggler === "search") {
                        loading.value = true;
                        loaded.value = false;
                    } else {
                        options.value = props.referResolve
                            ? await props.referResolve(oldList)
                            : oldList;
                        return;
                    }
                    break;
                case "search":
                    //to do in tree-select case how to build tree data?
                    loading.value = true;
                    loaded.value = false;
                    options.value = [loadingOptions];
                    break;
            }
            lasteTriggler = trigger;
            let newValue: OptionType[] = [];
            if (
                !loaded.value ||
                trigger === "append" ||
                trigger === "children"
            ) {
                try {
                    newValue = await action({ trigger, keyword, parentNode });
                } catch (e) {
                    loading.value = false;
                    options.value = oldList;
                    return;
                }
            }
            let list: OptionType[] = [];
            if (trigger === "append") {
                if (newValue.length === 0) {
                    if (!appendEnd.value) {
                        appendEnd.value = true;
                    }
                    options.value = oldList;
                    loading.value = false;
                    loaded.value = true;
                    return;
                } else {
                    list = [...oldList, ...newValue];
                }
                options.value = props.referResolve
                    ? await props.referResolve(list)
                    : list;
            } else if (
                props.displayType === "treeSelect" ||
                props.displayType === "tree"
            ) {
                const appendChildren = (
                    props.referResolve
                        ? await props.referResolve(newValue)
                        : newValue
                ) as TreeReferItem[];
                if (parentNode) {
                    parentNode.children = [
                        ...(parentNode.children || []),
                        ...appendChildren.map(a => ({
                            ...a,
                            pId: parentNode.id,
                        })),
                    ];
                    options.value = [...options.value];
                } else {
                    options.value.push(...appendChildren);
                }
            } else {
                list = newValue;
                options.value = props.referResolve
                    ? await props.referResolve(list)
                    : list;
            }
            if (trigger === "init") {
                context.emit("init", props.value);
            }
            loading.value = false;
            loaded.value = true;
        };

        const loadCustomOptions: OptionLoader = async ({
            trigger,
            parentNode,
            keyword,
        }) => {
            if (props.loader) {
                if (trigger === "show" && !loaded.value) {
                    trigger = "init";
                }
                return props.loader({
                    trigger,
                    keyword,
                    parentNode,
                });
            }
            if (props.displayType === "treeSelect" && trigger === "children") {
                return [];
            }
            return props.defaultOptions;
        };

        const loadResourceOptions: OptionLoader = async params => {
            if (props.resourceCode) {
                return platform.getResourece(
                    {
                        resourceCode: props.resourceCode,
                        ...params,
                    },
                    ...(props.resourceParams
                        ? await props.resourceParams.call(undefined)
                        : [])
                );
            }
            return [];
        };

        const onSearch = debounce(
            (
                keyword: string,
                info: {
                    source: "submit" | "blur" | "typing" | "effect";
                }
            ) => {
                if (info.source === "effect") {
                    return;
                }
                loadOptions(true, {
                    trigger: "search",
                    keyword,
                    value: props.value,
                });
            },
            500,
            {
                leading: false,
                trailing: true,
            }
        );

        const currentKeyWorkd = ref<string>();

        const loadOptions = async (
            open: boolean,
            op: OptionLoaderParams = { trigger: "show" }
        ) => {
            if (!isProcess.value) {
                isProcess.value = true;
                currentKeyWorkd.value = op.keyword;
                switch (props.referType) {
                    case "dict":
                        if (open) {
                            await commonLoader(loadDicOptions, op);
                        }
                        break;
                    case "resource":
                        if (open) {
                            await commonLoader(loadResourceOptions, op);
                        }
                        break;
                    case "custom":
                        if (open) {
                            await commonLoader(loadCustomOptions, op);
                        }
                        break;
                }
                referDisabled.value = false;
                isProcess.value = false;
            }
        };

        const scrollLoader = function (e: UIEvent, lastIndex?: number) {
            if (
                lastIndex !== undefined &&
                lastIndex + 5 >= options.value.length &&
                !appendEnd.value
            ) {
                loadOptions(true, {
                    trigger: "append",
                    keyword: currentKeyWorkd.value,
                    value: props.value,
                });
            }
        };

        const onLoadData = async (treeNode: LegacyDataNode | EventDataNode) =>
            loadOptions(true, {
                trigger: "children",
                parentNode: treeNode.dataRef,
                keyword: currentKeyWorkd.value,
                value: props.value,
            });

        const renderRefer = () => {
            switch (props.displayType) {
                case "checkbox":
                    return (
                        <ICheckboxGroup
                            disabled={props.disabled || referDisabled.value}
                            options={options.value}
                            v-model:value={referValue.value as Array<any>}
                            {...context.attrs}
                        />
                    );
                case "radio":
                    return (
                        <IRadioGroup
                            disabled={props.disabled || referDisabled.value}
                            options={options.value}
                            v-model:value={referValue.value}
                            {...context.attrs}
                        />
                    );
                case "cascader":
                    return (
                        <ICascader
                            class={classNames.value}
                            disabled={props.disabled || referDisabled.value}
                            options={options.value as any}
                            placeholder={props.placeholder}
                            v-model:value={referValue.value as Array<any>}
                            fieldNames={props.fieldNames}
                            loading={loading.value}
                            onDropdownVisibleChange={loadOptions}
                            multiple={props.multi ? true : undefined}
                            {...context.attrs}
                        ></ICascader>
                    );
                case "treeSelect":
                    return (
                        <ISelectTree
                            class={classNames.value}
                            disabled={props.disabled || referDisabled.value}
                            placeholder={props.placeholder}
                            v-model:value={referValue.value}
                            fieldNames={props.fieldNames}
                            maxTagCount={props.maxTagCount}
                            maxTagTextLength={props.maxTagTextLength}
                            treeData={options.value as any}
                            onDropdownVisibleChange={() =>
                                loadOptions(true, {
                                    trigger: "show",
                                    value: props.value,
                                })
                            }
                            multiple={!!props.multi}
                            loading={loading.value}
                            allowClear={props.allowClear}
                            showSearch={props.showSearch}
                            loadData={onLoadData}
                            {...context.attrs}
                        ></ISelectTree>
                    );
                case "tree":
                    return (
                        <ITree
                            class={classNames.value}
                            disabled={props.disabled || referDisabled.value}
                            v-model:selectedKeys={referValue.value}
                            fieldNames={props.fieldNames}
                            treeData={options.value as any}
                            multiple={!!props.multi}
                            initLoader={() =>
                                loadOptions(true, {
                                    trigger: "show",
                                    value: props.value,
                                })
                            }
                            loadData={onLoadData}
                            {...context.attrs}
                        />
                    );
                case "select":
                default:
                    return (
                        <ISelect
                            class={classNames.value}
                            disabled={props.disabled || referDisabled.value}
                            placeholder={props.placeholder}
                            v-model:value={referValue.value}
                            fieldNames={props.fieldNames}
                            maxTagCount={props.maxTagCount}
                            maxTagTextLength={props.maxTagTextLength}
                            options={options.value as any}
                            onDropdownVisibleChange={loadOptions}
                            mode={props.multi ? "multiple" : undefined}
                            loading={loading.value}
                            allowClear={props.allowClear}
                            onPopupScroll={
                                props.loader ? scrollLoader : undefined
                            }
                            showSearch={props.showSearch}
                            {...context.attrs}
                            onSearch={props.showSearch ? onSearch : undefined}
                            filterOption={
                                props.showSearch
                                    ? () => true
                                    : (context.attrs.filterOption as any)
                            }
                        ></ISelect>
                    );
            }
        };

        const reset = () => {
            options.value = [];
            loaded.value = false;
            appendEnd.value = false;
            if (
                props.displayType === "radio" ||
                props.displayType === "checkbox"
            ) {
                loadOptions(true, { trigger: "init", value: props.value });
            }
        };

        loadOptions(true, { trigger: "init", value: props.value });
        watch(
            () => props.referResolve,
            () => {
                loadOptions(true, { trigger: "show", value: props.value });
            }
        );

        watch(
            () => props.value,
            () => {
                if (!loaded.value) {
                    if (
                        Array.isArray(props.value) &&
                        props.value.length === 0
                    ) {
                        return;
                    }
                    if (props.value ?? "" === "") {
                        return;
                    }
                    loadOptions(true, { trigger: "init", value: props.value });
                }
            }
        );
        watch(
            () => props.tenantsId,
            () => {
                loaded.value = false;
            }
        );
        watch(
            () => props.defaultOptions,
            async v => {
                options.value = props.referResolve
                    ? await props.referResolve(v)
                    : v;
            }
        );

        watch(
            () => referValue.value,
            v => {
                if (props.valueResolve) {
                    const option = options.value.find(a => a.value === v);
                    if (option) {
                        props.valueResolve(option);
                    }
                }
                if (loaded.value) {
                    optionsInit(v);
                    change(v);
                }
            }
        );

        onMounted(() => {
            if (
                (props.value ?? "") === "" ||
                (Array.isArray(props.value) && props.value.length === 0)
            ) {
                referDisabled.value = false;
            } else {
                referDisabled.value = true;
            }
        });

        watchEffect(() => {
            if (
                !loaded.value &&
                (props.value ?? "").toString().length > 0 &&
                referDisabled.value === true
            ) {
                if (props.displayType === "checkbox" || props.multi) {
                    referValue.value = ["载入中..."];
                }
                referValue.value = "载入中...";
            } else if ((props.value ?? "") !== "") {
                if (props.multi) {
                    referValue.value = Array.isArray(props.value)
                        ? props.value
                        : [props.value];
                } else {
                    referValue.value = Array.isArray(props.value)
                        ? props.value[0]
                        : props.value;
                }
            } else {
                referValue.value = props.multi ? [] : props.value;
            }
        });

        const resourceParamsData = ref<any[]>(initEmpty);

        watchEffect(async () => {
            const newResourceParams = await props.resourceParams();
            const max = Math.max(
                newResourceParams.length,
                resourceParamsData.value.length
            );
            if (toRaw(resourceParamsData.value) !== initEmpty) {
                for (let i = 0; i < max; i++) {
                    if (newResourceParams[i] !== resourceParamsData.value[i]) {
                        reset();
                        if (props.autoClear) {
                            change(undefined);
                        }
                        break;
                    }
                }
            }
            resourceParamsData.value = newResourceParams;
        });

        watch(
            () => options.value,
            op => {
                if (props.autoClear && loaded.value) {
                    if (Array.isArray(props.value)) {
                        const optionValues = op.map(a => a.value);
                        const validValues = props.value.filter(a =>
                            optionValues.includes(a)
                        );
                        if (validValues.length !== props.value.length) {
                            optionsInit(validValues);
                            change(validValues);
                        }
                    } else if (!op.some(a => a.value === props.value)) {
                        optionsInit();
                        change();
                    }
                }
            }
        );

        context.expose({
            options,
            loadOptions,
            reset,
        });

        return () => <div class="i-refer">{renderRefer()}</div>;
    },
});
