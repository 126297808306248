import { FormPresenter } from "../Presenters/FormPresenter";
import { GridPresenter } from "../Presenters/GridPresenter";
import {
    defineComponent,
    onMounted,
    onUnmounted,
    PropType,
    ref,
    toRaw,
} from "vue";
import IQueryForm from "../IQueryForm/IQueryForm";
import { IEntityGrid } from "../IEntityGrid/IEntityGrid";
import "./style.less";
import type {
    FormSolutionItemDef,
    QueryFormSolutionItemDef,
    ValidationError,
    ViewObj,
} from "../Presenters/declare";
import { computed } from "@vue/reactivity";
import { FormInstance } from "@intasect/ant-design-vue";
import type {
    NamePath,
    ValidateErrorEntity,
} from "@intasect/ant-design-vue/es/form/interface";
import { usePlatform } from "@/utils/composables";

export default defineComponent({
    name: "i-search-page",
    emits: [
        "query",
        "reset",
        "change",
        "changeTab",
        "rowClick",
        "rowSelectAll",
        "rowSelect",
    ],
    components: {
        IQueryForm,
        IEntityGrid,
    },
    props: {
        //查询form的presenter
        formPresenter: {
            type: Object as PropType<FormPresenter<any, any>>,
        },
        //查询表格的presenter
        gridPresenter: {
            type: Object as PropType<GridPresenter<any, any>>,
            required: true,
        },
        //是否自动处理查询，为false则需要通过query事件自行处理查询
        autoQuery: {
            type: Boolean,
            default: true,
        },
        //是否在初始化完成后查询数据
        queryWhenMounted: {
            type: Boolean,
            default: true,
        },
        //标签也配置
        tabs: {
            type: Array as PropType<ViewObj[]>,
            default: () => [],
        },
        //容器显示方式 none 不显示，combine 合并在一个容器中，sepreate 独立容器
        containerMode: {
            type: String as PropType<"none" | "combine" | "seprate">,
            default: "seprate",
        },
        //是否隐藏序号列
        hideOrderColumn: {
            type: Boolean,
            default: false,
        },
        //是否隐藏操作列
        hideActionColumn: {
            type: Boolean,
            default: false,
        },
        formProviders: {
            type: Object as PropType<
                Record<string, (def: FormSolutionItemDef, value: any) => any>
            >,
            default: () => ({}),
        },
        actionCellWidth: Number,
    },
    setup(props, context) {
        const formData = ref(null);
        const formChange = (values: any) => {
            context.emit("change", values);
            formData.value = values;
        };
        const refreshGrid = () => {
            props.gridPresenter.setQueryParams(formData.value);
            props.gridPresenter.pageTo(1);
            props.gridPresenter.refresh();
        };
        const Reset = (formState: any, e: Event) => {
            context.emit("reset", formData.value || {}, e);
            if (props.autoQuery) {
                refreshGrid();
            }
        };
        const Query = async (values: any) => {
            context.emit("query", values);
            if (props.autoQuery) {
                refreshGrid();
            }
        };
        const seprateContainer = computed(() => {
            return props.containerMode === "seprate";
        });
        const pageClassName = computed(() => {
            switch (props.containerMode) {
                default:
                case "none":
                    return "i-search-page";
                case "combine":
                    return "i-search-page container";
                case "seprate":
                    return "i-search-page seprate";
            }
        });
        const platform = usePlatform();
        const validateFail = (errors: ValidationError[]) => {
            errors.forEach(err => {
                //如果是隐藏的表单字段校验失败，说明该字段的赋值来源于外部自定义操作，通过message的方式提示
                if (err.def.display === false) {
                    platform.messageInfo(err.errors.join(","));
                }
            });
        };
        const formRef = ref<
            FormInstance & {
                setExpand: (val: boolean) => void;
                queryItems: QueryFormSolutionItemDef[];
            }
        >();
        const gridRef = ref();
        const renderForm = () => {
            if (props.formPresenter) {
                return (
                    <IQueryForm
                        ref={formRef}
                        presenter={props.formPresenter}
                        isExpand={false}
                        onChange={formChange}
                        onReset={Reset}
                        onValidateFail={validateFail}
                        onQuery={Query}
                        withContainer={seprateContainer.value}
                        providers={props.formProviders}
                    />
                );
            }
            return null;
        };
        const changeTab = (e: any) => {
            context.emit("changeTab", e);
        };
        context.expose({
            clearValidate: (nameList?: NamePath) => {
                formRef.value?.clearValidate(nameList);
            },
            resetFields: (nameList?: NamePath) => {
                formRef.value?.resetFields(nameList);
            },
            scrollToField: (name: NamePath, options: [ScrollOptions]) => {
                formRef.value?.scrollToField(name, options);
            },
            validate: async (nameList?: NamePath[]) => {
                return formRef.value?.validate(nameList);
            },
            validateFields: async (nameList?: NamePath[]) => {
                return formRef.value?.validateFields(nameList);
            },
            submit: (e: Event) => {
                return refreshGrid();
            },
            get grid() {
                return gridRef.value;
            },
        });
        const onRowClick = (e: any) => {
            context.emit("rowClick", e);
        };
        const onRowSelectAll = (
            selected: boolean,
            selectedRows: any[],
            changeRows: any[]
        ) => {
            context.emit("rowSelectAll", selected, selectedRows, changeRows);
        };
        const onRowSelect = (
            record: any,
            selected: boolean,
            selectedRows: any[]
        ) => {
            context.emit("rowSelect", record, selected, selectedRows);
        };
        const renderGrid = () => {
            return (
                <div class="table-content">
                    {context.slots.leftContent && (
                        <div class="left-content">
                            {context.slots.leftContent()}
                        </div>
                    )}
                    <IEntityGrid
                        class={props.formPresenter ? "" : "no-head"}
                        mountedQuery={props.queryWhenMounted}
                        mountedQueryParams={
                            props.formPresenter
                                ? toRaw(props.formPresenter.dataModel)
                                : undefined
                        }
                        presenter={props.gridPresenter}
                        tabs={props.tabs}
                        onChangeTab={changeTab}
                        enableRowSelect={
                            props.gridPresenter.enableRowSelection.value
                        }
                        hideActionColumn={props.hideActionColumn}
                        hideOrderColumn={props.hideOrderColumn}
                        withContainer={seprateContainer.value}
                        onRowClick={onRowClick}
                        onRowSelectAll={onRowSelectAll}
                        onRowSelect={onRowSelect}
                        actionCellWidth={props.actionCellWidth}
                        ref={gridRef}
                    ></IEntityGrid>
                    {context.slots.rightContent && (
                        <div class="right-content">
                            {context.slots.rightContent()}
                        </div>
                    )}
                </div>
            );
        };
        const renderChildren = () => [renderForm(), renderGrid()];
        const renderLeftSlot = () => {
            if (context.slots.left) {
                return (
                    <div class="left-content">
                        {{
                            default: context.slots.left,
                        }}
                    </div>
                );
            } else {
                return null;
            }
        };
        const renderRightSlot = () => {
            if (context.slots.right) {
                return (
                    <div class="right-content">
                        {{
                            default: context.slots.right,
                        }}
                    </div>
                );
            } else {
                return null;
            }
        };

        onMounted(() => {
            props.gridPresenter.beforeRefresh(async () => {
                if (props.formPresenter && formRef.value) {
                    try {
                        await formRef.value.validate();
                    } catch (e) {
                        const params = e as ValidateErrorEntity<any>;
                        for (const item of params.errorFields) {
                            const index = formRef.value.queryItems.findIndex(
                                a =>
                                    a.meta.fieldName ===
                                    (item.name[0] as string)
                            );
                            //当校验有错的项超出 收起情况下可以看到的表单项时，自动展开
                            if (
                                index > 2 &&
                                formRef.value.queryItems[index]
                                    .expandDisplay === false
                            ) {
                                formRef.value.setExpand(true);
                                break;
                            }
                        }
                        return params.errorFields.length === 0;
                    }
                }
                return true;
            });
        });

        onUnmounted(() => {
            props.gridPresenter.dispose();
            props.formPresenter?.dispose();
        });
        return () => (
            <div class={pageClassName.value}>
                {renderLeftSlot()}
                <div class="content">
                    {{
                        default: renderChildren,
                    }}
                </div>
                {renderRightSlot()}
            </div>
        );
    },
});
