import {
    PropType,
    computed,
    defineComponent,
    ref,
    toRef,
    watch,
    watchEffect,
} from "vue";
import {
    RowCreatedHandler,
    FormItemDef,
    GridSolutionItemDef,
    RowSelectionPositionType,
} from "../Presenters/declare";
import ITable, {
    ColSpanItemType,
    RowspanKeyResolveType,
} from "../ITable/ITable";
import { EditableRow } from "./EditableRow";
import {
    createIGridContext,
    useIGridColumns,
    useIGridPagination,
    useIGridRowSelection,
} from "./hooks";
import { TablePaginationConfig } from "@intasect/ant-design-vue";
import {
    ColumnsType,
    FilterValue,
    SorterResult,
    TableCurrentDataSource,
} from "@intasect/ant-design-vue/es/table/interface";
import { ActionButtons } from "./ActionButtons";
import {
    GridOperationAction,
    OperationActionKey,
    generateRowActionCell,
} from "../ActionCell/ActionCell";
import { CellEditable, RowEditable } from "./declare";

export const IGrid = defineComponent({
    name: "i-grid",
    emits: [
        "clickRow",
        "change",
        "actionClick",
        "rowSelectChange",
        "rowSelect",
        "rowSelectAll",
        "pageChange",
        "showSizeChange",
        "editableRowCreated",
        "update:dataSource",
        "update:selectedRowKeys",
        "update:selectedRows",
    ],
    props: {
        gridItemDefs: {
            type: Array as PropType<GridSolutionItemDef[]>,
            required: true,
        },
        actions: {
            type: Array as PropType<GridOperationAction[]>,
            default: () => [],
        },
        formItemDefs: {
            type: Array as PropType<FormItemDef[]>,
            default: () => [],
        },
        dataSource: {
            type: Array as PropType<any[]>,
            default: () => [],
        },
        colDefaultWidth: {
            type: Number,
            default: 200,
        },
        actionColWidth: Number,
        multiSelect: Boolean,
        selectedRowKeys: {
            type: Array as PropType<any[]>,
            default: () => [],
        },
        selectedRows: {
            type: Array,
            default: () => [],
        },
        getCheckboxProps: {
            type: Function as PropType<(record: any) => Record<string, any>>,
            default: () => ({}),
        },
        isPaging: Boolean,
        pageNum: { type: Number, default: 1 },
        pageSize: { type: Number, default: 30 },
        pageTotal: { type: Number, default: 1 },
        autoPaging: Boolean,
        enableRowSelect: Boolean,
        showColumnEditor: Boolean,
        orderColumn: Boolean,
        actionColumn: Boolean,
        dragSortColumn: Boolean,
        autoScroll: { type: Boolean, default: true },
        rowKey: {
            type: String,
            default: "id",
        },
        childrenColumnName: {
            type: String,
            default: "children",
        },
        parentKey: {
            type: String,
            default: "parentId",
        },
        rowSelectDisabledCheck: {
            type: Function as PropType<(record: any) => boolean>,
            default: () => false,
        },
        colSpanItems: {
            type: Array as PropType<ColSpanItemType[]>,
            default: () => [],
        },
        rowSpanItems: {
            type: Array as PropType<(string | symbol)[]>,
            default: () => [],
        },
        rowSpanKeyResolve: {
            type: Function as PropType<RowspanKeyResolveType>,
            default: undefined,
        },
        loading: Boolean,
        expandIconColumnIndex: {
            type: Number,
            default: 1,
        },
        showRowSelectInfo: {
            type: Boolean,
            default: false,
        },
        rowSelectionPosition: {
            type: String as PropType<RowSelectionPositionType>,
            default: "left first",
        },
        withContainer: {
            type: Boolean,
            default: false,
        },
        hideActionColumn: {
            type: Boolean,
            default: false,
        },
        defaultColWidth: { type: Number, default: 200 },
        actionMaxShowLength: { type: Number, default: 3 },
        actionValid: {
            type: Function as PropType<
                (actionKey: OperationActionKey) => boolean
            >,
            default: () => true,
        },
        actionVisible: {
            type: Function as PropType<
                (actionKey: OperationActionKey, record?: any) => boolean
            >,
            default: () => true,
        },
        actionDisable: {
            type: Function as PropType<
                (actionKey: OperationActionKey, record?: any) => boolean
            >,
            default: () => false,
        },
        customRowProps: {
            type: Object as PropType<Record<string, any>>,
            default: () => ({}),
        },
        editableColumnContext: {
            type: Object as PropType<Record<string, any>>,
            default: () => ({}),
        },
        cellEditable: {
            type: Function as PropType<CellEditable>,
        },
        rowEditable: {
            type: Function as PropType<RowEditable>,
        },
    },
    setup(props, context) {
        const realCellEditable = computed(() => {
            if (props.cellEditable === undefined) {
                return () => props.formItemDefs.length > 0;
            }
            return props.cellEditable;
        });
        const realRowEditable = computed(() => {
            if (props.rowEditable === undefined) {
                return () => props.formItemDefs.length > 0;
            }
            return props.rowEditable;
        });
        const components = computed(() => {
            return {
                body: {
                    row: EditableRow,
                },
            };
        });
        const rowSelection = useIGridRowSelection(
            toRef(props, "enableRowSelect"),
            toRef(props, "multiSelect"),
            toRef(props, "selectedRowKeys"),
            toRef(props, "selectedRows"),
            toRef(props,"rowKey"),
            toRef(props,"dataSource"),
            props.getCheckboxProps,
            context.emit
        );
        const innerPagination = useIGridPagination(
            toRef(props, "autoPaging"),
            toRef(props, "isPaging"),
            toRef(props, "pageNum"),
            toRef(props, "pageSize"),
            toRef(props, "pageTotal"),
            context.emit
        );
        watch(
            () => props.loading,
            (v, oldV) => {
                if (v === false) {
                    prevTotal.value = genTotal();
                }
            }
        );
        const genTotal = () => {
            if (
                innerPagination.value !== false &&
                innerPagination.value !== undefined
            ) {
                return (
                    (innerPagination.value.current! - 1) *
                    innerPagination.value.pageSize!
                );
            }
            return 0;
        };
        const prevTotal = ref(genTotal());
        const rowCreated: RowCreatedHandler = (
            record: any,
            rowIndex: number,
            formItemDefs: FormItemDef[],
            ctx
        ) => {
            context.emit(
                "editableRowCreated",
                record,
                rowIndex,
                formItemDefs,
                ctx
            );
        };
        const gridOnChange = (
            pagination: TablePaginationConfig,
            filters: Record<string, FilterValue | null>,
            sorter: SorterResult | SorterResult[],
            extra: TableCurrentDataSource
        ) => {
            context.emit("change", pagination, filters, sorter, extra);
            innerPagination.value = pagination;
        };
        const onClickRow = (
            actionKey: string,
            currentRecord: any | undefined,
            editableRecordContext:
                | {
                      validate: () => Promise<boolean>;
                  }
                | undefined = undefined
        ) => {
            context.emit(
                "clickRow",
                actionKey,
                currentRecord,
                editableRecordContext
            );
        };

        const leftActions = computed(() =>
            props.actions.filter(a => a.position === "actionBarLeft")
        );
        const rightActions = computed(() =>
            props.actions.filter(a => a.position === "actionBarRight")
        );
        const rowActions = computed(() =>
            props.actions.filter(a => a.position === "inline")
        );
        const actionClick = (
            actionKey: string,
            record: any | undefined,
            editableRecordContext:
                | {
                      validate: () => Promise<boolean>;
                  }
                | undefined
        ) => {
            context.emit(
                "actionClick",
                actionKey,
                record,
                editableRecordContext
            );
        };

        const column = ref<ColumnsType<any>>([]);

        const withRowSelectActionsMap = computed(() => {
            const result = new Set<OperationActionKey>();
            props.actions
                .filter(a => a.position !== "inline" && a.withRowSelect)
                .forEach(item => {
                    result.add(item.key);
                });
            return result;
        });

        const withRowSelectionDisable = (
            actionKey: OperationActionKey,
            record?: any
        ) => {
            if (
                withRowSelectActionsMap.value.has(actionKey) &&
                props.selectedRows.length === 0
            ) {
                return true;
            }
            return props.actionDisable(actionKey, record);
        };

        const dataSource = computed({
            get() {
                return props.dataSource;
            },
            set(val) {
                context.emit("update:dataSource");
            },
        });

        watchEffect(() => {
            column.value = useIGridColumns({
                colInfo: {
                    dragSortCol: props.dragSortColumn,
                    orderCol: props.orderColumn,
                    actionCol: props.actionColumn,
                    columns: props.gridItemDefs,
                    defaultColWidth: props.defaultColWidth,
                    actionClick,
                },
                pageInfo: {
                    isPaging: props.isPaging,
                    prevTotal,
                },
                actions: {
                    actionColWidth: props.actionColWidth,
                    actions: rowActions.value,
                    actionMaxShowLength: props.actionMaxShowLength,
                    render: generateRowActionCell(
                        rowActions.value,
                        props.actionValid,
                        actionClick,
                        props.actionDisable,
                        props.actionVisible,
                        props.actionMaxShowLength
                    ),
                },
                editable: {
                    context: {},
                },
            });
        });

        const actionBarLeftSlot = computed(() => {
            if (props.hideActionColumn || leftActions.value.length < 1) {
                return undefined;
            }
            return () => (
                <ActionButtons
                    actions={leftActions.value}
                    onActionClick={actionClick}
                    rowActionDisable={withRowSelectionDisable}
                    rowActionVisible={props.actionVisible}
                    rowSelection={rowSelection.value}
                />
            );
        });

        const actionBarRightSlot = computed(() => {
            if (props.hideActionColumn || rightActions.value.length < 1) {
                return undefined;
            }
            return () => (
                <ActionButtons
                    actions={rightActions.value}
                    onActionClick={actionClick}
                    rowActionDisable={withRowSelectionDisable}
                    rowActionVisible={props.actionVisible}
                    rowSelection={rowSelection.value}
                />
            );
        });

        const { validate, editableRowFormContextMap } = createIGridContext(
            realRowEditable,
            realCellEditable,
            toRef(props, "formItemDefs"),
            rowCreated
        );

        const customRow = (record: any) => {
            if (typeof context.attrs.customRow === "function") {
                return {
                    ...context.attrs.customRow(record),
                    record,
                    index: dataSource.value.indexOf(record),
                };
            }
            return { record, index: dataSource.value.indexOf(record) };
        };

        context.expose({
            validate,
            editableRowFormContextMap,
        });

        return () => (
            <ITable
                v-model:dataSource={dataSource.value}
                v-model:columns={column.value}
                autoScroll={props.autoScroll}
                pagination={innerPagination.value}
                rowSelection={rowSelection.value}
                rowKey={props.rowKey}
                childrenColumnName={props.childrenColumnName}
                components={components.value}
                parentKey={props.parentKey}
                onChange={gridOnChange}
                colSpanItems={props.colSpanItems}
                rowSelectDisabledCheck={props.rowSelectDisabledCheck}
                rowSpanItems={props.rowSpanItems}
                rowSpanKeyResolve={props.rowSpanKeyResolve}
                loading={props.loading}
                showColumnEditor={props.showColumnEditor}
                dragSort={props.dragSortColumn}
                expandIconColumnIndex={props.expandIconColumnIndex}
                dragSortOptions={{
                    handle: ".dragItem",
                }}
                showRowSelectInfo={props.showRowSelectInfo}
                rowSelectionPosition={props.rowSelectionPosition}
                withContainer={props.withContainer}
                onClickRow={onClickRow}
                {...context.attrs}
                customRow={customRow}
            >
                {{
                    header: context.slots.header,
                    actionBarLeft: actionBarLeftSlot.value,
                    actionBarRight: actionBarRightSlot.value,
                    headerCell: context.slots.headerCell,
                }}
            </ITable>
        );
    },
});
