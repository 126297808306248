import { Col } from "@intasect/ant-design-vue";
import {
    computed,
    defineAsyncComponent,
    defineComponent,
    h,
    inject,
    PropType,
} from "vue";
import {
    FormItemDef,
    FormTableItemDef,
    FormSolutionItemDef,
} from "../Presenters/declare";
import { genRule } from "../utils/validate";
import { IFormItem, useFormItemCol } from "./hooks";
import { TableFormItem } from "./TableFormItem";
import { IFormContext } from "./declare";
import { IFORM_CONTEXT } from "./common";

const Item = defineAsyncComponent(
    async () => (await import("@intasect/ant-design-vue/es/form")).FormItem
);

const ItemRest = defineAsyncComponent(
    async () => (await import("@intasect/ant-design-vue/es/form")).FormItemRest
);

export const NormalFormItem = defineComponent({
    name: "i-form-normal",
    props: {
        colspan: {
            type: Number,
            required: true,
        },
        item: {
            type: Object as PropType<FormItemDef | FormTableItemDef>,
            required: true,
        },
        //模式
        mode: {
            type: String as PropType<"default" | "view">,
            default: "default",
        },
        providers: {
            type: Object as PropType<
                Record<string, (def: FormSolutionItemDef, value: any) => any>
            >,
            default: () => ({}),
        },
        //表单的数据模型
        formModel: {
            type: Object as PropType<{ [key: string]: any }>,
            required: true,
        },
    },
    setup(props, context) {
        const span = useFormItemCol(props, props.item);
        if (props.item.controlType === "table") {
            return () => (
                <TableFormItem
                    span={span.value}
                    item={props.item as FormTableItemDef}
                    formModel={props.formModel}
                />
            );
        } else {
            const formContext = inject<IFormContext>(IFORM_CONTEXT);
            const itemPrefix = computed(() => {
                if (
                    formContext &&
                    formContext.layout === "horizontal" &&
                    formContext.itemPrefix &&
                    formContext.itemPrefixCheck({
                        def: props.item,
                        formModel: props.formModel,
                    })
                ) {
                    return (
                        <ItemRest key="prefix">
                            <div class="i-form-item-prefix">
                                {formContext.itemPrefix({
                                    def: props.item,
                                    formModel: props.formModel,
                                })}
                            </div>
                        </ItemRest>
                    );
                }
            });
            const itemSuffix = computed(() => {
                if (
                    formContext &&
                    formContext.layout === "horizontal" &&
                    formContext.itemSuffix &&
                    formContext.itemSuffixCheck({
                        def: props.item,
                        formModel: props.formModel,
                    })
                ) {
                    return (
                        <ItemRest key="suffix">
                            <div class="i-form-item-suffix">
                                {formContext.itemSuffix({
                                    def: props.item,
                                    formModel: props.formModel,
                                })}
                            </div>
                        </ItemRest>
                    );
                }
            });
            const labelPrefix = computed(() => {
                if (
                    formContext &&
                    formContext.layout === "vertical" &&
                    formContext.itemPrefix &&
                    formContext.itemPrefixCheck({
                        def: props.item,
                        formModel: props.formModel,
                    })
                ) {
                    return (
                        <div class="i-form-label-prefix">
                            {formContext.itemPrefix({
                                def: props.item,
                                formModel: props.formModel,
                            })}
                        </div>
                    );
                }
            });
            const labelSuffix = computed(() => {
                if (
                    formContext &&
                    formContext.layout === "vertical" &&
                    formContext.itemSuffix &&
                    formContext.itemSuffixCheck({
                        def: props.item,
                        formModel: props.formModel,
                    })
                ) {
                    return (
                        <div class="i-form-label-suffix">
                            {formContext.itemSuffix({
                                def: props.item,
                                formModel: props.formModel,
                            })}
                        </div>
                    );
                }
            });

            const label = computed(() => {
                if (props.item.hideTitle) {
                    return undefined;
                } else if (formContext?.formLabel) {
                    return formContext.formLabel({
                        title:
                            props.item.title ??
                            (props.item as FormItemDef).meta.title,
                        colspan: props.colspan,
                        formModel: props.formModel,
                    });
                } else if ((props.item as FormItemDef).titleRender) {
                    return h((props.item as FormItemDef).titleRender, {
                        title:
                            props.item.title ??
                            (props.item as FormItemDef).meta.title,
                        colspan: props.colspan,
                        formModel: props.formModel,
                    });
                } else if (!(props.item as FormItemDef).hideTitle) {
                    return (
                        props.item.title ??
                        (props.item as FormItemDef).meta.title
                    );
                }
            });

            return () =>
                props.item.display !== false && (
                    <Col
                        key={(props.item as FormItemDef).meta.fieldName}
                        span={span.value}
                        offset={props.item.offset}
                    >
                        <Item
                            class="i-form-item"
                            colon={(props.item as FormItemDef).colon}
                            labelCol={(props.item as FormItemDef).labelCol}
                            label={label.value}
                            name={
                                (props.item as FormItemDef).meta
                                    .fieldName as string
                            }
                            rules={
                                genRule(
                                    props.item.title,
                                    (props.item as FormItemDef).validation,
                                    (props.item as FormItemDef).meta.fieldType,
                                    formContext?.customContext
                                ) as any
                            }
                        >
                            {{
                                labelPrefix: () => labelPrefix.value,
                                labelSuffix: () => labelSuffix.value,
                                default: () => [
                                    itemPrefix.value,
                                    <IFormItem
                                        key="item"
                                        mode={props.mode}
                                        formModel={props.formModel}
                                        def={props.item as FormItemDef}
                                        providers={props.providers}
                                    />,
                                    itemSuffix.value,
                                ],
                            }}
                        </Item>
                    </Col>
                );
        }
    },
});
