import { treeProps } from "@intasect/ant-design-vue/es/tree/Tree";
import {
    defineAsyncComponent,
    defineComponent,
    PropType,
    computed,
    onMounted,
} from "vue";
import "@intasect/ant-design-vue/es/tree/style";

const Tree = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/tree")
);
const Porps = treeProps();

export default defineComponent({
    name: "i-tree",
    components: {
        Tree,
    },
    emits: ["update:value"],
    props: {
        ...Porps,
        isList: {
            type: Boolean,
            default: false,
        },
        fieldNames: {
            type: Object as PropType<{
                label: string;
                value: string;
                children: string;
            }>,
            default: () => ({
                label: "label",
                value: "value",
                children: "children",
            }),
        },
        initLoader: Function,
    },
    setup(props, { slots }) {
        const showLeafIcon = false;
        const treeFieldNames = {
            title: props.fieldNames.label,
            key: props.fieldNames.value,
            children: props.fieldNames.children,
        };
        onMounted(() => {
            if (props.initLoader) {
                props.initLoader();
            }
        });
        return () => (
            <Tree
                {...props}
                fieldNames={treeFieldNames}
                showLine={{ showLeafIcon }}
            >
                {slots}
            </Tree>
        );
    },
});
