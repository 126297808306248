import {
    generateRowActionCell,
    GridOperationAction,
    OperationActionKey,
} from "@/components/i-components/ActionCell/ActionCell";
import { ActionCheckTypes } from "@/config/declare";
import {
    ComputedType,
    EntityController,
    IObserver,
    PageCondition,
    useEntityPage,
    UseEntityListType,
    UseEntityPageType,
    useEntityList,
    PlatformContext,
    PageResonse,
    EntityData,
    IDisposer,
    RefType,
    devConsole,
} from "@intasect/platform-core";
import { Ref, ref, toRef, UnwrapNestedRefs, watch, WatchStopHandle } from "vue";
import {
    RowCreatedHandler,
    FormItemDef,
    GridActionClickEvent,
    GridActionParmas,
    GridSolutionItemDef,
    RowSelectionPositionType,
    SorterType,
    SortType,
    ViewObj,
    SubQueryItem,
} from "./declare";
import { ColSpanItemType } from "../ITable/ITable";
import { CellEditable, RowEditable } from "../IGrid/declare";

export abstract class GridPresenterCore<
    EntityDef extends { [name: string]: any },
    Controller extends EntityController<EntityDef, PC, PR, ED>,
    PC = PageCondition<EntityDef>,
    PR = PageResonse<EntityDef>,
    ED = EntityData<EntityDef>
> implements IDisposer
{
    protected disposeList: Array<() => void> = [];
    public readonly gridController: Controller;
    protected readonly currentGridItemDef: RefType<GridSolutionItemDef[]>;
    protected readonly currentFormItemDef: RefType<FormItemDef[]>;
    protected readonly currentSorterMap: RefType<SorterType[]>;
    protected entityListDef:
        | UseEntityPageType<EntityDef>
        | UseEntityListType<EntityDef>; //[entityList, refresh, nextPage, prevPage]
    private onActionClickEvents: GridActionClickEvent<EntityDef>[] = [];
    public readonly selectedRows: Ref<Array<EntityDef>> = ref([]);
    protected selectedRowKeys = ref<Array<string>>([]);
    //protected selectedRowKeysChangedList: Array<(keys: string[]) => void> = [];
    protected actionVisibleCheckMap: {
        [actionKey: OperationActionKey]: (
            params: GridActionParmas<EntityDef>
        ) => boolean;
    } = {};
    protected actionDisableCheckMap: {
        [actionKey: OperationActionKey]: (
            params: GridActionParmas<EntityDef>
        ) => boolean;
    } = {};
    public readonly actions: RefType<GridOperationAction[]>;
    public readonly rowActions: ComputedType<GridOperationAction<any>[]>;
    public readonly actionBarLeftActions: ComputedType<
        GridOperationAction<any>[]
    >;
    public readonly actionBarRightActions: ComputedType<
        GridOperationAction<any>[]
    >;
    //行数据的key（必须是唯一值）
    public primaryKey = "id";
    //树形列表的子级节点的字段值
    public childrenKey = "children";
    //树形列表的父级节点的字段值
    public parentKey = "parentId";
    public rowSelectDisabled: (record: EntityDef) => boolean = () => false;
    public _rowSelectMulti = ref(true);
    public readonly dragSort = ref(false);
    public showRowSelectInfo = ref(false);
    public rowSelectionPosition = ref<RowSelectionPositionType>("left first");
    public enableRowSelection = ref(true);
    //刷新的时候是否清空行选择
    public clearSelectKeysWhenRefresh = true;
    //行展开的列索引
    public expandColumnIndex = 2;
    private beforeRefreshEventList: Array<() => Promise<boolean>> = [];
    public readonly showColumnEditor = ref(false);
    public queryParmasResolve = (params: any) => params;
    public colSpanItems = ref<ColSpanItemType[]>([]);
    public readonly selectColumnRowSpan = ref(false);
    public selectColumnRowSpanResolve = (record: EntityDef) => "";
    public customHandleRowSelectKeys?: (
        selectedRowKeys: string[],
        selectedRows: any[]
    ) => void;
    private _cellEditable: Ref<CellEditable | undefined> = ref();
    private _rowEditable: Ref<RowEditable | undefined> = ref();
    protected readonly subQueryItems = ref<SubQueryItem[]>([]);

    private onEditableRowCreatedFnList: Array<RowCreatedHandler> = [];

    onEditableRowCreated = (callback: RowCreatedHandler<EntityDef>) => {
        this.onEditableRowCreatedFnList.push(callback);
    };

    dispatchEditableRowCreated: RowCreatedHandler = (
        record: any,
        rowIndex: number,
        defs: FormItemDef[],
        context: any
    ) => {
        this.onEditableRowCreatedFnList.forEach(f => {
            f(record, rowIndex, defs, context);
        });
    };

    protected viewMap: {
        [viewName: string]: {
            gridItemDef: (
                gridItemDef: Array<GridSolutionItemDef>
            ) => Promise<GridSolutionItemDef[]> | GridSolutionItemDef[];
            formItemDef?: (
                formItemDef: FormItemDef[]
            ) => Promise<FormItemDef[]> | FormItemDef[];
        };
    } = {
        default: {
            gridItemDef: async () => this.originalGridItemDef.value.list,
            formItemDef: () => [],
        },
    };
    //用于表格头部tab渲染
    public viewList = ref<Array<ViewObj>>([]);
    private _currentViewName = ref("default");
    public useTabView = ref<boolean>(false);

    constructor(
        protected readonly observerApi: IObserver,
        protected platFormContext: PlatformContext,
        controller: Controller,

        protected originalGridItemDef: RefType<{
            list: GridSolutionItemDef[];
            map: Record<string, GridSolutionItemDef>;
            sorterMap: Array<SorterType>;
        }>,
        actions: GridOperationAction<any>[] = [],
        public isPaging: boolean = true,
        public rowActionsMaxShowLength: number = 3,
        //@ts-ignore
        protected pageCondition: PageCondition<EntityDef> = {
            pageNum: 1,
            pageSize: 30,
            orderByField: "",
        },
        public readonly checkAction: ActionCheckTypes = () => true,
        formItemDefs: FormItemDef[],
        functionName?: string
    ) {
        this.actions = this.observerApi.ref(actions);
        this.gridController = controller;
        this.entityListDef = this.generateEntityList(functionName);
        this.rowActions = this.observerApi.computed(() =>
            this.actions.value.filter(a => a.position === "inline")
        );
        this.actionBarLeftActions = this.observerApi.computed(() =>
            this.actions.value
                .filter(a => a.position === "actionBarLeft")
                .sort((a, b) => (a.order || 0) - (b.order || 0))
        );
        this.actionBarRightActions = this.observerApi.computed(() =>
            this.actions.value
                .filter(a => a.position === "actionBarRight")
                .sort((a, b) => (a.order || 0) - (b.order || 0))
        );
        this.currentGridItemDef = this.observerApi.ref(
            this.originalGridItemDef.value.list
        );
        this.currentFormItemDef =
            this.observerApi.ref<FormItemDef[]>(formItemDefs);
        this.currentSorterMap = this.observerApi.ref(
            this.originalGridItemDef.value.sorterMap
        );
        this.initSubQueryItem();
    }

    dispose = () => {
        this.disposeList.forEach(d => {
            d();
        });
    };

    protected generateEntityList(
        functionName?: string
    ): UseEntityListType<EntityDef> | UseEntityPageType<EntityDef> {
        const { pageNum, pageSize, ...rest } = this.pageCondition;
        return this.isPaging
            ? (useEntityPage(
                  this.observerApi,
                  this.gridController,
                  this.pageCondition,
                  functionName
              ) as any as UseEntityPageType<EntityDef>)
            : useEntityList(
                  this.observerApi,
                  this.gridController,
                  rest as any,
                  functionName
              );
    }

    get entity() {
        return this.entityListDef[0];
    }

    refresh = async () => {
        const beforeRefreshCheck = await Promise.all(
            this.beforeRefreshEventList.map(a => a())
        );
        if (!beforeRefreshCheck.includes(false)) {
            if (this.clearSelectKeysWhenRefresh) {
                this.selectedRows.value = [];
                this.SelectedRowKeys = [];
            }
            this.entity.pageCondition.orderByField = this.genSortParams(
                this.currentSorterMap.value
            );
            return this.entityListDef[1]();
        } else {
            this.entityListDef[0].loading = false;
        }
    };

    combineSubQueryItems = (dataSource: EntityDef[]) => {
        const currentQueryId = this.entityListDef[0].queryId;
        this.subQueryItems.value.forEach(async item => {
            const subResult = await item.query();
            if (Array.isArray(subResult)) {
                dataSource.forEach(async data => {
                    if (typeof item.resolve === "function") {
                        const subData = await item.resolve(data, subResult);
                        if (this.entityListDef[0].queryId === currentQueryId) {
                            Object.assign(data, subData);
                        }
                    } else {
                        const subData = subResult.find(
                            a =>
                                a[item.resolve.subId] ===
                                data[item.resolve.mainId]
                        );
                        if (
                            this.entityListDef[0].queryId === currentQueryId &&
                            subData !== undefined
                        ) {
                            Object.assign(data, subData);
                        }
                    }
                });
            } else {
                devConsole(`can't reslove subQueryItems`, subResult);
            }
        });
    };

    clear() {
        this.entityListDef[0].entityList.length = 0;
    }

    get loading() {
        return toRef(this.entityListDef[0], "loading");
    }

    get rowSelectMulti() {
        return this._rowSelectMulti.value;
    }

    set rowSelectMulti(val) {
        this._rowSelectMulti.value = val;
    }

    get dataSource() {
        return toRef(this.entityListDef[0], "entityList");
    }

    updateDataSource(value: EntityDef[]) {
        const newAr = [...value];
        this.entityListDef[0].entityList.length = 0;
        this.entityListDef[0].entityList.push(...newAr);
        // = reactive(value);
    }

    pageTo(pageNumber: number) {
        this.entityListDef[0].pageCondition.pageNum = pageNumber;
    }

    private updateColumns = async () => {
        this.currentGridItemDef.value = await (
            this.viewMap[this.currentViewName].gridItemDef ||
            this.viewMap.default.gridItemDef
        ).call(null, this.gridItemDef);
        this.currentFormItemDef.value = await (this.viewMap[
            this.currentViewName
        ].formItemDef ?? this.viewMap.default.formItemDef)!.call(
            null,
            this.formItemDef
        );
    };

    get context() {
        return this.platFormContext;
    }

    get actionRender() {
        return generateRowActionCell(
            this.rowActions.value.map(a => {
                if (
                    a.popConfirm &&
                    a.position === "inline" &&
                    a.popConfirm.placement === undefined
                ) {
                    a.popConfirm.placement = "topRight";
                }
                return a;
            }),
            this.checkAction,
            this.dispatchActionClick,
            this.actionDisable,
            this.actionVisible,
            this.rowActionsMaxShowLength
        );
    }

    get currentViewName() {
        return this._currentViewName.value;
    }

    addView = ({
        viewName,
        gridItemDef,
        formItemDef,
        order,
    }: {
        viewName: string;
        gridItemDef: (
            gridItemDef: Array<GridSolutionItemDef>
        ) => Promise<GridSolutionItemDef[]> | GridSolutionItemDef[];
        formItemDef?: (
            formItemDef: FormItemDef[]
        ) => Promise<FormItemDef[]> | FormItemDef[];
        order?: number;
    }) => {
        this.viewMap[viewName] = { gridItemDef, formItemDef };
        const exist = this.viewList.value.find(a => a.value === viewName);
        if (exist) {
            exist.order = order ?? exist.order;
        } else {
            this.viewList.value.push({
                value: viewName,
                order: order ? order : this.viewList.value.length,
            });
        }
        this.viewList.value.sort((a, b) => {
            return (a.order || 0) - (b.order || 0);
        });
    };
    setView = async (viewName: string) => {
        this._currentViewName.value = viewName;
        await this.updateColumns();
    };

    setPageIndex = (index: number) => {
        this.entity.pageCondition.pageNum = index;
    };

    setPageSize = (size: number) => {
        this.entity.pageCondition.pageSize = size;
    };

    setRequestParams = (params: any) => {
        this.entity.pageCondition = Object.assign(
            this.entity.pageCondition,
            params
        );
    };

    setQueryParams = (params: any) => {
        this.entity.pageCondition = Object.assign(
            this.entity.pageCondition,
            this.queryParmasResolve(params)
        );
    };

    addActionClickEvent = (fn: GridActionClickEvent<EntityDef>) => {
        this.onActionClickEvents.push(fn);
    };

    dispatchActionClick = (
        actionKey: string,
        currentRecord: EntityDef | undefined = undefined,
        editableRecordContext:
            | {
                  validate: () => Promise<boolean>;
              }
            | undefined = undefined
    ) => {
        this.onActionClickEvents.forEach(f => {
            f.call(null, {
                actionKey,
                currentRecord,
                selectedRecords: this.selectedRows.value,
                context: this.context,
                editableRecordContext,
            });
        });
    };

    actionVisible = (
        actionKey: OperationActionKey,
        currentRecord: EntityDef | undefined = undefined
    ) => {
        if (this.actionVisibleCheckMap[actionKey]) {
            return this.actionVisibleCheckMap[actionKey].call(null, {
                actionKey,
                currentRecord,
                selectedRecords: this.selectedRows.value,
                context: this.context,
            });
        }
        return true;
    };

    actionDisable = (
        actionKey: OperationActionKey,
        currentRecord: EntityDef | undefined = undefined
    ) => {
        if (this.actionDisableCheckMap[actionKey]) {
            return this.actionDisableCheckMap[actionKey].call(null, {
                actionKey,
                currentRecord,
                selectedRecords: this.selectedRows.value,
                context: this.context,
            });
        }
        return false;
    };

    addActionVisibleRule = (
        actionKey: string,
        rule: (params: GridActionParmas<EntityDef>) => boolean
    ) => {
        this.actionVisibleCheckMap[actionKey] = rule;
    };

    addActionDisableRule = (
        actionKey: string,
        rule: (params: GridActionParmas<EntityDef>) => boolean
    ) => {
        this.actionDisableCheckMap[actionKey] = rule;
    };

    updateSelectedRows = (newRows: Array<EntityDef>) => {
        this.selectedRows.value = newRows;
        //this.selectedRowKeys.value = newRows.map(a => a[this.primaryKey]);
    };

    // addSelectedRowKeysChanedEvent = (fn: (keys: string[]) => void) => {
    //     this.selectedRowKeysChangedList.push(fn);
    // };

    get SelectedRows() {
        return this.selectedRows.value;
    }

    get RawSelectedRowKeys() {
        return this.selectedRowKeys;
    }

    get SelectedRowKeys() {
        return this.selectedRowKeys.value;
    }

    set SelectedRowKeys(value: string[]) {
        this.selectedRowKeys.value = value;
        // this.selectedRowKeysChangedList.forEach(f => {
        //     f(value);
        // });
    }

    async delete(...ids: string[]) {
        this.entityListDef[0].loading = true;
        await this.gridController.delete(ids);
        this.refresh();
    }

    replaceSorter(sorters: SorterType[]) {
        this.currentSorterMap.value = sorters;
    }

    setSorter(fieldName: string, sortType?: SortType) {
        if (sortType && sortType !== "default") {
            const item = this.currentSorterMap.value.find(
                a => a.fieldName === fieldName
            );
            if (item) {
                item.sortType = sortType;
            } else {
                this.currentSorterMap.value.push({ fieldName, sortType });
            }
        } else {
            this.currentSorterMap.value = this.currentSorterMap.value.filter(
                a => a.fieldName !== fieldName
            );
        }
    }

    bindFormDataModel = (dataModel: UnwrapNestedRefs<Record<string, any>>) => {
        this.disposeList.push(
            watch(
                () => dataModel,
                model => {
                    for (const key in model) {
                        if (key in this.entity.pageCondition) {
                            //@ts-ignore
                            this.entity.pageCondition[key] = model[key];
                        }
                    }
                }
            )
        );
    };

    beforeRefresh = (fn: () => Promise<boolean>) => {
        this.beforeRefreshEventList.push(fn);
    };

    private genSortParams(sorterMap: Array<SorterType>) {
        return sorterMap.map(a => `${a.fieldName} ${a.sortType}`).join(",");
    }

    createNewEditableRow = (initData: any = {}) => {
        this.dataSource.value.push(initData);
        return initData;
    };

    initSubQueryItem = () => {
        let dis: WatchStopHandle | undefined;
        watch(
            () => this.subQueryItems.value.length,
            length => {
                if (length > 0 && dis === undefined) {
                    dis = watch(
                        () => this.entityListDef[0].loading,
                        v => {
                            if (!v) {
                                this.combineSubQueryItems(
                                    this.dataSource.value
                                );
                            }
                        }
                    );
                }
                if (length === 0) {
                    if (dis !== undefined) {
                        dis();
                        dis = undefined;
                    }
                }
            },
            { immediate: true }
        );
    };

    addSubQueryItem = <T>(item: SubQueryItem<T, EntityDef>) => {
        if (!this.subQueryItems.value.includes(item)) {
            this.subQueryItems.value.push(item);
        }
    };

    removeSubQuery = (item: SubQueryItem) => {
        const index = this.subQueryItems.value.indexOf(item);
        if (index > -1) {
            this.subQueryItems.value.splice(index, 1);
        }
    };

    get gridItemDef() {
        return this.currentGridItemDef.value;
    }

    get formItemDef() {
        return this.currentFormItemDef.value;
    }

    get rowEditable() {
        return this._rowEditable.value;
    }
    set rowEditable(val) {
        this._rowEditable.value = val;
    }

    get cellEditable() {
        return this._cellEditable.value;
    }

    set cellEditable(val) {
        this._cellEditable.value = val;
    }

    get orignalGridItemDef() {
        return this.originalGridItemDef.value.list;
    }
}
