import { defineComponent, onMounted, PropType, ref, watch } from "vue";
import {
    QRCodeErrorCorrectionLevel,
    QRCodeRenderersOptions,
    QRCodeToDataURLOptions,
    QRCodeToStringOptions,
    toCanvas,
    toDataURL,
    toString,
} from "qrcode";

const EVENT_DONE = "ready";
const EVENT_ERROR = "error";

export const IQRCode = defineComponent({
    name: "IQRCode",
    emits: [EVENT_DONE, EVENT_ERROR],
    props: {
        //二维码字符串
        value: {
            type: String,
            default: "",
        },
        //生成的二维码类型
        tag: {
            type: String as PropType<"canvas" | "img" | "svg">,
            default: "canvas",
        },
        //宽高度
        width: {
            type: Number,
            default: 250,
        },
        //容错率
        errorCorrectionLevel: {
            type: String as PropType<QRCodeErrorCorrectionLevel>,
            default: "H",
        },
    },
    setup(props, context) {
        const domRef = ref<HTMLCanvasElement | HTMLImageElement | undefined>();
        const done = () => {
            context.emit(EVENT_DONE, domRef.value);
        };
        const error = (err: Error) => {
            context.emit(EVENT_ERROR, err);
        };
        const generate = () => {
            const options:
                | QRCodeToDataURLOptions
                | QRCodeRenderersOptions
                | QRCodeToStringOptions = {
                width: props.width,
                errorCorrectionLevel: props.errorCorrectionLevel,
            };
            if (domRef.value) {
                switch (props.tag) {
                    default:
                    case "canvas":
                        toCanvas(
                            domRef.value,
                            props.value,
                            options as QRCodeRenderersOptions
                        )
                            .then(done)
                            .catch(error);
                        break;

                    case "img":
                        toDataURL(
                            props.value,
                            options as QRCodeToDataURLOptions
                        )
                            .then(url => {
                                (domRef.value! as HTMLImageElement).src = url;
                                domRef.value!.onload = done;
                            })
                            .catch(error);
                        break;

                    case "svg":
                        toString(props.value, options as QRCodeToStringOptions)
                            .then(str => {
                                const div = document.createElement("div");

                                div.innerHTML = str;

                                const svg = div.querySelector("svg");

                                if (svg) {
                                    const { attributes, childNodes } = svg;

                                    Object.keys(attributes).forEach(
                                        (key: string) => {
                                            const attribute =
                                                attributes[Number(key)];

                                            domRef.value!.setAttribute(
                                                attribute.name,
                                                attribute.value
                                            );
                                        }
                                    );
                                    Object.keys(childNodes).forEach(
                                        (key: string) => {
                                            const childNode =
                                                childNodes[Number(key)];

                                            domRef.value!.appendChild(
                                                childNode.cloneNode(true)
                                            );
                                        }
                                    );
                                    done();
                                }
                            })
                            .catch(error);
                        break;
                }
            }
        };
        watch(() => props, generate, { deep: true });
        onMounted(generate);
        return () => <props.tag ref={domRef}></props.tag>;
    },
});
