import { ActionCheckTypes, ActionTypes } from "@/config/declare";
import type { ColumnType } from "@intasect/ant-design-vue/es/table";
import {
    Component,
    computed,
    defineComponent,
    h,
    inject,
    PropType,
    provide,
    ref,
    VNodeTypes,
} from "vue";
import { EllipsisOutlined } from "@ant-design/icons-vue";
import { Dropdown, Menu, Popconfirm } from "@intasect/ant-design-vue";
import IButton from "@/components/i-components/IButton/IButton";
import "./ActionCell.less";
import { devConsole } from "@intasect/platform-core";
import IModal from "@/components/i-components/IModal/IModal.vue";
import { ExclamationCircleFilled } from '@ant-design/icons-vue';
const MenuItem = Menu.Item;

export type FormButtonPosition = "top" | "bottom";

export type GridActionPorision = "actionBarLeft" | "actionBarRight" | "inline";

export type OperationActionKey = string | symbol;

export interface OperationAction<T = any> {
    key: OperationActionKey;
    order?: number;
    title: string | ((record: T) => VNodeTypes);
    actionType: ActionTypes;
    buttonStyle?:
        | "primary"
        | "success"
        | "warning"
        | "danger"
        | "default"
        | "link";
    popConfirm?: {
        icon?: VNodeTypes;
        okText?: string;
        cancelText?: string;
        showCancel?: boolean;
        title: string | ((record: T) => VNodeTypes);
        placement?:
            | "topLeft"
            | "top"
            | "topRight"
            | "rightTop"
            | "right"
            | "rightBottom"
            | "bottomRight"
            | "bottom"
            | "bottomLeft"
            | "leftBottom"
            | "left"
            | "leftTop";
    };
    width?: number;
    customRender?: Component;
}

export interface GridOperationAction<T = any> extends OperationAction<T> {
    position: GridActionPorision;
    withRowSelect?: boolean;
}

export interface FormOperationAction<T = any> extends OperationAction<T> {
    position: FormButtonPosition;
}

export const generateRowActionCell = <RecordType,>(
    actions: OperationAction<RecordType>[],
    checkActionFn: ActionCheckTypes,
    onClick: (
        actionKey: string,
        record: any | undefined,
        editableRecordContext:
            | {
                  validate: () => Promise<boolean>;
              }
            | undefined
    ) => void,
    disabled: (
        actionKey: OperationActionKey,
        record: any | undefined
    ) => boolean,
    visible: (
        actionKey: OperationActionKey,
        record: any | undefined
    ) => boolean,
    maxShowLength: number = 3
) => {
    return (opt: {
        value: any;
        text: any;
        record: RecordType;
        index: number;
        column: ColumnType<RecordType>;
    }) => {
        return (
            <ActionCell
                record={opt.record}
                actions={actions.filter(a => visible(a.key, opt.record))}
                checkActionFn={checkActionFn}
                maxShowLength={maxShowLength}
                onClick={onClick}
                disableFn={disabled}
            />
        );
    };
};

export const ActionCell = defineComponent({
    components: {
        EllipsisOutlined,
    },
    emits: ["click"],
    props: {
        actions: {
            type: Array as PropType<OperationAction[]>,
            default: () => [],
        },
        checkActionFn: {
            type: Function as PropType<ActionCheckTypes>,
            default: () => true,
        },
        record: {
            type: Object as PropType<any>,
            required: true,
        },
        maxShowLength: {
            type: Number,
            default: 3,
        },
        disableFn: {
            type: Function as PropType<
                (actionKey: string, currentRecord: any | undefined) => boolean
            >,
            default: () => false,
        },
    },
    setup(props, context) {
        const validActions = computed(() =>
            props.actions.filter(a => props.checkActionFn(a.actionType))
        );
        const showActions = computed(() => {
            let length = props.actions.length;
            if (props.actions.length > props.maxShowLength) {
                length = props.maxShowLength;
            }
            return validActions.value.slice(0, length);
        });
        const expandActions = computed(() => {
            if (props.actions.length > props.maxShowLength) {
                return validActions.value.slice(props.maxShowLength);
            }
            return [];
        });

        const checkSepratorShow = (index: number) => {
            if (index === showActions.value.length - 1) {
                return expandActions.value.length > 0;
            }
            return index < showActions.value.length - 1;
        };

        provide("disableFn", (actionKey: string) =>
            props.disableFn(actionKey, props.record)
        );

        const editableRecordContext = inject("editableRecord", null);

        return () => {
            if (props.actions.length > 0) {
                return (
                    <div class="operation-actions">
                        {showActions.value.map((action, index) => {
                            const onClick = () => {
                                context.emit(
                                    "click",
                                    action.key,
                                    props.record,
                                    editableRecordContext
                                );
                            };
                            if (action.customRender) {
                                return [
                                    h(action.customRender, {
                                        record: props.record,
                                    }),
                                    <div
                                        key="seprator"
                                        v-show={checkSepratorShow(index)}
                                        class="seprator"
                                    ></div>,
                                ];
                            }
                            let title: any = action.title as string;
                            if (typeof action.title !== "string") {
                                title = () => {
                                    return (
                                        action.title as (
                                            record: any
                                        ) => VNodeTypes
                                    )(props.record);
                                };
                            }
                            return (
                                <OperationActionButton
                                    popConfirm={action.popConfirm}
                                    actionKey={action.key}
                                    key={action.key.toString()}
                                    record={props.record}
                                    title={title}
                                    onClick={onClick}
                                    showSeprator={checkSepratorShow(index)}
                                />
                            );
                        })}
                        <OperationActionDropdown
                            onClick={(actionKey, record) => {
                                context.emit("click", actionKey, record);
                            }}
                            items={expandActions.value}
                            record={props.record}
                        />
                    </div>
                );
            }
            return null;
        };
    },
});

export const OperationActionButton = defineComponent({
    props: {
        actionKey: {
            type: [String, Symbol],
            required: true,
        },
        title: {
            type: [String, Function] as PropType<
                string | ((record: any) => VNodeTypes)
            >,
            default: () => "",
        },
        popConfirm: {
            type: Object as PropType<OperationAction["popConfirm"]>,
        },
        showSeprator: {
            type: Boolean,
            default: true,
        },
        record: Object,
    },
    components: {
        IButton,
        Popconfirm,
    },
    emits: ["click"],
    setup(props, context) {
        const visible = ref(false)
        const disabled = useDisable(props.actionKey);
        const onClick = (e: Event) => {
            e.stopPropagation();
            visible.value = true
        };
        const onConfirm = (e: Event) => {
            close()
            context.emit("click");
        };
        const close = () => {
            visible.value = false
        }
        const content = computed(() =>
            typeof props.title === "string" ? () => props.title : props.title
        );

        if (props.popConfirm) {
            const { title, ...rest } = props.popConfirm;
            const popConfirmTitle = computed(() =>
                typeof title === "string"
                    ? () => title
                    : () => title(props.record)
            );
            return () => (
                <>
                    <IButton
                        class="action-button"
                        size="small"
                        type="link"
                        disabled={disabled.value}
                        onClick={onClick}
                    >
                           {{ default: content.value }}
                    </IButton>
                    <IModal visible={visible.value} title='提示' width={420} onCancel={close} disableMove={true} disableResize={false} hideFullScreen={true} okText={rest.okText} cancelText={rest.cancelText}

                        onOk={onConfirm} class='hint-modal'>
                        <div class = "hint-body">
                            <ExclamationCircleFilled class='hint-icon' /> {title}
                        </div>

                    </IModal>
                    <div v-show={props.showSeprator} class="seprator"></div>
                </>
            );
            // const defaultRender = () => (
            //     <IButton
            //         class="action-button"
            //         size="small"
            //         type="link"
            //         disabled={disabled.value}
            //         onClick={onClick}
            //     >
            //         {{ default: content.value }}
            //     </IButton>
            // );
            // return () => (
            //     <>
            //         <Popconfirm {...rest} onConfirm={onConfirm}>
            //             {{
            //                 title: popConfirmTitle.value,
            //                 default: defaultRender,
            //             }}
            //         </Popconfirm>
            //         <div v-show={props.showSeprator} class="seprator"></div>
            //     </>
            // );
        }
        return () => (
            <>
                <IButton
                    class="action-button"
                    size="small"
                    type="link"
                    disabled={disabled.value}
                    onClick={onConfirm}
                >
                    {{ default: content.value }}
                </IButton>
                <div v-show={props.showSeprator} class="seprator"></div>
            </>
        );
    },
});

export const OperationActionDropdown = defineComponent({
    emits: ["click"],
    props: {
        items: {
            type: Array as PropType<Array<OperationAction>>,
            default: () => [],
        },
        context: {
            type: Object,
            default: () => {},
        },
        record: {
            type: Object as PropType<any>,
            default: () => {},
        },
    },
    components: {
        EllipsisOutlined,
        Dropdown,
        Menu,
        MenuItem,
    },
    setup(props, context) {
        if (props.items.length < 1) {
            return () => null;
        }
        const onClick = (e: Event) => {
            e.stopPropagation();
        };

        const slots = {
            default: () => <EllipsisOutlined onClick={onClick} />,
            overlay: () => (
                <Menu>
                    {props.items.map(item => {
                        const disabled = useDisable(item.key);
                        if (item.popConfirm) {
                            const { title, ...rest } = item.popConfirm;
                            const popConfirmTitle = computed(() =>
                                typeof title === "string"
                                    ? () => title
                                    : () => title(props.record)
                            );
                            const onConfirm = (e: Event) => {
                                e.stopPropagation();
                                if (disabled.value) {
                                    devConsole("action disabled!");
                                    return;
                                }
                                context.emit("click", item.key, props.record);
                            };
                            return (
                                <MenuItem
                                    key={item.key.toString()}
                                    disabled={disabled.value}
                                    onClick={onClick}
                                >
                                    <Popconfirm {...rest} onConfirm={onConfirm}>
                                        {{
                                            title: popConfirmTitle.value,
                                            default: () =>
                                                typeof item.title === "string"
                                                    ? item.title
                                                    : item.title(props.record),
                                        }}
                                    </Popconfirm>
                                </MenuItem>
                            );
                        } else {
                            return (
                                <MenuItem
                                    key={item.key}
                                    disabled={disabled.value}
                                    onClick={() => {
                                        context.emit(
                                            "click",
                                            item.key,
                                            props.record
                                        );
                                    }}
                                >
                                    {typeof item.title === "string"
                                        ? item.title
                                        : item.title(props.record)}
                                </MenuItem>
                            );
                        }
                    })}
                </Menu>
            ),
        };
        return () => (
            <Dropdown overlayClassName="action-dropdown">{slots}</Dropdown>
        );
    },
});

const useDisable = (actionKey: OperationActionKey) => {
    const disableFn = inject<(actionKey: OperationActionKey) => boolean>(
        "disableFn",
        () => false
    );

    return computed(() => disableFn(actionKey));
};
