import { FormPresenter } from "../Presenters/FormPresenter";
import { GridPresenter } from "../Presenters/GridPresenter";
import {
    defineAsyncComponent,
    defineComponent,
    onMounted,
    onUnmounted,
    PropType,
    watch,
    ref,
    toRaw,
    nextTick,
} from "vue";
import IQueryForm from "../IQueryForm/IQueryForm";
import { IEntityGrid } from "@/components/i-components/IEntityGrid/IEntityGrid";
import "@intasect/ant-design-vue/es/select/style";
import "./style.less";
import { cloneDeep } from "lodash-es";
import { devConsole } from "@intasect/platform-core";

const Select = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/select")
);
interface FormState {
    pass: string;
    checkPass: string;
    age: number | undefined;
}
export default defineComponent({
    name: "i-refer-table",
    emits: [
        "query",
        "reset",
        "queryFormChange",
        "update:value",
        "selectedChange",
        "selectOpen",
        "tableReset"
    ],
    components: {
        IQueryForm,
        IEntityGrid,
        Select,
    },
    props: {
        //查询formPresenter
        formPresenter: {
            type: Object as PropType<FormPresenter<any, any>>,
        },
        //表格presenter
        gridPresenter: {
            type: Object as PropType<GridPresenter<any, any>>,
        },
        //是否自动查询
        autoQuery: {
            type: Boolean,
            default: true,
        },
        //值
        value: {
            type: [Array, String, Number, Boolean] as PropType<
                | string
                | number
                | boolean
                | Array<string>
                | Array<number>
                | Array<boolean>
            >,
        },
        //用于绑定选项的字段
        fieldNames: {
            type: Object as PropType<{ label: string; value: string }>,
            required: true,
        },
        //是否隐藏操作列
        hideActionColumn: {
            type: Boolean,
            default: true,
        },
        hideOrderColumn: {
            type: Boolean,
            default: true,
        },
        multi: Boolean,
        defaultFrom: Object
    },
    setup(props, context) {
        const formData = ref(null);
        const formChang = (values: any) => {
            context.emit("queryFormChange", values);
            formData.value = values;
        };
        const Reset = () => {
            context.emit("reset", formData.value);
            if(props.defaultFrom){
                props.gridPresenter?.setQueryParams(props.defaultFrom);
                context.emit('tableReset')
            }
            props.gridPresenter?.refresh();
        };
        const Query = (values: FormState) => {
            context.emit("query", values);
            props.gridPresenter?.setQueryParams(values);
            props.gridPresenter?.refresh();
        };
        const renderForm = () => {
            if (props.formPresenter) {
                return (
                    <IQueryForm
                        presenter={props.formPresenter}
                        isExpand={true}
                        onChange={formChang}
                        onReset={Reset}
                        onQuery={Query}
                        withContainer={false}
                    />
                );
            }
        };

        const emitSelectRows = (selectedRows: any[]) => {
            if (props.gridPresenter?.rowSelectMulti) {
                const newValue = selectedRows.map(a =>
                    toRaw(a[props.fieldNames.value])
                );
                context.emit("selectedChange", cloneDeep(selectedRows));
                context.emit("update:value", newValue);
            }
        };

        const onRowSelectChange = (rowKeys: string[], selectedRows: any[]) => {
            emitSelectRows(selectedRows);
        };
        const rederList = () => {
            if (props.gridPresenter) {
                return (
                    <IEntityGrid
                        enableRowSelect={props.gridPresenter.rowSelectMulti}
                        hideActionColumn={props.hideActionColumn}
                        presenter={props.gridPresenter}
                        hideOrderColumn={props.hideOrderColumn}
                        onRowSelectChange={onRowSelectChange}
                        onRowSelect={selectedRow}
                        onRowClick={clickRow}
                        withContainer={false}
                    ></IEntityGrid>
                );
            }
        };
        const renderChildren = () => [renderForm(), rederList()];
        const selectedRow = (
            record: any,
            selected: boolean,
            selectedRows: any[]
        ) => {
            emitSelectRows(selectedRows);
        };
        const clickRow = (record: any) => {
            if (!props.gridPresenter?.rowSelectMulti) {
                selectOpen.value = false;
                const newValue = record[props.fieldNames.value];
                context.emit("selectedChange", [record]);
                context.emit("update:value", [newValue]);
            }
        };
        const selectOpen = ref(false);
        const inDropDownPanel = ref(false);
        const onDropdownVisibleChange = (visible: boolean) => {
            if (!visible && inDropDownPanel.value) {
                return;
            }
            selectOpen.value = visible;
        };
        const checkInDropDownPanel = (e: MouseEvent) => {
            if (pageRef.value) {
                const rect =
                    pageRef.value.parentElement?.parentElement?.getBoundingClientRect();
                if (rect) {
                    if (
                        rect.x <= e.x &&
                        rect.x + rect.width >= e.x &&
                        rect.y <= e.y &&
                        rect.y + rect.height >= e.y
                    ) {
                        inDropDownPanel.value = true;
                    } else {
                        inDropDownPanel.value = false;
                    }
                }
            }
        };

        onMounted(() => {
            window.addEventListener("mousedown", checkInDropDownPanel);
        });

        onUnmounted(() => {
            window.removeEventListener("mousedown", checkInDropDownPanel);
        });
        const onSelect = () => {
            selectOpen.value = false;
        };
        const pageRef = ref<HTMLElement | undefined>();
        const onChange = (keys: any, options: any) => {
            if (keys) {
                const records = props.gridPresenter?.dataSource.value.filter(
                    a => keys.includes(a[props.fieldNames.value])
                );
                context.emit("selectedChange", records);
                context.emit("update:value", records);
            }
            context.emit("selectedChange");
            context.emit("update:value");
        };
        const loadValue = async (val: any) => {
            props.gridPresenter?.setQueryParams({
                [props.fieldNames.value]: val,
            });
            await props.gridPresenter?.refresh();
            props.gridPresenter?.setQueryParams({
                [props.fieldNames.value]: undefined,
            });
        };

        const stop = watch(
            () => props.value,
            () => {
                if (Array.isArray(props.value)) {
                    if (props.value.length > 1) {
                        //当有多个值的情况，如何预加载保证数据正常显示？
                        devConsole("to do!");
                    } else if (
                        props.value[0] !== undefined &&
                        !props.gridPresenter?.dataSource.value.some(
                            a =>
                                a[props.fieldNames.value] ===
                                //@ts-ignore
                                (props.value![0] as string)
                        )
                    ) {
                        loadValue(props.value[0]);
                        nextTick(() => {
                            stop();
                        });
                    }
                } else {
                    const type = typeof props.value;
                    switch (type) {
                        case "number":
                            loadValue(props.value);
                            break;
                        case "string":
                            if ((props.value! as string).length > 0) {
                                loadValue(props.value);
                            }
                            break;
                    }
                    nextTick(() => {
                        stop();
                    });
                }
            },
            { immediate: true }
        );

        watch(
            () => props.multi,
            m => {
                if (props.gridPresenter) {
                    props.gridPresenter.rowSelectMulti = m;
                }
            },
            { immediate: true }
        );

        watch(
            () => props.gridPresenter,
            p => {
                if (p) {
                    p.rowSelectMulti = props.multi;
                }
            },
            { immediate: true }
        );
        watch(
            () => selectOpen.value,
            p => {
                if (p&&props.defaultFrom) {
                    context.emit("selectOpen");
                    props.gridPresenter.entity.pageCondition = {
                        ...props.gridPresenter?.entity.pageCondition,
                        ...props.defaultFrom
                    }
                }
            },
            
        );
        
        

        context.expose({
            get options() {
                return (props.gridPresenter?.dataSource.value || []).map(a => ({
                    label: a[props.fieldNames.label],
                    value: a[props.fieldNames.value],
                    data: a,
                }));
            },
        });
        return () => (
            <div class="i-refer-table">
                <Select
                    v-model={[props.value, "value"]}
                    fieldNames={props.fieldNames}
                    options={props.gridPresenter?.dataSource.value}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDropdownVisibleChange={onDropdownVisibleChange}
                    open={selectOpen.value}
                    dropdownMatchSelectWidth={false}
                    allowClear={true}
                    dropdownRender={() => (
                        <div
                            ref={pageRef}
                            class="i-refer-search-page i-search-page container"
                        >
                            <div class="content">{renderChildren()}</div>
                        </div>
                    )}
                    mode={
                        props.gridPresenter?.rowSelectMulti
                            ? "multiple"
                            : undefined
                    }
                ></Select>
            </div>
        );
    },
});
