import { cascaderProps } from "@intasect/ant-design-vue/es/cascader/index";
import { defineAsyncComponent, defineComponent } from "vue";
import '@intasect/ant-design-vue/es/cascader/style'

const Cascader = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/cascader'))
const Porps = cascaderProps();

export default defineComponent({
    name: "i-cascader",
    components: {
        Cascader,
    },
    props: Porps,
    setup(props) {
        return () => <Cascader {...props}></Cascader>;
    },
});
