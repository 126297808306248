import { MetadataMap } from "@/entities";
import { usePlatform } from "@/utils/composables";
import { markRaw } from "vue";
import {
    ControlType,
    DatePickerProps,
    FormDef,
    FormFieldDef,
    FormGroupItemDef,
    FormItemDef,
    FormSolutionItemDef,
    InputNumberProps,
    InputProps,
    ReferProps,
    TimePickerProps,
    FormGroupDef,
    GridSolutionItemDef,
    GridDef,
    SorterType,
    FormTableDef,
    FormTableItemDef,
} from "./declare";
import { FieldType } from "@intasect/platform-core";

export const generateFormFieldDef = (
    entityName: keyof typeof MetadataMap,
    formDef: FormDef
) => {
    const platform = usePlatform();
    const formMetaData = platform.getMetaData(entityName);
    const list: Array<FormSolutionItemDef> = [];
    const metaKeys: string[] = [];
    //实体字段def构建
    formMetaData.forEach(meta => {
        metaKeys.push(meta.fieldName as string);
        if (
            meta.fieldName in formDef &&
            formDef[meta.fieldName as string]!.isGroup !== true
        ) {
            if (formDef[meta.fieldName as string]!.controlType !== "table") {
                //@ts-ignore
                const def: FormItemDef = {
                    ...formDef[meta.fieldName as string],
                    meta: { ...meta, virtual: false },
                    title: (formDef[meta.fieldName as string]! as FormItemDef)
                        .hideTitle
                        ? ""
                        : (formDef[meta.fieldName as string]! as FormItemDef)
                              .title || meta.title,
                };
                if (def.itemRender) {
                    def.itemRender = markRaw(def.itemRender);
                }
                if (!def.controlType) {
                    def.controlType = genDefaultControlType(def);
                }
                if (!def.props) {
                    def.props = genDefaultControlTypeProps(def.controlType);
                }
                list.push(def);
            } else {
                const tableDef = formDef[
                    meta.fieldName as string
                ] as FormTableDef;
                const { isGroup, meta: m, ...rest } = tableDef;
                const tableItemDef: FormTableItemDef = {
                    ...rest,
                    meta,
                };
                list.push(tableItemDef);
            }
        }
    });
    //虚拟字段def构建
    Object.keys(formDef)
        .filter(a => !metaKeys.includes(a))
        .forEach(key => {
            if (
                formDef[key] &&
                formDef[key]!.meta &&
                formDef[key]!.isGroup !== true
            ) {
                formDef[key]!.meta!.virtual = true;
                //@ts-ignore
                const def: FormItemDef = {
                    ...formDef[key],
                    meta: { ...formDef[key]!.meta!, virtual: true },
                    title:
                        (formDef[key]! as FormItemDef).title ||
                        formDef[key]!.meta!.title,
                };
                if (def.itemRender) {
                    def.itemRender = markRaw(def.itemRender);
                }
                if (!def.controlType) {
                    def.controlType = genDefaultControlType(def);
                }
                if (!def.props) {
                    def.props = genDefaultControlTypeProps(def.controlType);
                }
                list.push(def);
                //分组构建
            } else if (formDef[key]!.isGroup) {
                const d = formDef[key] as FormGroupDef;
                const def: FormGroupItemDef = {
                    ...d,
                    controlType: d.controlType || "card",
                    grouName: key,
                    children: generateFormFieldDef(entityName, d.children),
                };
                list.push(def);
            } else if (formDef[key]!.controlType === "table") {
                const tableDef = formDef[key] as FormTableDef;
                const { isGroup, meta, ...rest } = tableDef;
                const tableItemDef: FormTableItemDef = {
                    meta: formDef[key]!.meta!,
                    ...rest,
                };
                list.push(tableItemDef);
            } else {
                console.warn(
                    `can't init formDef,missing metadata`,
                    formDef[key]
                );
            }
        });
    return list
        .filter(a => a)
        .sort((a, b) => {
            return a.order - b.order;
        });
};

export const generateGridFormFieldDef = (
    entityName: keyof typeof MetadataMap,
    formDef: FormDef
) => {
    return getFormItemDefs(generateFormFieldDef(entityName, formDef));
};

export const getFormItemDefs = (list: FormSolutionItemDef[]): FormItemDef[] => {
    const result: FormItemDef[] = [];
    list.forEach(item => {
        if (
            item.controlType === "card" ||
            item.controlType === "tab" ||
            item.controlType === "tabItem"
        ) {
            result.push(...getFormItemDefs(item.children));
        } else {
            result.push(item as FormItemDef);
        }
    });
    return result;
};

export const genFormItemDef = (defs: FormSolutionItemDef[]): FormItemDef[] => {
    const list: FormItemDef[] = [];
    defs.forEach(def => {
        if (
            def.controlType === "card" ||
            def.controlType === "tab" ||
            def.controlType === "tabItem"
        ) {
            list.push(...genFormItemDef(def.children));
        } else {
            const itemDef = def as FormItemDef;
            list.push({
                ...(def as FormItemDef),
                itemRender: itemDef.itemRender
                    ? markRaw(itemDef.itemRender)
                    : undefined,
                viewModeDisplay: itemDef.viewModeDisplay
                    ? markRaw(itemDef.viewModeDisplay)
                    : undefined,
            });
        }
    });
    return list;
};

export const genDefaultControlType = (def: FormFieldDef): ControlType => {
    if (def.refer) {
        return "refer";
    }
    return genControlType(def.meta.fieldType);
};

export const genControlType = (fieldType: FieldType) => {
    switch (fieldType) {
        default:
        case "string":
            return "input";
        case "number":
            return "inputNumber";
        case "date":
            return "datePicker";
        case "dateTime":
            return "dateTimePicker";
        case "time":
            return "timePicker";
        case "none":
            return "none";
    }
};

export const genDefaultControlTypeProps = (controlType: ControlType) => {
    switch (controlType) {
        default:
        case "input":
            return {} as InputProps;
        case "inputNumber":
            return {} as InputNumberProps;
        case "datePicker":
            return { format: "YYYY/MM/DD" } as DatePickerProps;
        case "timePicker":
            return { format: "HH:mm:ss" } as TimePickerProps;
        case "refer":
            return { displayType: "select" } as ReferProps;
    }
};

export const generateGridItemDef = (
    entityName: keyof typeof MetadataMap,
    gridDef: GridDef
) => {
    const list: Array<GridSolutionItemDef> = [];
    const map: { [fieldName: string]: GridSolutionItemDef } = {};
    const metaKeys: string[] = [];
    let sorterMap: Array<SorterType> = [];
    const platform = usePlatform();
    const gridMetaData = platform.getMetaData(entityName);
    //实体字段def构建
    gridMetaData.forEach(meta => {
        metaKeys.push(meta.fieldName as string);
        if (meta.fieldName in gridDef) {
            const def = {
                title: meta.title,
                meta: { ...meta, virtual: false },
                titleAlign: meta.fieldType === "number" ? "right" : "left",
                contentAlign: meta.fieldType === "number" ? "right" : "left",
                ...gridDef[meta.fieldName as string],
            } as GridSolutionItemDef;
            if (typeof def.order === "number") {
                if (list[def.order] === undefined) {
                    list[def.order] = def;
                } else {
                    list.splice(def.order, 0, def);
                }
            } else {
                list.push(def);
            }
            map[meta.fieldName as string] = def;
            if (def.sort) {
                const sortType = gridDef[meta.fieldName as string]?.sort;
                const fieldName = meta.fieldName as string;
                if (sortType && sortType !== "default") {
                    const item = sorterMap.find(a => a.fieldName === fieldName);
                    if (item) {
                        item.sortType = sortType;
                    } else {
                        sorterMap.push({ fieldName, sortType });
                    }
                } else {
                    sorterMap = sorterMap.filter(
                        a => a.fieldName !== fieldName
                    );
                }
            }
        }
    });
    //虚拟字段def构建
    Object.keys(gridDef)
        .filter(a => !metaKeys.includes(a))
        .forEach(key => {
            if (gridDef[key] && gridDef[key]!.meta) {
                gridDef[key]!.meta!.virtual = true;
                const def = {
                    title: gridDef[key]!.meta!.title,
                    ...gridDef[key],
                    meta: { ...gridDef[key]!.meta!, virtual: true },
                } as GridSolutionItemDef;
                if (def.order) {
                    list[def.order] = def;
                } else {
                    list.push(def);
                }
                map[def.meta.fieldName as string] = def;
            } else {
                console.warn(
                    `can't init gridDef,missing metadata`,
                    gridDef[key]
                );
            }
        });
    return {
        list: list.filter(a => a).sort((a, b) => a.order - b.order),
        map,
        sorterMap,
    };
};
