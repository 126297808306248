import {
    defineComponent,
    markRaw,
    onMounted,
    onUnmounted,
    ref,
    toRaw,
    watch,
} from "vue";
import { useEditableForm, useIGridContext } from "./hooks";
import { cloneDeep } from "lodash-es";

export const EditableRow = defineComponent({
    props: {
        record: Object,
        index: Number,
    },
    setup(props, context) {
        const iGridContext = useIGridContext();
        const formItemDefs = ref(
            cloneDeep(toRaw(iGridContext.formItemDefs.value)).map(a => {
                if (a.itemRender) {
                    a.itemRender = markRaw(a.itemRender);
                }
                return a;
            })
        );
        watch(
            () => iGridContext.formItemDefs.value,
            v => {
                formItemDefs.value = cloneDeep(toRaw(v)).map(a => {
                    if (a.itemRender) {
                        a.itemRender = markRaw(a.itemRender);
                    }
                    return a;
                });
            }
        );

        if (props.index !== undefined && props.record !== undefined) {
            const [iFormContext, validate] = useEditableForm(
                formItemDefs,
                props.record,
                props.index
            );

            onMounted(() => {
                iGridContext.addValidateFn(validate);
                iGridContext.addEditableRowFormContext(
                    props.index!,
                    iFormContext
                );
            });
            onUnmounted(() => {
                iGridContext.removeValidateFn(validate);
                iGridContext.removeEditableRowFormContext(props.index!);
            });
            watch(
                () => props.record,
                () => {
                    iGridContext.dispatchRowCreated.call(
                        undefined,
                        props.record,
                        props.index!,
                        formItemDefs.value,
                        iFormContext
                    );
                },
                { immediate: true }
            );
        }
        return () => <tr {...context.attrs}>{{ ...context.slots }}</tr>;
    },
});
