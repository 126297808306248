import { selectProps } from "@intasect/ant-design-vue/es/select/index";
import { defineAsyncComponent, defineComponent, computed } from "vue";
import '@intasect/ant-design-vue/es/select/style'

const Select = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/select'))
const Porps = selectProps();
export default defineComponent({
    name: "i-select",
    components: {
        Select,
    },
    props: {
        ...Porps,
        allowClear: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, context) {
        const options = computed(() => {
            if (props.loading) {
                return [
                    {
                        [props.fieldNames?.label || "label"]: "载入中...",
                        [props.fieldNames?.value || "value"]: "载入中...",
                        disabled: true,
                    },
                ];
            }
            return props.options;
        });
        return () => (
            <Select {...props} options={options.value}>
                {{
                    ...context.slots,
                }}
            </Select>
        );
    },
});
