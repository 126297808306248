import { GridOperationAction } from "@/components/i-components/ActionCell/ActionCell";
import { MetadataMap } from "@/entities";
import { usePlatform } from "@/utils/composables";
import {
    EntityController,
    PageCondition,
    Metadata,
    PageResonse,
    EntityData,
    RefType,
} from "@intasect/platform-core";
import { FormDef, GridDef, GridSolutionItemDef, SorterType } from "./declare";
import { GridPresenterCore } from "./GridPresenterCore";
import { generateGridFormFieldDef, generateGridItemDef } from "./utils";

export class GridPresenter<
    EntityDef extends { [name: string]: any },
    Controller extends EntityController<EntityDef, PC, PR, ED>,
    PC = PageCondition<EntityDef>,
    PR = PageResonse<EntityDef>,
    ED = EntityData<EntityDef>
> extends GridPresenterCore<EntityDef, Controller, PC, PR, ED> {
    protected readonly gridMetaData: Metadata[];
    private _gridDef: GridDef;
    constructor(
        public readonly entityName: keyof typeof MetadataMap,
        gridDef: GridDef,
        actions: GridOperationAction<any>[] = [],
        formDef: FormDef = {},
        public isPaging: boolean = true,
        public rowActionsMaxShowLength: number = 3,
        //@ts-ignore
        protected pageCondition: PageCondition<EntityDef> = {
            pageNum: 1,
            pageSize: 30,
            orderByField: "",
        },
        functionName?: string
    ) {
        const platform = usePlatform();
        if (!platform) {
            throw new Error("platform not exist!");
        }
        super(
            platform.observerAPI,
            { platform },
            platform.getController<EntityDef, any>(
                entityName as string
            ) as any as Controller,
            platform.observerAPI.ref(generateGridItemDef(entityName, gridDef)),
            actions,
            isPaging,
            rowActionsMaxShowLength,
            pageCondition,
            () => true,
            generateGridFormFieldDef(entityName, formDef || {}),
            functionName
        );
        this._gridDef = gridDef;
    }

    get gridDef() {
        return this._gridDef;
    }

    set gridDef(val) {
        this._gridDef = val;
        this.originalGridItemDef.value = generateGridItemDef(
            this.entityName,
            val
        );
    }
}
