import { defineComponent, PropType, defineAsyncComponent } from "vue";
import "./index.less";
import '@intasect/ant-design-vue/es/tooltip/style'
import '@intasect/ant-design-vue/es/tag/style'

const Tooltip = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/tooltip'))
const Tag = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/tag'))
export type tagType = "primary" | "success" | "danger" | "warning";
export default defineComponent({
    name: "i-tag",
    components: {
        Tag,
        Tooltip,
    },
    emits: ["close"],
    props: {
        // ...tagProps(),
        // color: {
        //     type: String,
        //     default: "info",
        // },
        type: {
            type: String as PropType<tagType>,
            default: "primary",
        },
        content: {
            type: String,
        },
        closable: Boolean,
    },
    //info success  warning error
    setup(props, { slots, emit }) {
        let className = "i-tag";
        switch (props.type) {
            case "primary":
                className += "-info";
                break;
            case "success":
                className += "-success";
                break;
            case "warning":
                className += "-warning";
                break;
            case "danger":
                className += "-error";
                break;
            default:
                className = "";
                break;
        }
        const close = () => { };
        if (props.content && props.content.length > 8) {
            return () => (
                <Tooltip title={props.content}>
                    <Tag
                        onClose={close}
                        closable={props.closable}
                        class={`i-tag ${className}`}
                    >
                        {{
                            default: () => `${props.content!.slice(0, 8)}...`,
                            icon: slots.icon,
                        }}
                    </Tag>
                </Tooltip>
            );
        }
        return () => (
            <Tag
                onClose={close}
                closable={props.closable}
                class={`i-tag ${className}`}
            >
                {{ default: () => props.content, icon: slots.icon }}
            </Tag>
        );
    },
});
