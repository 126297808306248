import { defineAsyncComponent, defineComponent, PropType, ref } from "vue";
const Alert = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/alert'))
import '@intasect/ant-design-vue/es/alert/style'

export default defineComponent({
    name: "i-alert",
    components: {
        Alert,
    },
    props: {
        type: {
            type: String as PropType<"success" | "info" | "warn" | "error">,
            default: "success",
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        message: String,
        icon: String,
        closeable: Boolean,
    },
    setup(props, { slots }) {
        const handleClose = () => { };
        let type = props.type === "warn" ? "warning" : props.type;
        return () => (
            <Alert
                class={`i-alert-${type}`}
                show-icon={props.showIcon}
                message={props.message}
                type={type as any}
                closable={props.closeable}
                after-close={handleClose}
                v-slots={slots}
            ></Alert>
        );
    },
});
