import type { MetadataMap } from "@/entities";
import { usePlatform } from "@/utils/composables";
import {
    EntityController,
    EntityData,
    Metadata,
    PageCondition,
    PageResonse,
} from "@intasect/platform-core";
import { FormDef } from "./declare";
import { FormPresenterCore } from "./FormPresenterCore";
import { generateFormFieldDef } from "./utils";
export class FormPresenter<
    EntityDef extends { [key: string]: any },
    Controller extends EntityController<EntityDef, PC, PR, ED>,
    PC = PageCondition<EntityDef>,
    PR = PageResonse<EntityDef>,
    ED = EntityData<EntityDef>
> extends FormPresenterCore<EntityDef, Controller, PC, PR, ED> {
    protected readonly formMetaData: Metadata[];
    //实体模型到视图模型的转换
    constructor(
        protected entityName: keyof typeof MetadataMap,
        protected formDef: FormDef
    ) {
        const platform = usePlatform();
        if (!platform) {
            throw new Error("platform not exist!");
        }
        const Controller = platform.getController<EntityDef, any>(
            entityName
        ) as any as Controller;
        super(
            generateFormFieldDef(entityName, formDef),
            Controller,
            platform.observerAPI
        );
        this.formMetaData = platform.getMetaData(entityName);
    }
}
