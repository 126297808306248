import { TableRowSelection } from "@intasect/ant-design-vue/es/table/interface";
import { defineComponent, PropType, ref, watchEffect, watch } from "vue";
import {
    GridOperationAction,
    OperationActionKey,
} from "../ActionCell/ActionCell";
import { ActionButton } from "./ActionButton";

export const ActionButtons = defineComponent({
    props: {
        actions: {
            type: Array as PropType<GridOperationAction[]>,
            required: true,
        },
        rowActionVisible: {
            type: Function as PropType<
                (actionKey: OperationActionKey) => boolean
            >,
            default: () => true,
        },
        rowActionDisable: {
            type: Function as PropType<
                (actionKey: OperationActionKey) => boolean
            >,
            default: () => true,
        },
        rowSelection: {
            type: Object as PropType<TableRowSelection>,
        },
    },
    emits: ["actionClick"],
    setup(props, context) {
        const visibleActions = ref(
            props.actions.filter(a => props.rowActionVisible(a.key))
        );
        watchEffect(() => {
            visibleActions.value = props.actions.filter(a =>
                props.rowActionVisible(a.key)
            );
        });
        watch(
            () => props.rowSelection?.selectedRowKeys?.length,
            () => {
                visibleActions.value = props.actions.filter(a =>
                    props.rowActionVisible(a.key)
                );
            }
        );
        const onClick = (actionKey: string) => {
            context.emit("actionClick", actionKey);
        };
        return () =>
            visibleActions.value.map(action => (
                <ActionButton
                    onActionClick={onClick}
                    key={action.key}
                    rowActionDisable={props.rowActionDisable}
                    rowSelection={props.rowSelection}
                    action={action}
                />
            ));
    },
});
