import { useForm } from "@intasect/ant-design-vue/es/form";
import {
    defineComponent,
    PropType,
    ref,
    inject,
    Ref,
    watchEffect,
    computed,
} from "vue";
import { IFormItem } from "../IForm/hooks";
import { FormItemDef, ValidationStatus } from "../Presenters/declare";

export const EditableGridItem = defineComponent({
    props: {
        mode: {
            type: String as PropType<"view" | "default">,
            default: "default",
        },
        fieldName: { type: String, required: true },
        formModel: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        providers: {
            type: Object as PropType<
                Record<string, (def: FormItemDef, value: any) => any>
            >,
            default: () => {},
        },
    },
    setup(props) {
        const error = ref("");
        const context = inject<{
            editableFormContext: ReturnType<typeof useForm>;
            formItemDefs: Ref<FormItemDef[]>;
            validateResult: Ref<Record<string, string[]> | undefined>;
        }>("editableForm");
        const def = context!.formItemDefs.value.find(
            a => a.meta.fieldName === props.fieldName
        );
        if (def === undefined) {
            throw new Error(`can't find def with ${props.fieldName}`);
        }

        watchEffect(() => {
            if (
                context?.editableFormContext.validateInfos &&
                context?.editableFormContext.validateInfos[props.fieldName] &&
                context?.editableFormContext.validateInfos[props.fieldName]
                    .validateStatus === "error"
            ) {
                error.value =
                    context?.editableFormContext.validateInfos[
                        props.fieldName
                    ].help.join(",");
            } else {
                error.value = "";
            }
        });

        const status = computed<ValidationStatus>(() => {
            if (error.value) {
                return "error";
            }
            return "default";
        });

        return () => [
            <IFormItem
                def={def}
                class="editable-cell"
                status={status.value}
                {...props}
            />,
            error.value && <p class="editable-cell-error">{error.value}</p>,
        ];
    },
});
