import { Validation } from "../Presenters/declare";
import { FieldType } from "@intasect/platform-core";
import { RuleType, Rule } from "async-validator";

export const ValidateRegexp = {
    // 小写字母
    LOWER_CASE: /^[a-z]+$/,
    // 大写字母
    UPPER_CASE: /^[A-Z]+$/,
    // 纯字母
    ALPHABET: /^[A-Za-z]+$/,
    // 纯字母+数字
    ALPHANUM: /^[A-Za-z0-9]+$/,
    // url
    URL: /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/,
    // Phone
    PHONE: /^1\d{10}$/,
    // 手机号 | 座机号
    PHONE_NUM: /^((0[0-9]{2,3}-)+([0-9]{7,8})+(-[0-9]{1,4})?$)|((1\d{10})$)/,
    // 座机号
    PHONE_FIXED: /^((0[0-9]{2,3}-)+([0-9]{7,8})+(-[0-9]{1,4})?$)/,
    INTEGER_POSITIVE_IDX: /^[1-9][0-9]?$/,
    //非负整数的正则表达式(小于99)
    INTEGER_POSITIVE: /^[0-9]*$/,
    //非负整数的正则表达式(包括0)
    INTEGER_NONNEGATIVE: /^([0]|[1-9][0-9]*)$/,
    // 2位小数
    FLOAT_2: /^(-)?(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)/,
    // 正浮点数，最多2位小数
    FLOAT_POSITION_2: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/,
    // 非负浮点数，最多2位小数
    FLOAT_NON_NEGATIVE_2: /^(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)$/,
};

export const EmailDecorator: Rule = {
    type: "email",
    message: "请输入正确的E-mail",
};

export const IntegerDecorator = {
    validator: (rule: any, value: string, callback: any) => {
        if (
            value === undefined ||
            ValidateRegexp.INTEGER_POSITIVE.test(value)
        ) {
            callback();
            return;
        }
        callback("请输入整数");
    },
};
export const IDXDecorator = {
    validator: (rule: any, value: string, callback: any) => {
        if (
            value === undefined ||
            ValidateRegexp.INTEGER_POSITIVE_IDX.test(value)
        ) {
            callback();
            return;
        }
        callback("请输入小于等于99的正整数");
    },
};

export const FloatDecorator = {
    validator: (rule: any, value: string, callback: any) => {
        if (value === undefined || ValidateRegexp.FLOAT_2.test(value)) {
            callback();
            return;
        }
        callback("请保留两位小数");
    },
};

export const RequireDecorator = (title: string = "内容") => ({
    required: true,
    message: `请输入${title}`,
});

export const RequireDecoratorSelect = (title: string = "") => ({
    required: true,
    message: `请选择${title}`,
});

export const customRule = (regular: RegExp, msg: string | undefined) => {
    const backrule = {
        validator: (rule: any, value: string, callback: any) => {
            if (value === undefined || regular.test(value)) {
                callback();
                return;
            }
            callback(msg || "无法通过校验");
        },
    };
    return backrule;
};

const genValidationType = (type: FieldType): RuleType => {
    switch (type) {
        case "string":
            return "string";
        case "number":
            return "number";
        case "date":
        case "dateTime":
        case "time":
        case "timeSpan":
            return "date";
        case "bool":
            return "boolean";
        default:
            return "any";
    }
};

export const genRule = (
    title: string | undefined,
    validation: Validation | undefined,
    fieldType: FieldType = "string",
    customContext: any = {}
) => {
    const rules: Rule[] = [];
    if (validation) {
        const type = validation.type || genValidationType(fieldType);
        if (validation.required) {
            rules.push({
                required: true,
                message: validation.ruleMsg || `${title}不能为空`,
                type,
            });
        }
        if (validation.length) {
            rules.push({
                len: validation.length,
                // message: validation.ruleMsg || `${title}长度`,
                type,
            });
        }
        if (validation.min !== undefined || validation.max !== undefined) {
            rules.push({
                min: validation.min,
                max: validation.max,
                type,
                //message: validation.ruleMsg || `${title}range`,
            });
        }
        if (validation.ruleType && typeof validation?.ruleType !== "string") {
            const decoratorRule = customRule(
                validation?.ruleType,
                validation?.ruleMsg
            );
            rules.push(decoratorRule);
        } else if (validation.ruleType) {
            switch (validation.ruleType) {
                case "email":
                    rules.push(EmailDecorator);
                    break;
                case "integer":
                    rules.push(IntegerDecorator);
                    break;
                case "float":
                    rules.push(FloatDecorator);
                    break;
                case "ids":
                    rules.push(IDXDecorator);
                    break;
                default:
                    break;
            }
        } else if (validation.ruleResolve) {
            rules.push({
                asyncValidator(rule, value, callback) {
                    return validation.ruleResolve!(value, customContext);
                },
            });
        }
    }
    return rules;
};

export const getStringLength = (str: string) => {
    return str.match(/[^ -~]/g) == null
        ? str.length
        : str.length + (str.match(/[^ -~]/g) || "").length;
};
