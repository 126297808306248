import { TreeSelect } from "@intasect/ant-design-vue";
import { treeSelectProps } from "@intasect/ant-design-vue/es/tree-select/index";
import { defineComponent } from "vue";
const Porps = treeSelectProps();
export default defineComponent({
    name: "i-refer-tree",
    components: {
        TreeSelect,
    },
    props: {
        ...Porps,
        //是否多选
        multi: Boolean,
    },
    setup(props) {
        const showLeafIcon = false;
        return () => (
            <TreeSelect
                {...props}
                tree-line={{ showLeafIcon }}
                tree-checkable={props.multi}
            ></TreeSelect>
        );
    },
});
