import inputProps from "@intasect/ant-design-vue/es/input/inputProps";
import {
    computed,
    defineAsyncComponent,
    defineComponent,
    onMounted,
    PropType,
    ref,
} from "vue";
import "@intasect/ant-design-vue/es/input/style";
import "./style.less";
import { ValidationStatus } from "../Presenters/declare";

const Input = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/input")
);
const InputSearch = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/input/Search")
);

const TextArea = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/input/TextArea")
);

export default defineComponent({
    name: "i-input",
    components: {
        Input,
        TextArea,
    },
    props: {
        ...inputProps(),
        status: {
            type: String as PropType<ValidationStatus>,
            default: "default",
        },
        allowClear: {
            type: Boolean,
            default: true,
        },
        multi: Boolean,
        rows: {
            type: Number,
            default: 3,
        },
    },
    setup(props, { expose, slots, attrs }) {
        const inputRef = ref();
        const dom = ref<HTMLElement>();
        expose({
            dom,
        });
        onMounted(() => {
            if (inputRef.value) {
                dom.value = props.multi
                    ? inputRef.value.resizableTextArea.textArea.parentElement
                    : inputRef.value.input.parentElement;
            }
        });
        const classNames = computed(() => {
            return `i-input ${props.multi ? "i-textarea" : ""} i-input-${
                props.status
            } ${attrs.class || ""}`;
        });
        const textareaProps = computed(() => {
            if (props.multi) {
                const { prefix, ...rest } = props;
                return rest;
            }
            return {};
        });

        return () => {
            return props.multi ? (
                <TextArea
                    {...textareaProps.value}
                    rows={props.rows}
                    class={classNames.value}
                    ref={inputRef}
                />
            ) : (
                <Input {...props} class={classNames.value} ref={inputRef}>
                    {slots}
                </Input>
            );
        };
    },
});

export const IInputSearch = defineComponent({
    name: "i-input-search",
    props: {
        ...inputProps(),
    },
    components: { InputSearch },
    setup(props, { slots }) {
        return () => (
            <InputSearch {...props} allowClear>
                {slots}
            </InputSearch>
        );
    },
});
