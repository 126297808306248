import { defineComponent } from "vue";

const genOrder = (
    prevTotal: number | undefined,
    index: number,
    renderIndex: number
) => {
    if (prevTotal !== undefined) {
        return prevTotal + index + 1;
    }
    return renderIndex + 1;
};

export const OrderColumn = defineComponent({
    props: {
        index: { type: Number, required: true },
        renderIndex: { type: Number, required: true },
        prevTotal: Number,
    },
    setup(props) {
        return () => (
            <span>
                {genOrder(props.prevTotal, props.index, props.renderIndex)}
            </span>
        );
    },
});
