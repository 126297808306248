import { progressProps } from "@intasect/ant-design-vue/es/progress/props";
import { defineAsyncComponent, defineComponent } from "vue";
import { ExclamationCircleFilled } from "@ant-design/icons-vue";
import "./index.less";
import '@intasect/ant-design-vue/es/progress/style'

const Progress = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/progress'));

export default defineComponent({
    name: "i-progress",
    components: {
        Progress,
    },
    props: {
        ...progressProps(),
        //是否显示图标
        showIcon: {
            type: Boolean,
        },
    },
    setup(props, { slots }) {
        const render = (percent: number | undefined) => {
            if (props.status === "exception" && props.showIcon) {
                return (
                    <>
                        <ExclamationCircleFilled />
                        <span style="color: #000;padding-left:3px">
                            {percent}%
                        </span>
                    </>
                );
            }
            return (
                <>
                    <span style="color: #000">{percent}%</span>
                </>
            );
        };
        return () => (
            <Progress {...props} format={percent => render(percent)}></Progress>
        );
    },
});
