import { Col } from "@intasect/ant-design-vue";
import { computed, defineComponent, PropType, ref, toRef } from "vue";
import { FormGroupItemDef, FormSolutionItemDef } from "../Presenters/declare";
import { IFormRow } from "./IFormRow";
import { Tabs } from '@intasect/ant-design-vue';
import { useFormItemCol, useFormResizeOb } from "./hooks";
const TabPane = Tabs.TabPane;

export const GroupFormItem = defineComponent({
    name: "i-form-group",
    props: {
        colspan: {
            type: Number,
            required: true,
        },
        item: {
            type: Object as PropType<FormGroupItemDef>,
            required: true,
        },
        //模式
        mode: {
            type: String as PropType<"default" | "view">,
            default: "default",
        },
        providers: {
            type: Object as PropType<
                Record<string, (def: FormSolutionItemDef, value: any) => any>
            >,
            default: () => ({}),
        },
        //表单的数据模型
        formModel: {
            type: Object as PropType<{ [key: string]: any }>,
            required: true,
        },
        //栅格数量
        gutter: {
            type: Number,
            default: 24,
        },
        container: {
            type: Object as PropType<HTMLElement>
        }
    },
    setup(props) {
        const containerRef = toRef(props, 'container');
        const { colSetting } = useFormResizeOb(containerRef, props.gutter, props.item.maxCol ?? 4);
        const children = computed(() => {
            switch (props.item.controlType) {
                case 'tab':
                    return <TabGroup {...props} colspan={colSetting.span.value} />
                case 'card':
                default:
                    return <CardGroup {...props} colspan={colSetting.span.value} />
            }
        })
        return () => children.value;
    },
});

const CardGroup = defineComponent({
    name: 'car-group',
    props: {
        colspan: {
            type: Number,
            required: true,
        },
        item: {
            type: Object as PropType<FormGroupItemDef>,
            required: true,
        },
        //模式
        mode: {
            type: String as PropType<"default" | "view">,
            default: "default",
        },
        providers: {
            type: Object as PropType<
                Record<string, (def: FormSolutionItemDef, value: any) => any>
            >,
            default: () => ({}),
        },
        //表单的数据模型
        formModel: {
            type: Object as PropType<{ [key: string]: any }>,
            required: true,
        },
        //栅格数量
        gutter: {
            type: Number,
            default: 24,
        },
    },
    setup(props) {
        const span = useFormItemCol(props, props.item);
        return () =>
            props.item.display !== false && (
                <Col
                    key={props.item.grouName}
                    span={span.value}
                    offset={props.item.offset}
                    class="i-form-group"
                >
                    <div class="group-title">
                        {{ default: () => props.item.title }}
                    </div>
                    <IFormRow
                        colspan={props.colspan}
                        gutter={props.gutter}
                        items={props.item.children}
                        mode={props.mode}
                        formModel={props.formModel}
                        providers={props.providers}
                    />
                </Col>
            );
    }
});

const TabGroup = defineComponent({
    name: 'car-group',
    props: {
        colspan: {
            type: Number,
            required: true,
        },
        item: {
            type: Object as PropType<FormGroupItemDef>,
            required: true,
        },
        //模式
        mode: {
            type: String as PropType<"default" | "view">,
            default: "default",
        },
        providers: {
            type: Object as PropType<
                Record<string, (def: FormSolutionItemDef, value: any) => any>
            >,
            default: () => ({}),
        },
        //表单的数据模型
        formModel: {
            type: Object as PropType<{ [key: string]: any }>,
            required: true,
        },
        //栅格数量
        gutter: {
            type: Number,
            default: 24,
        },
    },
    setup(props) {
        const span = useFormItemCol(props, props.item);
        return () =>
            props.item.display !== false && (
                <Col
                    key={props.item.grouName}
                    span={span.value}
                    offset={props.item.offset}
                    class="i-form-group"
                >
                    <Tabs>
                        {
                            (props.item.children.filter(a => a.controlType === 'tabItem') as FormGroupItemDef[])
                                .map(child =>
                                    <TabPane key={child.grouName} tab={child.title} >
                                        <IFormRow
                                            colspan={props.colspan}
                                            gutter={props.gutter}
                                            items={child.children}
                                            mode={props.mode}
                                            formModel={props.formModel}
                                            providers={props.providers}
                                        />
                                    </TabPane>)
                        }
                    </Tabs>
                </Col>
            );
    }
});