import { defineAsyncComponent, defineComponent, h } from "vue";
import '@intasect/ant-design-vue/es/popconfirm/style'

const Popconfirm = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/popconfirm'))

export default defineComponent({
    name: "i-popconfirm",
    components: {
        Popconfirm,
    },
    render() {
        return h(
            Popconfirm,
            {
                ...this.$attrs,
                wrapClassName: `${this.$attrs.wrapClassName || ""
                    } i-popconfirm`,
                on: this.$attrs,
            },
            {
                ...this.$slots,
            }
        );
    },
});
