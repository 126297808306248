import {
    TransferItem,
    transferProps,
} from "@intasect/ant-design-vue/es/transfer";
import { defineComponent, ref, onMounted, computed, PropType, defineAsyncComponent } from "vue";
import Sortable from "sortablejs";
import '@intasect/ant-design-vue/es/transfer/style'
import "./style.less";

const Transfer = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/transfer'))

type DragItem = { newIndex: number; oldIndex: number; item: HTMLElement };

type ChildrenType = {
    direction: "left" | "right";
    disabled: boolean;
    selectedKeys: string[];
    filteredItems: TransferItem[];
    onItemSelect: (key: string, selected: boolean) => void;
    onItemSelectAll: (keys: string[], selected: boolean) => void;
};

export const ITransferTable = defineComponent({
    name: "i-transfer-table",
    components: {
        Transfer,
    },
    props: {
        ...transferProps(),
        dataSource: {
            type: Array as PropType<Array<{ key: string;[key: string]: any }>>,
            required: true,
            default: () => [],
        },
    },
    setup(props, { slots }) {
        const data = ref(props.dataSource);
        const targetKeys = ref<string[]>([]);
        const leftData = computed(() =>
            data.value.filter(a => !targetKeys.value.includes(a.key))
        );
        let action = "";
        onMounted(() => {
            const iTransferWrapper =
                document.getElementsByClassName("i-transfer")[0];
            const tableBodys =
                iTransferWrapper.getElementsByClassName("ant-table-tbody");
            //@ts-ignore
            new Sortable(tableBodys[0], {
                handle: ".ant-table-row",
                animation: 150,
                ghostClass: "blue-background-class",
                group: "gp1111",
                sort: true,
                onEnd: ({ newIndex, oldIndex }: DragItem) => {
                    if (action !== "") {
                        action = "";
                        return;
                    }
                    const source = leftData.value[oldIndex]; // 谁
                    const destination = leftData.value[newIndex]; // 移动到哪儿
                    oldIndex = data.value.indexOf(source);
                    newIndex = data.value.indexOf(destination);
                    // 拖动后同步至stata
                    const newData = data.value.filter(
                        a => a.key !== source.key
                    );
                    const dIndex = newData.indexOf(destination);
                    if (oldIndex === newIndex) {
                        return;
                    }
                    if (oldIndex > newIndex) {
                        newData.splice(dIndex, 0, source);
                    } else {
                        newData.splice(dIndex + 1, 0, source);
                    }
                    data.value = newData;
                },
                onRemove(item: DragItem) {
                    item.item.remove();
                },
                onAdd(item: DragItem) {
                    action = "rightToLeft";
                    item.item.remove();
                    let { newIndex, oldIndex } = item;
                    const sourceKey = targetKeys.value[oldIndex]; // 谁
                    const destination = leftData.value[newIndex]; // 移动到哪儿
                    const source = data.value.find(a => a.key === sourceKey);
                    targetKeys.value = targetKeys.value.filter(
                        a => a !== sourceKey
                    );
                    if (source) {
                        newIndex = data.value.indexOf(destination);
                        oldIndex = data.value.indexOf(source);
                        // 拖动后同步至stata
                        const newData = data.value.filter(
                            a => a.key !== source.key
                        );
                        const dIndex = newData.indexOf(destination);
                        if (oldIndex === newIndex) {
                            return;
                        }
                        newData.splice(dIndex, 0, source);
                        data.value = newData;
                    }
                },
            });
            //@ts-ignore
            new Sortable(tableBodys[1], {
                handle: ".ant-table-row",
                animation: 150,
                ghostClass: "blue-background-class",
                group: "gp1111",
                sort: true,
                onAdd(item: DragItem) {
                    const { newIndex, oldIndex } = item;
                    action = "leftToRight";
                    const source = leftData.value[oldIndex].key; // 谁
                    const destination = targetKeys.value[newIndex]; // 移动到哪儿
                    // 拖动后同步至stata
                    const newTargetKeys = targetKeys.value;
                    if (destination) {
                        const dIndex = newTargetKeys.indexOf(destination);
                        newTargetKeys.splice(dIndex, 0, source);
                    } else {
                        newTargetKeys.push(source);
                    }
                    targetKeys.value = newTargetKeys;
                },
                onEnd: ({ newIndex, oldIndex }: DragItem) => {
                    if (action !== "") {
                        action = "";
                        return;
                    }
                    const source = targetKeys.value[oldIndex]; // 谁
                    const destination = targetKeys.value[newIndex]; // 移动到哪儿
                    // 拖动后同步至stata
                    const newTargetKeys = targetKeys.value.filter(
                        a => a !== source
                    );
                    const dIndex = newTargetKeys.indexOf(destination);
                    if (oldIndex > newIndex) {
                        newTargetKeys.splice(dIndex, 0, source);
                    } else {
                        newTargetKeys.splice(dIndex + 1, 0, source);
                    }
                    targetKeys.value = newTargetKeys;
                },
            });
        });

        const getRowSelection = ({
            disabled,
            selectedKeys,
            onItemSelectAll,
            onItemSelect,
        }: Record<string, any>) => {
            return {
                getCheckboxProps: (item: Record<string, string | boolean>) => ({
                    disabled: disabled || item.disabled,
                }),
                onSelectAll(
                    selected: boolean,
                    selectedRows: Record<string, string | boolean>[]
                ) {
                    const treeSelectedKeys = selectedRows
                        .filter(item => !item.disabled)
                        .map(({ key }) => key);
                    onItemSelectAll(treeSelectedKeys, selected);
                },
                onSelect({ key }: Record<string, string>, selected: boolean) {
                    onItemSelect(key, selected);
                },
                selectedRowKeys: selectedKeys,
            };
        };

        return {
            targetKeys,
            getRowSelection,
            slots,
        };
    },
    render() {
        return (
            <Transfer
                class="i-transfer"
                v-model={[this.targetKeys, "target-keys"]}
                dataSource={this.dataSource}
                disabled={false}
                showSearch={true}
                filterOption={(inputValue, item) =>
                    item.name.indexOf(inputValue) !== -1
                }
                showSelectAll={true}
                titles={["源列表", "目标列表"]}
                locale={{
                    itemUnit: "",
                    itemsUnit: "",
                    notFoundContent: "列表为空",
                    searchPlaceholder: "请输入搜索内容",
                }}
            >
                {{
                    children: ({
                        direction,
                        filteredItems,
                        selectedKeys,
                        disabled: listDisabled,
                        onItemSelectAll,
                        onItemSelect,
                    }: ChildrenType) =>
                        this.slots.default?.({
                            ref:
                                direction === "left" ? "tableRef" : "tableRef2",
                            rowSelection: this.getRowSelection({
                                disabled: listDisabled,
                                selectedKeys,
                                onItemSelectAll,
                                onItemSelect,
                            }),
                            dataSource: filteredItems,
                            style: {
                                PointerEvent: listDisabled ? "none" : null,
                            },
                            customRow: ({
                                key,
                                disabled: itemDisabled,
                            }: {
                                key: string;
                                disabled: boolean;
                            }) => ({
                                onClick: () => {
                                    if (itemDisabled || listDisabled) return;
                                    onItemSelect(
                                        key,
                                        !selectedKeys.includes(key)
                                    );
                                },
                            }),
                        }),
                }}
            </Transfer>
        );
    },
});

export const ITransfer = defineComponent({
    name: "i-transfer",
    props: {
        ...transferProps(),
    },
    components: {
        Transfer,
    },
    setup(props) {
        return () => (
            <Transfer
                class="i-transfer"
                {...props}
                titles={["源列表", "目标列表"]}
                locale={{
                    itemUnit: "",
                    itemsUnit: "",
                    notFoundContent: "列表为空",
                    searchPlaceholder: "请输入搜索内容",
                }}
            />
        );
    },
});
