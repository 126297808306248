import { Checkbox } from "@intasect/ant-design-vue";
import IInput from "../IInput/IInput";
import { IInputNumber } from "../IInputNumber";
import IRefer from "../IRefer/IRefer";
import {
    DatePickerProps,
    InputNumberProps,
    InputProps,
    ReferType,
    TimePickerProps,
    DateTimePickerProps,
    CheckBoxProps,
    FormItemDef,
    ValidationStatus,
    ReferTableProps,
    OptionType,
} from "../Presenters/declare";
//@ts-ignore
import ResizeObserver from "resize-observer-polyfill";
import {
    computed,
    defineComponent,
    h,
    inject,
    onMounted,
    onUnmounted,
    PropType,
    provide,
    ref,
    Ref,
    toRaw,
    watch,
} from "vue";
import { usePlatform } from "@/utils/composables";
import { useInjectFormItemContext } from "@intasect/ant-design-vue/es/form";
import { isEqual } from "lodash-es";
import IReferTable from "../IReferTable/IReferTable";
import IDate from "../IDate/IDate";
import { nextTick } from "process";
import { IFormContext } from "./declare";
import { IFORM_CONTEXT } from "./common";
import IRichText from "../IRichText/IRichText";

const FormItemInput = defineComponent({
    name: "i-form-item-input",
    props: {
        fieldName: { type: String, required: true },
        commonProps: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        inputProps: { type: Object as PropType<InputProps>, required: true },
        formState: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
    },
    setup(props) {
        return () => (
            <IInput
                {...props.commonProps}
                v-model={[props.formState[props.fieldName], "value"]}
                addonBefore={props.inputProps?.prefix}
                addonAfter={props.inputProps?.suffix}
            ></IInput>
        );
    },
});

const FormItemInputNumber = defineComponent({
    name: "i-form-item-inputnumber",
    props: {
        fieldName: { type: String, required: true },
        commonProps: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        inputNumberProps: {
            type: Object as PropType<InputNumberProps>,
            required: true,
        },
        formState: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
    },
    setup(props) {
        return () => (
            <IInputNumber
                {...props.commonProps}
                v-model={[props.formState[props.fieldName], "value"]}
                addonBefore={props.inputNumberProps?.prefix}
                addonAfter={props.inputNumberProps?.suffix}
                type={props.inputNumberProps?.type}
            ></IInputNumber>
        );
    },
});

const FormItemRichText = defineComponent({
    name: "i-form-item-richText",
    props: {
        fieldName: { type: String, required: true },
        commonProps: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        richTextProps: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        formState: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
    },
    setup(props) {
        return () => (
            <IRichText
                {...props.commonProps}
                {...props.richTextProps}
                v-model:value={props.formState[props.fieldName]}
            />
        );
    },
});

const FormItemDatePicker = defineComponent({
    name: "i-form-item-datepicker",
    props: {
        fieldName: { type: String, required: true },
        commonProps: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        datePickerProps: {
            type: Object as PropType<DatePickerProps>,
            required: true,
        },
        formState: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        multi: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        return () => (
            <IDate
                {...props.commonProps}
                range={props.multi}
                picker={props.datePickerProps.picker}
                v-model={[props.formState[props.fieldName], "value"]}
                valueFormat={props.datePickerProps?.format || `YYYY/MM/DD`}
                disabledDate={props.datePickerProps.disabledDay}
            />
        );
    },
});

const FormItemTimePicker = defineComponent({
    name: "i-form-item-timepicker",
    props: {
        fieldName: { type: String, required: true },
        commonProps: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        timePickerProps: {
            type: Object as PropType<TimePickerProps>,
            required: true,
        },
        formState: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        multi: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        return () => (
            <IDate
                {...props.commonProps}
                range={props.multi}
                picker="time"
                v-model={[props.formState[props.fieldName], "value"]}
                valueFormat={props.timePickerProps?.format || `HH:mm:ss`}
                disabledTime={props.timePickerProps.disabledTime}
            />
        );
    },
});

const FormItemDateTimePicker = defineComponent({
    name: "i-form-item-datetimepicker",
    props: {
        fieldName: { type: String, required: true },
        commonProps: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        dateTimePickerProps: {
            type: Object as PropType<DateTimePickerProps>,
            required: true,
        },
        formState: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        multi: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        return () => (
            <IDate
                {...props.commonProps}
                picker="date"
                range={props.multi}
                v-model={[props.formState[props.fieldName], "value"]}
                valueFormat={
                    props.dateTimePickerProps?.format || `YYYY/MM/DD HH:mm:ss`
                }
                disabledDate={props.dateTimePickerProps.disabledDay}
                disabledTime={props.dateTimePickerProps.disabledTime}
                //show-time={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
                showTime={true}
            />
        );
    },
});

const FormTableRefer = defineComponent({
    name: "i-form-table-refer",
    props: {
        fieldName: { type: String, required: true },
        commonProps: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        referTableProps: {
            type: Object as PropType<ReferTableProps>,
            required: true,
        },
        formState: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
    },
    setup(props) {
        const formPresenter = new props.referTableProps.formPresenter();
        const gridPresenter = new props.referTableProps.gridPresenter();
        return () => (
            <IReferTable
                {...props.commonProps}
                v-model={[props.formState[props.fieldName], "value"]}
                fieldNames={props.referTableProps.fieldNames}
                formPresenter={formPresenter}
                gridPresenter={gridPresenter}
            />
        );
    },
});

const FormItemRefer = defineComponent({
    name: "i-form-item-refer",
    props: {
        fieldName: { type: String, required: true },
        referType: { type: Object as PropType<ReferType>, required: true },
        commonProps: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        formState: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
    },
    setup(props) {
        const referRef = ref();
        const platform = usePlatform();
        const params = ref<any[]>([]);
        const dicParams = ref<any[]>([]);
        const ctx = useInjectFormItemContext();
        const formContext = inject<IFormContext>(IFORM_CONTEXT);
        const onOptionsInit = (value: any) => {
            bindReferData(value);
        };
        const onChange = (value?: any) => {
            ctx.onFieldChange();
        };
        const bindReferData = (value?: any) => {
            const list = Array.isArray(value) ? value : [value];
            if (formContext) {
                formContext.referData[props.fieldName] =
                    referRef.value.options.filter((a: OptionType) =>
                        list.includes(a.value)
                    );
                formContext.referOptions[props.fieldName] =
                    referRef.value.options;
            }
        };
        if (props.referType.resourceParamsResolve) {
            watch(
                () => {
                    try {
                        return props.referType.resourceParamsResolve!({
                            formModel: props.formState,
                            context: {},
                            platform,
                        });
                    } catch (e) {
                        console.log(e);
                    }
                },
                newParmas => {
                    if (!isEqual(toRaw(params.value), newParmas)) {
                        params.value = newParmas ?? [];
                        referRef.value?.reset();
                    }
                },
                { immediate: true }
            );
        }
        if (props.referType.dictParamsResolve) {
            watch(
                () => {
                    try {
                        return props.referType.dictParamsResolve!({
                            formModel: props.formState,
                            context: {},
                            platform,
                        });
                    } catch (e) {
                        console.log(e);
                    }
                },
                newParmas => {
                    if (!isEqual(toRaw(dicParams.value), newParmas)) {
                        dicParams.value = newParmas ?? [];
                        referRef.value?.reset();
                    }
                },
                { immediate: true }
            );
        }

        if (
            (props.referType.autoFill ?? []).length > 0 &&
            (props.formState[props.fieldName] === undefined ||
                (props.formState[props.fieldName] ?? []).length === 0)
        ) {
            onMounted(async () => {
                await referRef.value.loadOptions(true);
                const valueList: any[] = [];
                (props.referType.autoFill ?? []).forEach(index => {
                    const realIndex =
                        index > -1
                            ? index
                            : referRef.value.options.length + index + 1;
                    if (referRef.value.options[realIndex] !== undefined) {
                        valueList.push(referRef.value.options[realIndex].value);
                    }
                    if (props.commonProps.multi) {
                        props.formState[props.fieldName] = valueList;
                    } else {
                        props.formState[props.fieldName] = valueList[0];
                    }
                });
            });
        }

        return () => (
            <IRefer
                {...props.commonProps}
                {...props.referType}
                referResolve={
                    props.referType.referResolve
                        ? referItems =>
                              props.referType.referResolve!({
                                  formModel: props.formState,
                                  referItems,
                                  context: {},
                                  platform,
                              })
                        : undefined
                }
                resourceParams={
                    props.referType.resourceParamsResolve
                        ? () => params.value
                        : undefined
                }
                dicParams={
                    props.referType.dictParamsResolve
                        ? () => dicParams.value
                        : undefined
                }
                v-model:value={props.formState[props.fieldName]}
                valueResolve={
                    props.referType.valueResolve
                        ? value =>
                              props.referType.valueResolve!({
                                  formModel: props.formState,
                                  value,
                                  context: {},
                                  platform,
                              })
                        : undefined
                }
                onChange={onChange}
                onInit={bindReferData}
                onOptionsInit={onOptionsInit}
                ref={referRef}
            />
        );
    },
});

const FormItemCheckBox = defineComponent({
    name: "i-form-item-checkbox",
    props: {
        fieldName: { type: String, required: true },
        commonProps: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        checkBoxProps: {
            type: Object as PropType<CheckBoxProps>,
            required: true,
        },
        formState: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        title: {
            type: String,
        },
    },
    setup({ commonProps, formState, fieldName, title, checkBoxProps }) {
        return () => (
            <Checkbox
                {...commonProps}
                {...checkBoxProps}
                v-model={[formState[fieldName], "checked"]}
            >
                {title}
            </Checkbox>
        );
    },
});

export const IFormItem = defineComponent({
    name: "i-form-item",
    props: {
        def: {
            type: Object as PropType<FormItemDef>,
            required: true,
        },
        mode: {
            type: String as PropType<"view" | "default">,
            default: "default",
        },
        status: {
            type: String as PropType<ValidationStatus>,
            default: "default",
        },
        formModel: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
        providers: {
            type: Object as PropType<
                Record<string, (def: FormItemDef, value: any) => any>
            >,
            default: () => {},
        },
    },
    setup(props) {
        if (props.mode === "view") {
            return (def: FormItemDef) => {
                if (def.viewModeDisplay) {
                    return def.viewModeDisplay(
                        props.formModel.value[
                            props.def.meta.fieldName as string
                        ]
                    );
                }
                return () =>
                    props.formModel.value[def.meta.fieldName as string];
            };
        }
        const commonProps = computed<Record<string, any>>(() => {
            return {
                status: props.status,
                disabled: !!props.def.disabled,
                multi: !!props.def.multi,
                placeholder: props.def.placeholder,
                onClick: (e: Event) => {
                    e.stopPropagation();
                },
            };
        });
        const render = computed(() => {
            if (props.def.itemRender) {
                return h(
                    defineComponent({
                        props: ["value"],
                        emits: ["update:value"],
                        setup() {
                            const ctx = useInjectFormItemContext();
                            for (const key in props.providers) {
                                provide(
                                    key,
                                    props.providers[key](
                                        props.def,
                                        props.formModel[
                                            props.def.meta.fieldName as string
                                        ]
                                    )
                                );
                            }
                            return () =>
                                //@ts-ignore
                                h(props.def.itemRender, {
                                    fieldName: props.def.meta.fieldName,
                                    value: props.formModel[
                                        props.def.meta.fieldName as string
                                    ],
                                    ...commonProps.value,
                                    ...props.def.props,
                                    "onUpdate:value": (value: any) => {
                                        props.formModel[
                                            props.def.meta.fieldName as string
                                        ] = value;
                                        ctx.onFieldChange();
                                    },
                                    setValue: (value: any) => {
                                        props.formModel[
                                            props.def.meta.fieldName as string
                                        ] = value;
                                        ctx.onFieldChange();
                                    },
                                    formContext: {
                                        formModel: props.formModel,
                                    },
                                });
                        },
                    })
                );
            }
            switch (props.def.controlType) {
                case "input":
                    return (
                        <FormItemInput
                            fieldName={props.def.meta.fieldName as string}
                            commonProps={commonProps.value}
                            inputProps={props.def.props as InputProps}
                            formState={props.formModel}
                        />
                    );
                case "inputNumber":
                    return (
                        <FormItemInputNumber
                            fieldName={props.def.meta.fieldName as string}
                            commonProps={commonProps.value}
                            inputNumberProps={
                                props.def.props as InputNumberProps
                            }
                            formState={props.formModel}
                        />
                    );
                case "datePicker":
                    return (
                        <FormItemDatePicker
                            fieldName={props.def.meta.fieldName as string}
                            commonProps={commonProps.value}
                            datePickerProps={props.def.props as DatePickerProps}
                            formState={props.formModel}
                            multi={props.def.multi}
                        />
                    );
                case "timePicker":
                    return (
                        <FormItemTimePicker
                            fieldName={props.def.meta.fieldName as string}
                            commonProps={commonProps.value}
                            timePickerProps={props.def.props as TimePickerProps}
                            formState={props.formModel}
                            multi={props.def.multi}
                        />
                    );
                case "dateTimePicker":
                    return (
                        <FormItemDateTimePicker
                            fieldName={props.def.meta.fieldName as string}
                            commonProps={commonProps.value}
                            dateTimePickerProps={
                                props.def.props as DateTimePickerProps
                            }
                            formState={props.formModel}
                            multi={props.def.multi}
                        />
                    );
                case "refer":
                    return (
                        <FormItemRefer
                            fieldName={props.def.meta.fieldName as string}
                            formState={props.formModel}
                            referType={props.def.refer!}
                            commonProps={{
                                ...commonProps.value,
                                ...props.def.props,
                            }}
                        />
                    );
                case "checkBox":
                    return (
                        <FormItemCheckBox
                            fieldName={props.def.meta.fieldName as string}
                            commonProps={commonProps.value}
                            checkBoxProps={props.def.props as CheckBoxProps}
                            formState={props.formModel}
                            title={props.def.meta.title}
                        />
                    );
                case "referTable":
                    return (
                        <FormTableRefer
                            fieldName={props.def.meta.fieldName as string}
                            commonProps={commonProps.value}
                            referTableProps={props.def.props as ReferTableProps}
                            formState={props.formModel}
                        />
                    );
                case "richText":
                    return (
                        <FormItemRichText
                            fieldName={props.def.meta.fieldName as string}
                            commonProps={commonProps.value}
                            richTextProps={props.def.props}
                            formState={props.formModel}
                        />
                    );
                case "none":
                    return null;
            }
        });
        return () => render.value;
    },
});

const labelCol = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 8 },
    xl: { span: 8 },
    xxl: { span: 8 },
    xxxl: { span: 8 },
};

const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 16 },
    xl: { span: 16 },
    xxl: { span: 16 },
    xxxl: { span: 16 },
};

export const useFormResizeOb = (
    containerRef: Ref<HTMLElement | undefined>,
    gutter: number = 24,
    maxCol: number = 4,
    minCol: number = 1
) => {
    const rob = ref<ResizeObserver | undefined>();
    const colspan = ref(gutter);
    const getColspan = (gutter: number, col: number) => {
        let c = col;
        while (gutter % c !== 0) {
            c--;
        }
        return gutter / c;
    };
    const resizeCallback = (entries: any) => {
        for (const entry of entries) {
            const { width } = entry.contentRect;
            if (width === 0) {
                //修复切换导致Form闪烁的问题
                return;
            }
            if (width >= 992) {
                colspan.value = getColspan(gutter, maxCol);
            } else if (width >= 768) {
                colspan.value = getColspan(
                    gutter,
                    maxCol > Math.max(3, minCol) ? Math.max(3, minCol) : maxCol
                );
            } else if (width >= 576) {
                colspan.value = getColspan(
                    gutter,
                    maxCol > Math.max(2, minCol) ? Math.max(2, minCol) : maxCol
                );
            } else if (minCol === 1) {
                colspan.value = gutter;
            } else {
                colspan.value = gutter / minCol;
            }
        }
    };
    const dis = watch(
        () => containerRef.value,
        v => {
            if (containerRef.value) {
                rob.value = new ResizeObserver(
                    (entries: any, observer: any) => {
                        if (window && window.requestAnimationFrame) {
                            window.requestAnimationFrame(() => {
                                if (
                                    !Array.isArray(entries) ||
                                    !entries.length
                                ) {
                                    return;
                                }
                                resizeCallback(entries);
                            });
                        } else {
                            resizeCallback(entries);
                        }
                    }
                );
                rob.value?.observe(containerRef.value);
                nextTick(() => {
                    dis();
                });
            }
        },
        { immediate: true }
    );

    onUnmounted(() => {
        if (rob.value && containerRef.value) {
            rob.value.unobserve(containerRef.value);
            rob.value = undefined;
        }
    });

    return {
        formSetting: {
            labelCol,
            wrapperCol,
        },
        colSetting: {
            span: colspan,
        },
    };
};

export const useFormItemCol = (
    props: { colspan: number },
    item: { colspan?: number; colspace?: number }
) => {
    return computed<number>(() => {
        if (item.colspan && item.colspan > 0 && item.colspan < 25) {
            return item.colspan;
        }
        if (item.colspace !== undefined) {
            if (
                item.colspace > 0 &&
                item.colspace * (item.colspan ?? props.colspan) <= 24
            ) {
                return Math.floor(
                    item.colspace * (item.colspan ?? props.colspan)
                );
            }
            return 24;
        }
        return props.colspan;
    });
};
