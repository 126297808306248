<template>
    <IModal
        :title="title"
        :visible="visible"
        :hideFullScreen="true"
        :isfullScreen="true"
        @cancel="close"
    >
        <div class="pdf-preview">
            <div class="pdf-wrap">
                <vue-pdf-embed
                    ref="vuePdf"
                    :source="state.source"
                    :style="scaleFun"
                    class="@intasect/vue-pdf-embed"
                    :page="state.pageNum"
                />
            </div>
            <div class="page-tool">
                <div class="page-tool-item" @click="lastPage">上一页</div>
                <div class="page-tool-item" @click="nextPage">下一页</div>
                <div class="page-tool-item">
                    {{ state.pageNum }}/{{ state.numPages }}
                </div>
                <div class="page-tool-item" @click="pageZoomOut">放大</div>
                <div class="page-tool-item" @click="pageZoomIn">缩小</div>
                <div
                    class="page-tool-item"
                    v-if="downVisible && downUrl"
                    @click="downFile"
                >
                    下载
                </div>
                <div class="page-tool-item" v-if="printVisible" @click="print">
                    打印
                </div>
            </div>
        </div>
        <template #footer></template>
    </IModal>
    <iframe width="0" height="0" frameborder="0" id="oprs-iframe"></iframe>
</template>

<script lang="ts">
import { reactive, onMounted, computed, defineComponent, ref } from "vue";
import VuePdfEmbed from "@intasect/vue-pdf-embed";
//@ts-ignore
import { createLoadingTask } from "vue3-pdfjs/esm";
import IModal from "@/components/i-components/IModal/IModal.vue";
import { usePlatform } from "@/utils/composables";

export default defineComponent({
    name: "PdfPreview",
    emits: ["close"],
    props: {
        //pdfurl需要文件地址(非接口地址) 或者文件流 或者整个文件
        pdfUrl: {
            type: String,
            required: true,
        },
        //downUrl 下载pdf的接口地址 暂定get请求下载
        downUrl: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
        },
        //标题
        title: {
            type: String,
            default: "PDF预览",
        },
        //是否展示下载  配合下载接口地址使用
        downVisible: {
            type: Boolean,
            default: false,
        },
        //是否展示打印
        printVisible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        VuePdfEmbed,
        IModal,
    },
    setup(props, context) {
        const state = reactive({
            source: props.pdfUrl,
            pageNum: 1,
            scale: 1, // 缩放比例
            numPages: 0, // 总页数
        });
        const scaleFun = computed(() => `transform:scale(${state.scale})`);
        onMounted(() => {
            const loadingTask = createLoadingTask(state.source);
            loadingTask.promise.then((pdf: { numPages: number }) => {
                state.numPages = pdf.numPages;
            });
        });
        const lastPage = () => {
            if (state.pageNum > 1) {
                state.pageNum -= 1;
            }
        };
        const nextPage = () => {
            if (state.pageNum < state.numPages) {
                state.pageNum += 1;
            }
        };
        const pageZoomOut = () => {
            if (state.scale < 2) {
                state.scale += 0.1;
            }
        };
        const pageZoomIn = () => {
            if (state.scale > 1) {
                state.scale -= 0.1;
            }
        };
        const close = () => {
            context.emit("close");
        };
        const platForm = usePlatform();
        const downFile = () => {
            platForm.download({
                url: props.downUrl,
                method: "get",
            });
        };
        const vuePdf = ref();
        const print = () => {
            vuePdf.value.print(300, props.title, true);
        };
        return {
            state,
            lastPage,
            nextPage,
            pageZoomOut,
            pageZoomIn,
            scaleFun,
            close,
            downFile,
            print,
            vuePdf,
        };
    },
});
</script>
<style lang="css" scoped>
.pdf-preview {
    position: relative;
    height: 85vh;
    padding: 20px 0;
    box-sizing: border-box;
    background-color: e9e9e9;
}
.pdf-wrap {
    position: relative;
    top: 0;
    overflow-y: auto;
}
.vue-pdf-embed {
    text-align: center;
    width: 515px;
    border: 1px solid #e5e5e5;
    margin: 0 auto;
    box-sizing: border-box;
    transform-origin: 50% 0%;
}

.page-tool {
    position: absolute;
    bottom: 35px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    background: rgb(66, 66, 66);
    color: white;
    border-radius: 19px;
    z-index: 100;
    cursor: pointer;
    margin-left: 50%;
    transform: translateX(-50%);
}
.page-tool-item {
    padding: 8px 15px;
    padding-left: 10px;
    cursor: pointer;
}
</style>
