import { MetadataMap } from "@/entities";
import { Col } from "@intasect/ant-design-vue";
import {
    defineComponent,
    PropType,
    inject,
    computed,
    ref,
    toRaw,
    defineAsyncComponent,
} from "vue";
import { IGrid } from "../IGrid/IGrid";
import { FormTableItemDef, FormSolutionItemDef, FormItemDef } from "../Presenters/declare";
import { IFormContext } from "./declare";
import { IFORM_CONTEXT } from "./common";
import {
    GridOperationAction,
    OperationActionKey,
} from "../ActionCell/ActionCell";
import { devConsole, uuid } from "@intasect/platform-core";

import { cloneDeep } from "lodash-es";
import { generateFormFieldDef, generateGridItemDef } from "../Presenters/utils";

export const FORM_TABLE_DEFAULT_ACTION_ADD = Symbol.for("%#FTDA_ADD&*");
export const FORM_TABLE_DEFAULT_ACTION_MODIFY = Symbol.for("%#FTDA_MODIFY&*");
export const FORM_TABLE_DEFAULT_ACTION_DELETE = Symbol.for("%#FTDA_DELETE&*");
export const FORM_TABLE_DEFAULT_ACTION_BATCHDELETE = Symbol.for(
    "%#FTDA_BATCHDELETE&*"
);

const defaultAdd: GridOperationAction = {
    position: "actionBarRight",
    key: FORM_TABLE_DEFAULT_ACTION_ADD,
    title: "新增",
    buttonStyle: "primary",
    actionType: "add",
};

const defaultModify: GridOperationAction = {
    position: "inline",
    key: FORM_TABLE_DEFAULT_ACTION_MODIFY,
    title: "编辑",
    actionType: "modify",
};

const defaultDelete: GridOperationAction = {
    position: "inline",
    key: FORM_TABLE_DEFAULT_ACTION_DELETE,
    title: "删除",
    actionType: "modify",
    popConfirm: {
        title: "确认删除吗？",
        showCancel: true,
    },
};

const defaultBatchDelete: GridOperationAction = {
    position: "actionBarLeft",
    key: FORM_TABLE_DEFAULT_ACTION_BATCHDELETE,
    title: "批量删除",
    actionType: "delete",
    buttonStyle: "danger",
    withRowSelect: true,
    popConfirm: {
        title: "确认删除选中行吗？",
        showCancel: true,
    },
};

const defaultModalActionList = [defaultAdd, defaultModify, defaultDelete];
const defaultModalWithSelectActionList = [
    defaultAdd,
    defaultModify,
    defaultDelete,
    defaultBatchDelete,
];

const defaultInlineActionList = [defaultAdd, defaultDelete];
const defaultInlineWithSelectActionList = [
    defaultAdd,
    defaultDelete,
    defaultBatchDelete,
];

const ModalForm = defineAsyncComponent(
    () => import("@/components/ModalForm.vue")
);

export const TableFormItem = defineComponent({
    name: "i-form-table",
    components: {
        ModalForm,
    },
    props: {
        item: {
            type: Object as PropType<FormTableItemDef>,
            required: true,
        },
        //模式
        mode: {
            type: String as PropType<"default" | "view">,
            default: "default",
        },
        providers: {
            type: Object as PropType<
                Record<string, (def: FormSolutionItemDef, value: any) => any>
            >,
            default: () => ({}),
        },
        //表单的数据模型
        formModel: {
            type: Object as PropType<{ [key: string]: any }>,
            required: true,
        },
        span: Number,
    },
    setup(props, context) {
        if (
            props.formModel[props.item.meta.fieldName as string] === undefined
        ) {
            props.formModel[props.item.meta.fieldName as string] = [];
        }
        const iFormContext = inject<IFormContext>(IFORM_CONTEXT);
        const isDispatchDefaultActions = computed(() => {
            return props.item.autoProcess !== false;
        });

        const primaryKey = computed(() => props.item.primaryKey || "id");

        const isNewRecord = (record: any) => {
            if (record[primaryKey.value]) {
                return false;
            } else {
                if (record["##_rowId"]) {
                    return false;
                } else {
                    record["##_rowId"] = uuid(8, 32);
                    return true;
                }
            }
        };

        const recordList = computed(
            () =>
                props.formModel[
                props.item.meta.fieldName as string
                ] as Array<any>
        );

        const currentTableRecord = ref<Record<string, any>>({});

        const deleteRecord = (record: any) => {
            let index = -1;
            if (record[primaryKey.value]) {
                index = recordList.value.findIndex(
                    a => a[primaryKey.value] === record[primaryKey.value]
                );
            } else {
                index = recordList.value.findIndex(
                    a => a["##_rowId"] === record["##_rowId"]
                );
            }
            if (index > -1) {
                recordList.value.splice(index, 1);
            } else {
                devConsole(`can't find record :`, record);
            }
        };

        const autoProcessDefaultActions = (
            actionKey: OperationActionKey,
            currentRecord: any | undefined = undefined,
            editableRecordContext:
                | {
                    validate: () => Promise<boolean>;
                }
                | undefined = undefined
        ) => {
            switch (actionKey) {
                case FORM_TABLE_DEFAULT_ACTION_ADD:
                    if (props.item.editMode === "modal") {
                        currentTableRecord.value = {};
                        showModal();
                    } else {
                        recordList.value.push({});
                    }
                    break;
                case FORM_TABLE_DEFAULT_ACTION_MODIFY:
                    currentTableRecord.value = cloneDeep(toRaw(currentRecord));
                    showModal();
                    break;
                case FORM_TABLE_DEFAULT_ACTION_DELETE:
                    deleteRecord(toRaw(currentRecord));
                    break;
                case FORM_TABLE_DEFAULT_ACTION_BATCHDELETE:
                    selectedRows.value.forEach(record =>
                        deleteRecord(toRaw(record))
                    );
                    break;
            }
        };

        const onSubmit = () => {
            if (isDispatchDefaultActions.value) {
                if (isNewRecord(currentTableRecord.value)) {
                    props.formModel[props.item.meta.fieldName as string].push(
                        currentTableRecord.value
                    );
                } else {
                    const index = recordList.value.findIndex(a => {
                        if (currentTableRecord.value[primaryKey.value]) {
                            return (
                                a[primaryKey.value] ===
                                currentTableRecord.value[primaryKey.value]
                            );
                        }
                        return (
                            a["##_rowId"] ===
                            currentTableRecord.value["##_rowId"]
                        );
                    });
                    if (index > -1) {
                        recordList.value.splice(
                            index,
                            1,
                            currentTableRecord.value
                        );
                    }
                }
            }
        };

        const onActionClick = (
            actionKey: string,
            currentRecord: any | undefined = undefined,
            editableRecordContext:
                | {
                    validate: () => Promise<boolean>;
                }
                | undefined = undefined
        ) => {
            if (iFormContext) {
                iFormContext.onTableActionClick(
                    props.item.meta.fieldName as string,
                    actionKey,
                    currentRecord,
                    selectedRows.value,
                    editableRecordContext
                );
            }
            if (isDispatchDefaultActions.value) {
                autoProcessDefaultActions(
                    actionKey,
                    currentRecord,
                    editableRecordContext
                );
            }
        };

        const selectedRowsKeys = ref<string[]>([]);

        const selectedRows = computed({
            get() {
                if (iFormContext) {
                    if (
                        iFormContext.selectedRecordMaps[
                        props.item.meta.fieldName as string
                        ] === undefined
                    ) {
                        iFormContext.selectedRecordMaps[
                            props.item.meta.fieldName as string
                        ] = ref([]);
                    }
                    return iFormContext.selectedRecordMaps[
                        props.item.meta.fieldName as string
                    ].value;
                }
                return [];
            },
            set(val) {
                if (iFormContext) {
                    if (
                        iFormContext.selectedRecordMaps[
                        props.item.meta.fieldName as string
                        ] === undefined
                    ) {
                        iFormContext.selectedRecordMaps[
                            props.item.meta.fieldName as string
                        ] = ref([]);
                    }
                    iFormContext.selectedRecordMaps[
                        props.item.meta.fieldName as string
                    ]!.value = val;
                }
            },
        });

        const actionValid = (record: any) => {
            if (iFormContext) {
                return iFormContext.tableActionValid(
                    props.item.meta.fieldName as string,
                    record,
                    props.formModel
                );
            }
            return true;
        };

        const actionVisible = (record: any) => {
            if (iFormContext) {
                return iFormContext.tableActionVisible(
                    props.item.meta.fieldName as string,
                    record,
                    props.formModel
                );
            }
            return true;
        };

        const actionDisable = (record: any) => {
            if (iFormContext) {
                return iFormContext.tableActionDisable(
                    props.item.meta.fieldName as string,
                    record,
                    props.formModel
                );
            }
            return false;
        };

        const gridActions = computed(() => {
            switch (typeof props.item.actions) {
                case "function":
                    return props.item.actions({
                        add: defaultAdd,
                        modify: defaultModify,
                        delete: defaultDelete,
                        batchDelete: defaultBatchDelete,
                    });
                case "object":
                    return props.item.actions;
                default:
                    if (props.item.editable) {
                        if (props.item.editMode === "modal") {
                            return props.item.selectable
                                ? defaultModalWithSelectActionList
                                : defaultModalActionList;
                        }
                        return props.item.selectable
                            ? defaultInlineWithSelectActionList
                            : defaultInlineActionList;
                    }
                    break;
            }
        });

        const editableFormItemDefs = computed<FormSolutionItemDef[]>(() => {
            if (props.item.editable) {
                return generateFormFieldDef(
                    props.item.meta.reference as any,
                    props.item.formDef || {}
                );
            }
            return [];
        });

        const modalVisible = ref(false);
        const showModal = () => {
            modalVisible.value = true;
        };

        const entityName = props.item.meta.reference! as keyof typeof MetadataMap;
        const formItemDefs = computed(() => {
            if (props.item.editMode === "inline" && props.item.formDef) {
                return generateFormFieldDef(entityName, props.item.formDef)
            }
        });
        const gridItemDefs = computed(() => generateGridItemDef(entityName, props.item.def))

        return () =>
            props.item.display !== false && (
                <Col
                    span={props.span}
                    offset={props.item.offset}
                    class="i-form-table"
                >
                    {props.item.hideTitle !== true && (
                        <div class="i-form-table-title">
                            {{ default: () => props.item.title }}
                        </div>
                    )}
                    <IGrid
                        actions={gridActions.value}
                        gridItemDefs={gridItemDefs.value.list}
                        formItemDefs={formItemDefs.value as FormItemDef[]}
                        actionColumn={true}
                        onActionClick={onActionClick}
                        multiSelect={props.item.multiSelect}
                        enableRowSelect={props.item.selectable}
                        v-model:dataSource={
                            props.formModel[props.item.meta.fieldName as string]
                        }
                        v-model:selectedRows={selectedRows.value}
                        v-model:selectedRowKeys={selectedRowsKeys.value}
                        actionValid={actionValid}
                        actionVisible={actionVisible}
                        actionDisable={actionDisable}
                    />
                    {props.item.editMode === "modal" && (
                        <ModalForm
                            title={props.item.title}
                            itemDefs={editableFormItemDefs.value}
                            model={currentTableRecord.value}
                            onSubmit={onSubmit}
                            v-model:visible={modalVisible.value}
                        ></ModalForm>
                    )}
                </Col>
            );
    },
});
