/* eslint-disable */
import Button from "@/components/i-components/IButton/IButton";
import {
    defineComponent,
    ref,
    PropType,
    watch,
    toRef,
    computed,
    h,
    defineAsyncComponent,
} from "vue";
import "./style.less";
import { DownOutlined, UpOutlined } from "@ant-design/icons-vue";
import type { FormInstance } from "@intasect/ant-design-vue";
import { toRaw } from "@vue/reactivity";
import { FormPresenter } from "../Presenters/FormPresenter";
import IForm from "../IForm/IForm";
import {
    NamePath,
    ValidateErrorEntity,
} from "@intasect/ant-design-vue/es/form/interface";
import {
    FormSolutionItemDef,
    QueryFormSolutionItemDef,
    ValidationError,
} from "../Presenters/declare";
import "@intasect/ant-design-vue/es/tooltip/style";

const Tooltip = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/tooltip")
);

const labelSolt = (props: { title: string; colspan: number }) => {
    if (props.colspan === 24) {
        if (props.title.length > 6) {
            return h(Tooltip, { placement: "top", title: props.title }, () =>
                h(
                    "p",
                    {
                        class: "iquery-form-label",
                    },
                    props.title
                )
            );
        }
        return props.title;
    }
    if (props.title.length > 12) {
        return h(Tooltip, { placement: "top", title: props.title }, () =>
            h(
                "p",
                {
                    class: "iquery-form-label-12",
                },
                props.title
            )
        );
    } else if (props.title.length > 6) {
        return h(
            "p",
            {
                class: "iquery-form-label-6",
            },
            props.title
        );
    }
    return props.title;
};

export default defineComponent({
    name: "i-query-form",
    emits: ["query", "reset", "change", "validateFail"],
    components: {
        Button,
        DownOutlined,
        UpOutlined,
    },
    props: {
        //是否展开
        isExpand: {
            type: Boolean,
            default: false,
        },
        //formPresenter
        presenter: {
            type: Object as PropType<FormPresenter<any, any>>,
            required: true,
        },
        //是否显示边框样式
        withContainer: {
            type: Boolean,
            default: true,
        },
        queryText: {
            type: String,
            default: "搜索",
        },
        resetText: {
            type: String,
            default: "重置",
        },
        providers: {
            type: Object as PropType<
                Record<string, (def: FormSolutionItemDef, value: any) => any>
            >,
            default: () => ({}),
        },
    },
    setup(props, context) {
        const formState = toRef(props.presenter, "dataModel");
        const isExpand = ref(props.isExpand);
        const formRef = ref<FormInstance>();

        const isExpandChange = () => {
            isExpand.value = !isExpand.value;
        };

        const setExpand = (val: boolean) => {
            isExpand.value = val;
        };

        const hiddenItems = computed(() => {
            return props.presenter.formFieldDef.value.filter(
                a => a.display === false
            );
        });

        const showItems = computed(() => {
            return props.presenter.formFieldDef.value.filter(
                a => a.display !== false
            );
        });
        const queryItems = computed<QueryFormSolutionItemDef[]>(() => {
            if (isExpand.value) {
                return props.presenter.formFieldDef.value;
            } else {
                return [
                    ...showItems.value.slice(0, 3),
                    ...showItems.value.slice(3).map(a => ({
                        ...a,
                        expandDisplay: false,
                        display: false,
                    })),
                    ...hiddenItems.value,
                ];
            }
        });

        const isExpandcoleseOropen = () => {
            const formlist = props.presenter.formFieldDef.value;
            if (
                formlist &&
                formlist.filter(a => a.display !== false).length > 3
            ) {
                return (
                    <a
                        href="javascript:;"
                        style="font-size: 12px;margin-left:8px"
                        onClick={isExpandChange}
                    >
                        <span style="margin-right:4px">
                            {isExpand.value ? "收起" : "展开"}
                        </span>
                        {isExpand.value ? <UpOutlined /> : <DownOutlined />}
                    </a>
                );
            } else {
                return null;
            }
        };
        //搜索
        const query = async (e: Event) => {
            try {
                await formRef.value?.validate();
                context.emit("query", props.presenter.dataModel);
            } catch (e: any) {
                validateFail(e);
            }
        };
        //清除
        const resetFields = (e: Event) => {
            for (const i in formState.value) {
                formState.value[i] = undefined;
            }
            formRef.value?.resetFields();
            context.emit("reset", formState, e);
        };
        //校验失败
        const validateFail = (params: ValidateErrorEntity<any>) => {
            const errorResult: ValidationError[] = [];
            params.errorFields.forEach(field => {
                const def = props.presenter.getField(field.name[0] as string);
                if (def) {
                    errorResult.push({ def, errors: field.errors });
                }
            });
            context.emit("validateFail", errorResult);
        };
        watch(
            () => formState.value,
            newFromState => {
                const newvalues = toRaw(newFromState);
                context.emit("change", newvalues);
            },
            {
                deep: true,
            }
        );
        context.expose({
            clearValidate: (nameList?: NamePath) => {
                formRef.value?.clearValidate(nameList);
            },
            resetFields: (nameList?: NamePath) => {
                formRef.value?.resetFields(nameList);
            },
            scrollToField: (name: NamePath, options: [ScrollOptions]) => {
                formRef.value?.scrollToField(name, options);
            },
            validate: async (nameList?: NamePath[]) => {
                return formRef.value?.validate(nameList);
            },
            validateFields: async (nameList?: NamePath[]) => {
                return formRef.value?.validateFields(nameList);
            },
            submit: (e: Event) => {
                return formRef.value?.submit(e);
            },
            setExpand,
            queryItems,
        });
        return () => (
            <IForm
                ref={formRef}
                formModel={props.presenter.dataModel}
                items={queryItems.value}
                layout="horizontal"
                class="i-query-form"
                labelCol={{ style: { width: "100px" } }}
                withContainer={props.withContainer}
                providers={props.providers}
            >
                {{
                    formFooter: () => {
                        return (
                            <div class="footer">
                                <Button
                                    style="margin-right:8px"
                                    onClick={resetFields}
                                >
                                    {props.resetText}
                                </Button>
                                <Button type="primary" onClick={query}>
                                    {props.queryText}
                                </Button>
                                {isExpandcoleseOropen()}
                            </div>
                        );
                    },
                    formLabel: labelSolt,
                }}
            </IForm>
        );
    },
});
