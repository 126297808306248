import {
    RowCreatedHandler,
    FormItemDef,
    GridItemDef,
    GridSolutionItemDef,
    SortType,
    ViewObj,
} from "../Presenters/declare";
import { PlatformContext } from "@intasect/platform-core";
import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    ref,
    VNodeTypes,
} from "vue";
import ITable, {
    RowspanKeyResolveType,
    selectionColumnKey,
} from "../ITable/ITable";
import type { ColumnType } from "@intasect/ant-design-vue/es/table";
import type {
    ColumnsType,
    ColumnTitle,
    FilterValue,
    Key,
    SorterResult,
    TableCurrentDataSource,
} from "@intasect/ant-design-vue/es/table/interface";
import { TablePaginationConfig, Tabs } from "@intasect/ant-design-vue";
import { IGrid } from "../IGrid/IGrid";
import { GridPresenter } from "../Presenters/GridPresenter";

const Pane = Tabs.TabPane;

export const IEntityGrid = defineComponent({
    name: "IEntityGrid",
    components: {
        ITable,
    },
    emits: [
        "rowSelectChange",
        "rowSelect",
        "rowClick",
        "rowSelectAll",
        "rowActionClick",
        "changeTab",
    ],
    props: {
        //GridPresenter实例
        presenter: {
            type: Object as PropType<GridPresenter<any, any>>,
            required: true,
        },
        //是否隐藏序号列
        hideOrderColumn: {
            type: Boolean,
            default: false,
        },
        //是否隐藏操作列
        hideActionColumn: {
            type: Boolean,
            default: false,
        },
        //是否启用行点击可以选中/取消选中行
        enableRowSelect: {
            type: Boolean,
            default: true,
        },
        //是否在组件加载后自动执行查询操作
        mountedQuery: {
            type: Boolean,
            default: true,
        },
        //自动查询的查询参数
        mountedQueryParams: {
            type: Object,
            default: () => {},
        },
        //操作列的宽度设置
        actionCellWidth: {
            type: Number,
            required: false,
        },
        //是否显示边框样式
        withContainer: {
            type: Boolean,
            default: true,
        },
        //tabs标签显示配置
        tabs: {
            type: Array as PropType<ViewObj[]>,
            default: () => [],
        },
        //默认列宽
        colDefaultWidth: { type: Number, default: 200 },
    },
    setup(props, context) {
        const gridRef = ref();
        const changeTab = (e: Key) => {
            if (props.presenter.viewList.value.length > 0) {
                props.presenter.setView(e as string);
            }
            context.emit("changeTab", e);
        };
        const tabList = computed(() => {
            if (!props.presenter.useTabView.value) {
                return [];
            }
            if (props.tabs.length > 0) {
                const tabs = Array.from(props.tabs);
                tabs.sort((a, b) => {
                    if (a.order && b.order) {
                        return a.order - b.order;
                    }
                    return 0;
                });
                return tabs;
            } else if (props.presenter.viewList.value.length > 0) {
                return props.presenter.viewList.value;
            }
            return [];
        });

        const renderTabs = () => {
            if (tabList.value.length === 0) {
                return null;
            }
            return (
                <Tabs onChange={changeTab}>
                    {tabList.value.map(item => (
                        <Pane key={item.value}>
                            {{
                                tab: () => {
                                    const defaultRender = item.value;
                                    if (context.slots["tab"]) {
                                        return context.slots["tab"]?.(
                                            item.value
                                        );
                                    }
                                    return defaultRender;
                                },
                            }}
                        </Pane>
                    ))}
                </Tabs>
            );
        };

        if (tabList.value.length > 0) {
            props.presenter.setView(tabList.value[0].value);
            changeTab(tabList.value[0].value);
        }

        const onPageChange = (pageIndex: number) => {
            props.presenter.setPageIndex(pageIndex);
        };

        const onShowSizeChange = (pageSize: number) => {
            props.presenter.setPageSize(pageSize);
        };

        const onRowChange = (selectedRowKeys: Key[], selectedRows: any[]) => {
            if (props.presenter.customHandleRowSelectKeys) {
                props.presenter.customHandleRowSelectKeys(
                    selectedRowKeys as string[],
                    selectedRows
                );
            }
            context.emit("rowSelectChange", selectedRowKeys, selectedRows);
        };

        const onRowSelect = (
            record: any,
            selected: boolean,
            selectedRows: any[]
        ) => {
            context.emit("rowSelect", record, selected, selectedRows);
        };
        const onRowSelectAll = (
            selected: boolean,
            selectedRows: any[],
            changeRows: any[]
        ) => {
            context.emit("rowSelectAll", selected, selectedRows, changeRows);
        };
        const getCheckboxProps = (record: any) => {
            return {
                disabled: props.presenter.rowSelectDisabled(record),
            };
        };

        const headerCellMap = computed<{
            [title: string]: (params: {
                def: GridSolutionItemDef;
                context: PlatformContext;
            }) => VNodeTypes;
        }>(() => {
            const result: Record<
                string,
                (params: {
                    def: GridItemDef;
                    context: PlatformContext;
                }) => VNodeTypes
            > = {};
            Object.entries(props.presenter.gridDef ?? {}).forEach(
                ([key, value]) => {
                    if (value?.titleRender) {
                        result[key] = value.titleRender;
                    }
                }
            );
            return result;
        });
        const platformContext: PlatformContext = {};
        const headerCell = (params: {
            title: ColumnTitle<any>;
            column: ColumnType;
        }) => {
            if (headerCellMap.value[params.column.key as string]) {
                return headerCellMap.value[params.column.key as string]({
                    def: props.presenter.gridItemDef.find(
                        a => a.meta.fieldName === params.column.key
                    )!,
                    context: platformContext,
                });
            }
            return null;
        };

        const columns = ref<ColumnsType<any>>([]);

        if (props.mountedQuery) {
            onMounted(() => {
                props.presenter.setQueryParams(props.mountedQueryParams);
                props.presenter.refresh();
            });
        }

        const tableOnChange = (
            pagination: TablePaginationConfig,
            filters: Record<string, FilterValue | null>,
            sorter: SorterResult | SorterResult[],
            extra: TableCurrentDataSource
        ) => {
            switch (extra.action) {
                case "paginate":
                    resetRowSelections();
                    break;
                case "sort":
                    updateSorter(
                        Array.isArray(sorter)
                            ? sorter[sorter.length - 1]
                            : sorter
                    );
                    break;
            }
        };

        const resetRowSelections = () => {
            if (props.presenter.clearSelectKeysWhenRefresh) {
                props.presenter.SelectedRowKeys = [];
                props.presenter.updateSelectedRows([]);
            }
        };

        const updateSorter = (sorter: SorterResult) => {
            const column = columns.value.find(a => a.key === sorter.columnKey);
            if (column) {
                column.sortOrder = sorter.order;
            }
            let sortType: SortType | undefined = undefined;
            switch (sorter.order) {
                case "ascend":
                    sortType = "asc";
                    break;
                case "descend":
                    sortType = "desc";
                    break;
                default:
                    sortType = undefined;
                    break;
            }
            props.presenter.setSorter(sorter.columnKey as string, sortType);
            props.presenter.refresh();
        };
        const rowSpanItems = computed(() => {
            const list: Array<string | symbol> = props.presenter.gridItemDef
                .filter(a => a.rowSpan)
                .map(a => a.meta.fieldName as string);
            if (props.presenter.selectColumnRowSpan.value) {
                list.push(selectionColumnKey);
            }
            return list;
        });
        const rowSpanKeyResolveMap = computed(() => {
            const map: Record<string | symbol, (record: any) => string> = {};
            props.presenter.gridItemDef
                .filter(a => a.rowSpan && a.rowSpanResolve)
                .forEach(item => {
                    map[item.meta.fieldName as string] = item.rowSpanResolve!;
                });
            if (props.presenter.selectColumnRowSpan.value) {
                map[selectionColumnKey] =
                    props.presenter.selectColumnRowSpanResolve;
            }
            return map;
        });

        const rowSpanKeyResolve: RowspanKeyResolveType = (
            fieldName: string | symbol
        ) => {
            //@ts-ignore
            if (rowSpanKeyResolveMap.value[fieldName]) {
                //@ts-ignore
                return rowSpanKeyResolveMap.value[fieldName];
            }
        };
        const onClickRow = (record: any) => {
            context.emit("rowClick", record);
        };

        const onEditableRowCreated: RowCreatedHandler = (
            record: any,
            rowIndex: number,
            defs: FormItemDef[],
            context
        ) => {
            props.presenter.dispatchEditableRowCreated(
                record,
                rowIndex,
                defs,
                context
            );
        };

        context.expose({
            validate: () => {
                if (gridRef.value) {
                    return gridRef.value.validate();
                }
                return true;
            },
            get editableRowFormContextMap() {
                if (gridRef.value) {
                    return gridRef.value.editableRowFormContextMap;
                }
                return {};
            },
        });

        return () => (
            <IGrid
                v-model:dataSource={props.presenter.dataSource.value}
                v-model:selectedRowKeys={
                    props.presenter.RawSelectedRowKeys.value
                }
                v-model:selectedRows={props.presenter.selectedRows.value}
                gridItemDefs={props.presenter.gridItemDef}
                formItemDefs={props.presenter.formItemDef}
                actions={props.presenter.actions.value}
                isPaging={props.presenter.isPaging}
                pageNum={props.presenter.entity.pageCondition.pageNum}
                pageSize={props.presenter.entity.pageCondition.pageSize}
                pageTotal={props.presenter.entity.rowTotal}
                defaultColWidth={props.colDefaultWidth}
                actionColWidth={props.actionCellWidth}
                multiSelect={props.presenter._rowSelectMulti.value}
                getCheckboxProps={getCheckboxProps}
                enableRowSelect={props.enableRowSelect}
                showColumnEditor={false}
                orderColumn={!props.hideOrderColumn}
                actionColumn={!props.hideActionColumn}
                dragSortColumn={props.presenter.dragSort.value}
                autoScroll={true}
                childrenColumnName={props.presenter.childrenKey}
                parentKey={props.presenter.parentKey}
                rowSelectDisabledCheck={props.presenter.rowSelectDisabled}
                colSpanItems={props.presenter.colSpanItems.value}
                rowSpanItems={rowSpanItems.value}
                rowSpanKeyResolve={rowSpanKeyResolve}
                loading={props.presenter.loading.value}
                expandIconColumnIndex={props.presenter.expandColumnIndex}
                showRowSelectInfo={props.presenter.showRowSelectInfo.value}
                rowSelectionPosition={
                    props.presenter.rowSelectionPosition.value
                }
                rowKey={props.presenter.primaryKey}
                withContainer={props.withContainer}
                onClickRow={onClickRow}
                onChange={tableOnChange}
                onActionClick={props.presenter.dispatchActionClick}
                onRowSelectChange={onRowChange}
                onRowSelect={onRowSelect}
                onRowSelectAll={onRowSelectAll}
                onPageChange={onPageChange}
                onShowSizeChange={onShowSizeChange}
                onEditableRowCreated={onEditableRowCreated}
                customRowProps={{ presenter: props.presenter }}
                editableColumnContext={props.presenter.context}
                actionDisable={props.presenter.actionDisable}
                actionVisible={props.presenter.actionVisible}
                cellEditable={props.presenter.cellEditable}
                rowEditable={props.presenter.rowEditable}
                actionMaxShowLength={props.presenter.rowActionsMaxShowLength}
                ref={gridRef}
                {...context.attrs}
            >
                {{
                    header: renderTabs,
                    headerCell,
                }}
            </IGrid>
        );
    },
});
