import { Row, Col } from "@intasect/ant-design-vue";
import { defineComponent, onMounted, PropType, ref } from "vue";
import {
    FormSolutionItemDef,
    FormItemDef,
    FormTableItemDef,
} from "../Presenters/declare";
import { GroupFormItem } from "./GroupFormItem";
import { NormalFormItem } from "./NormalFormItem";
import { useFormItemCol } from "./hooks";

export const IFormRow = defineComponent({
    name: "i-form-item",
    props: {
        //表单项定义
        items: {
            type: Array as PropType<FormSolutionItemDef[]>,
            required: true,
        },
        //表单的数据模型
        formModel: {
            type: Object as PropType<{ [key: string]: any }>,
            required: true,
        },
        //栅格数量
        gutter: {
            type: Number,
            default: 24,
        },
        //模式
        mode: {
            type: String as PropType<"default" | "view">,
            default: "default",
        },
        providers: {
            type: Object as PropType<
                Record<string, (def: FormSolutionItemDef, value: any) => any>
            >,
            default: () => ({}),
        },
        colspan: {
            type: Number,
            required: true,
        },
    },
    setup(props, context) {
        const rowRef = ref();
        const containerRef = ref();
        onMounted(() => {
            if (rowRef.value) {
                containerRef.value = rowRef.value.$el;
            }
        })
        return () => (
            <Row gutter={props.gutter} ref={rowRef}>
                {context.slots.formHeader && (
                    <Col span={props.colspan}>{context.slots.formHeader()}</Col>
                )}
                {props.items.map(item => {
                    if (
                        item.controlType === "card" ||
                        item.controlType === "tab" ||
                        item.controlType === "tabItem"
                    ) {
                        const span = useFormItemCol(props, item);
                        return (
                            <GroupFormItem
                                key={item.grouName}
                                colspan={span.value}
                                formModel={props.formModel}
                                providers={props.providers}
                                item={item}
                                mode={props.mode}
                                container={containerRef.value}
                            />
                        );
                    } else {
                        return (
                            <NormalFormItem
                                key={
                                    (item as FormItemDef | FormTableItemDef)
                                        .meta.fieldName
                                }
                                colspan={props.colspan}
                                formModel={props.formModel}
                                providers={props.providers}
                                item={item as FormItemDef | FormTableItemDef}
                                mode={props.mode}
                            />
                        );
                    }
                })}
                {context.slots.formFooter && (
                    <Col flex={1}>{context.slots.formFooter()}</Col>
                )}
            </Row>
        );
    },
});