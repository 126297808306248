import { defineComponent } from "vue";
/**
 * 使用方式
 * ```
 * <ISVGIcon name="" fill="" stroke="" style="" className=""/>
 * ```
 * name 是 "icon-" + svg文件名，或者是iconfont的symbol id
 */
export const ISVGIcon = defineComponent({
    name: "i-svg-icon",
    props: {
        name: {
            type: String,
            required: true,
        },
        className: {
            type: String,
        },
        fill: {
            type: String,
        },
        stroke: {
            type: String,
        },
    },
    setup(props) {
        return () => (
            <svg
                class={props.className}
                fill={props.fill}
                stroke={props.stroke}
            >
                <use xlinkHref={`#${props.name}`} />
            </svg>
        );
    },
});
