import { DragOutlined } from "@ant-design/icons-vue";
import {
    ColumnGroupType,
    ColumnType,
    ColumnsType,
} from "@intasect/ant-design-vue/es/table";
import { PlatformContext, uuid } from "@intasect/platform-core";
import {
    ComputedRef,
    VNodeTypes,
    computed,
    h,
    Ref,
    watchEffect,
    ref,
    provide,
    inject,
    reactive,
    watch,
} from "vue";
import { ITableCommonColumnType } from "../ITable/ITable";
import {
    GridSolutionItemDef,
    FormItemDef,
    RowCreatedHandler,
    OptionType,
} from "../Presenters/declare";
import { GridOperationAction } from "../ActionCell/ActionCell";
import { genRule, getStringLength } from "../utils/validate";
import {
    ColInfo,
    PageInfo,
    ActionInfo,
    EditableInfo,
    GridColGenOptions,
    IGridContext,
    CellEditable,
    RowEditable,
} from "./declare";
import type {
    TableRowSelection,
    Key,
    SortOrder,
    TablePaginationConfig,
} from "@intasect/ant-design-vue/es/table/interface";
import { useForm } from "@intasect/ant-design-vue/es/form";
import { IFORM_CONTEXT } from "../IForm/common";
import { IFormContext } from "../IForm/declare";
import { EditableCell } from "./EditableCell";
import { OrderColumn } from "./OrderColumn";

export const operationColumnKey = Symbol("action");
export const indexColumnKey = Symbol("index");
export const dragSortColumnKey = Symbol("dragSort");
export const IGRID_CONTEXT = "IGridContext";

export const sortTypeMap: { [key: string]: SortOrder } = {
    asc: "ascend",
    desc: "descend",
    default: null,
};

const defaultColInfo: Partial<ColInfo> = {
    dragSortCol: false,
    orderCol: true,
    columns: [],
    defaultColWidth: 200,
};

const defaultPageInfo: PageInfo = {
    isPaging: true,
    prevTotal: computed(() => 0),
};

const defaultActionInfo: Omit<ActionInfo, "render"> = {
    actionMaxShowLength: 3,
};

const defaultEditableInfo: EditableInfo = {};

export const useIGridColumns = <EntityDef>(
    options: GridColGenOptions<EntityDef>
) => {
    const {
        dragSortCol,
        orderCol,
        actionCol,
        columns,
        defaultColWidth,
        actionClick,
    } = { ...defaultColInfo, ...options.colInfo };
    const { isPaging, prevTotal } = { ...defaultPageInfo, ...options.pageInfo };
    const { actions, actionColWidth, actionMaxShowLength, render } = {
        ...defaultActionInfo,
        ...options.actions,
    };
    const { context } = { ...defaultEditableInfo, ...options.editable };
    const list: ColumnsType = [];
    if (dragSortCol) {
        list.push(generateDragSotColumns());
    }
    if (orderCol) {
        if (isPaging) {
            list.push(generateOrderColumn(prevTotal!));
        } else {
            list.push(generateUnfoldColumn());
        }
    }
    list.push(
        ...generateEntityColumns(
            columns,
            context!,
            defaultColWidth!,
            actionClick
        )
    );
    const inlineActionLength = actions!.length;
    if (inlineActionLength > 0 && actionCol) {
        list.push(
            generateActionColumn(
                render,
                actionColWidth,
                actions!,
                actionMaxShowLength!
            )
        );
    }
    return list;
};

const generateEntityColumns = function (
    columns: GridSolutionItemDef[],
    context: PlatformContext,
    defaultColWidth: number,
    actionClick: (
        actionKey: string,
        record: any | undefined,
        context: any
    ) => void
) {
    const colMap = new Map<string, ColumnGroupType<any> | ColumnType>();
    const parentMap: {
        [fieldName: string]: string[];
    } = {};
    columns.forEach(col => {
        colMap.set(
            col.meta.fieldName as string,
            generateItableColumn(col, context, defaultColWidth, actionClick)
        );
        if (col.parent) {
            if (!parentMap[col.parent]) {
                parentMap[col.parent] = [];
            }
            parentMap[col.parent].push(col.meta.fieldName as string);
        }
    });
    const parentKey: string[] = [];
    for (const key in parentMap) {
        const parent = colMap.get(key) as ColumnGroupType<any>;
        if (parent) {
            parent.children = [];
            //@ts-ignore
            delete parent.dataIndex;
            delete parent.customRender;
            parentMap[key].forEach(col => {
                const child = colMap.get(col);
                if (child) {
                    parent.children.push(child);
                    parentKey.push(col);
                }
            });
        }
    }
    parentKey.forEach(k => colMap.delete(k));
    return colMap.values();
};

const generateDragSotColumns = (): ColumnType => {
    return {
        title: "",
        key: dragSortColumnKey as any,
        fixed: "left",
        width: 40,
        customRender: () => {
            return h("div", { class: "dragItem" }, () => h(DragOutlined));
        },
    };
};

const generateOrderColumn = function (prevTotal: Ref<number>): ColumnType {
    return {
        title: "序号",
        key: indexColumnKey as any,
        fixed: "left",
        customRender: props => {
            return h(OrderColumn, { ...props, prevTotal: prevTotal.value });
        },
        width: 100,
    };
};
const generateUnfoldColumn = function (): ColumnType {
    return {
        title: "序号",
        key: indexColumnKey as any,
        fixed: "left",
        customRender: props => {
            return h(OrderColumn, props);
        },
        width: 100,
    };
};

const generateItableColumn = function (
    def: GridSolutionItemDef,
    context: PlatformContext,
    defaultWidth: number,
    actionClick: (
        actionKey: string,
        record: any | undefined,
        context: any
    ) => void
): ColumnType<any> & ITableCommonColumnType {
    const base: ColumnType<any> & ITableCommonColumnType = {
        key: generateITableColumnKey(def),
        dataIndex: def.meta.fieldName as string,
        title: def.title,
        resizable: !!def.resizable,
        width: def.width ?? defaultWidth,
        sorter: !!def.sort,
        headerAlign: def.titleAlign,
        bodyAlign: def.contentAlign,
        align: def.titleAlign ?? def.contentAlign,
        ellipsis: def.ellipsis,
        fixed: def.fixed === "none" ? undefined : def.fixed,
        cellStyle: def.cellStyle,
    };
    if (def.sort) {
        base.sortOrder = sortTypeMap[def.sort];
    }
    base.customRender = options => {
        return h(EditableCell, { options, def, context, actionClick });
    };
    return base;
};

const generateITableColumnKey = function (def: GridSolutionItemDef) {
    const key = def.meta.fieldName as string;
    if (!key) {
        console.warn("can not find key in columnDef", def);
        return uuid(8, 32);
    }
    return key;
};

const generateActionColumn = function (
    customRender: (opt: {
        value: any;
        text: any;
        record: any;
        index: number;
        column: ColumnType<any>;
    }) => VNodeTypes,
    actionWidth: number | undefined,
    actions: GridOperationAction[],
    maxLength: number
): ColumnType {
    const showActions =
        actions.length > maxLength ? actions.slice(0, maxLength) : actions;
    const cellWidth =
        actionWidth ??
        24 +
            showActions
                .map(a => {
                    if (typeof a.title === "string") {
                        return 10 + getStringLength(a.title) * 7;
                    } else {
                        return a.width || 80;
                    }
                })
                .reduce((p, c) => p + c) +
            (showActions.length !== actions.length ? 24 : 0);
    return {
        title: "操作",
        key: operationColumnKey as any,
        align: "left",
        fixed: "right",
        width: cellWidth,
        customRender,
    };
};

export const useIGridRowSelection = <T = any>(
    enable: Ref<boolean>,
    multi: Ref<boolean>,
    selectedRowKeys: Ref<any[]>,
    selectedRows: Ref<T[]>,
    rowKey: Ref<string>,
    dataSource: Ref<T[]>,
    getCheckboxProps: (record: T) => Record<string, any>,
    emit: (...params: any[]) => void
) => {
    const onRowChange = (rowKeys: Key[], rows: T[]) => {
        emit("update:selectedRows", rows);
        emit("update:selectedRowKeys", rowKeys);
        // emit("rowSelectChange", selectedRowKeys.value, rows);会导致选中的行是上一次的值
        emit("rowSelectChange", rowKeys, rows);
    };
    const onRowSelect = (
        record: any,
        selected: boolean,
        selectedRows: any[]
    ) => {
        emit("rowSelect", record, selected, selectedRows);
    };
    const onRowSelectAll = (
        selected: boolean,
        selectedRows: any[],
        changeRows: any[]
    ) => {
        emit("update:selectedRows", selectedRows);
        emit("rowSelectAll", selected, selectedRows, changeRows);
    };

    watch(
        () => selectedRowKeys.value,
        v => {
            //@ts-ignore
            const rowKeys = selectedRows.value.map(a => a[rowKey.value]);
            const excludeList = v.filter(b => !rowKeys.includes(b));
            if (excludeList.length > 0) {
                const newAppendRows = (dataSource.value ?? []).filter(a =>
                    excludeList.includes(a[rowKey.value])
                );
                if (newAppendRows.length > 0) {
                    emit("update:selectedRows", [
                        ...selectedRows.value,
                        ...newAppendRows,
                    ]);
                }
            }
        },
        { deep: true }
    );

    const rowSelection = computed<TableRowSelection<T> | undefined>(() => {
        if (enable.value) {
            return {
                fixed: "left",
                type: multi.value ? "checkbox" : "radio",
                selectedRowKeys: selectedRowKeys.value,
                onChange: onRowChange,
                onSelect: onRowSelect,
                onSelectAll: onRowSelectAll,
                columnWidth: "60px",
                getCheckboxProps,
                checkStrictly: false,
            };
        }
        return undefined;
    });
    return rowSelection;
};

export const useEditableForm = (
    formItemDefs: Ref<FormItemDef[]>,
    record: any,
    rowIndex: number
): [IFormContext, () => Promise<boolean>] => {
    const rules = ref<Record<string, any>>({});
    watchEffect(() => {
        rules.value = {};
        formItemDefs.value.forEach(def => {
            rules.value[def.meta.fieldName as string] = genRule(
                def.title || def.meta.title,
                def.validation
            );
        });
    });

    const editableFormContext = useForm(record, rules);
    const validate = async () => {
        try {
            await editableFormContext.validate(undefined, {
                trigger: "change",
                validateFirst: true,
                strict: true,
            });
            return true;
        } catch (e: any) {
            return false;
        }
    };
    const referData: Record<string, OptionType[]> = reactive({});
    const referOptions: Record<string, OptionType[]> = reactive({});
    const iFormContext: IFormContext = {
        layout: "tableRow",
        onTableActionClick: () => {},
        tableActionValid: () => true,
        tableActionDisable: () => true,
        tableActionVisible: () => true,
        selectedRecordMaps: {},
        itemPrefixCheck: () => true,
        itemSuffixCheck: () => true,
        get referData() {
            return referData;
        },
        get referOptions() {
            return referOptions;
        },
    };
    provide<IFormContext>(IFORM_CONTEXT, iFormContext);
    provide("editableForm", {
        editableFormContext,
        formItemDefs,
        rowIndex,
    });
    provide("editableRecord", {
        validate,
    });
    return [iFormContext, validate];
};

const pageSizeOptions = ["10", "30", "50"];
const showTotal = (total: number) => `共${total}条数据`;

export const useIGridPagination = (
    autoPaging: Ref<boolean>,
    isPaging: Ref<boolean>,
    pageNum: Ref<number>,
    pageSize: Ref<number>,
    total: Ref<number>,
    emit: (...params: any[]) => void
) => {
    const onChange = (pageIndex: number) => {
        emit("pageChange", pageIndex);
    };
    const onShowSizeChange = (pageIndex: number, pageSize: number) => {
        emit("showSizeChange", pageSize);
    };
    const defaultPagination = ref<TablePaginationConfig | false | undefined>({
        pageSize: 30,
        showTotal,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions,
    });
    const pagination = computed<TablePaginationConfig | false | undefined>({
        get() {
            if (autoPaging.value) {
                return defaultPagination.value;
            }
            return isPaging.value
                ? {
                      showTotal,
                      showSizeChanger: true,
                      showQuickJumper: true,
                      current: pageNum.value,
                      pageSize: pageSize.value,
                      total: total.value,
                      pageSizeOptions,
                      onChange,
                      onShowSizeChange,
                  }
                : false;
        },
        set(val) {
            defaultPagination.value = val;
        },
    });

    return pagination;
};

export const createIGridContext = (
    rowEditable: ComputedRef<RowEditable>,
    cellEditable: ComputedRef<CellEditable>,
    formItemDefs: Ref<FormItemDef[]>,
    dispatchRowCreated: RowCreatedHandler
) => {
    const validateFnList: Array<() => Promise<boolean>> = [];
    const editableRowFormContextMap: Record<number, IFormContext> = {};
    const addValidateFn = (fn: () => Promise<boolean>) => {
        if (!validateFnList.includes(fn)) {
            validateFnList.push(fn);
        }
    };
    const removeValidateFn = (fn: () => Promise<boolean>) => {
        const index = validateFnList.indexOf(fn);
        if (index > -1) {
            validateFnList.splice(index, 1);
        }
    };
    const validate = async () => {
        try {
            const result = await Promise.all(validateFnList.map(a => a()));
            return !result.some(a => !a);
        } catch (e) {
            return false;
        }
    };
    const addEditableRowFormContext = (
        index: number,
        context: IFormContext
    ) => {
        editableRowFormContextMap[index] = context;
    };
    const removeEditableRowFormContext = (index: number) => {
        delete editableRowFormContextMap[index];
    };

    provide<IGridContext>(IGRID_CONTEXT, {
        addValidateFn,
        removeValidateFn,
        get cellEditable() {
            return cellEditable.value;
        },
        get rowEditable() {
            return rowEditable.value;
        },
        formItemDefs,
        dispatchRowCreated,
        addEditableRowFormContext,
        removeEditableRowFormContext,
    });
    return { validate, editableRowFormContextMap };
};

export const useIGridContext = () => {
    const context = inject<IGridContext>(IGRID_CONTEXT);
    if (context === undefined) {
        throw new Error(`can't use IGridConxt without IGrid`);
    }
    return context;
};
