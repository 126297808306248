<template>
    <IModal
        :title="title"
        :visible="visible"
        @cancel="close"
        :width="800"
        :hideFullScreen="true"
    >
        <slot name="header"></slot>
        <UploadDragger
            name="file"
            :multiple="true"
            v-model:file-list="fileList"
            :maxCount="multi ? null : 1"
            :customRequest="onCustomRequest"
        >
            <p class="ant-upload-drag-icon">
                <InboxOutlined></InboxOutlined>
            </p>
            <p class="ant-upload-text">点击或将文件拖拽到这里上传</p>
            <p class="ant-upload-hint">支持扩展名: .xIs,xlsx</p>
        </UploadDragger>
        <template #footer>
            <i-button class="footer-left" @click="downFile">下载模板</i-button>
            <i-button @click="close">取消</i-button>
            <i-button type="primary" @click="submit">导入</i-button>
        </template>
    </IModal>
</template>
<script lang="ts">
import { defineComponent, ref, toRaw, watch } from "vue";
import IModal from "@/components/i-components/IModal/IModal.vue";
import IQueryForm from "@/components/i-components/IQueryForm/IQueryForm";
import IButton from "@/components/i-components/IButton/IButton";
import { FormInstance, Upload, UploadProps } from "@intasect/ant-design-vue";
import { InboxOutlined } from "@ant-design/icons-vue";
import { usePlatform } from "@/utils/composables";
import IForm from "@/components/i-components/IForm/IForm";
export default defineComponent({
    components: {
        IModal,
        IQueryForm,
        IButton,
        UploadDragger: Upload.Dragger,
        IForm,
        InboxOutlined,
    },
    emits: ["close", "ok"],
    props: {
        //显示隐藏
        visible: {
            type: Boolean,
        },
        //标题
        title: {
            type: String,
            required: true,
            default: () => "导入",
        },
        //下载地址 目前只支持get请求
        downUrl: {
            type: String,
        },

        //是否单文件上传
        multi: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const close = () => {
            context.emit("close");
        };
        const submit = () => {
            const row = {
                fileList: toRaw(fileList.value),
            };
            context.emit("ok", row);
        };
        const onCustomRequest = (file: any) => {
            setTimeout(() => {
                file.onSuccess();
            });
        };
        const platForm = usePlatform();
        const downFile = () => {
            platForm.download({
                url: props.downUrl,
                method: "get",
            });
        };
        const formRef = ref<FormInstance>();
        watch(
            () => props.visible,
            newdata => {
                if (newdata) {
                    fileList.value = [];
                }
            }
        );
        const fileList = ref<UploadProps["fileList"]>([]);

        return {
            close,
            submit,
            onCustomRequest,
            fileList,
            downFile,
            formRef,
        };
    },
});
</script>
<style scoped>
.footer-left {
    float: left;
}
</style>
