import {
    defineComponent,
    ref,
    PropType,
    toRef,
    defineAsyncComponent,
    provide,
    reactive,
} from "vue";
import type { FormInstance } from "@intasect/ant-design-vue";
import { FormSolutionItemDef, OptionType } from "../Presenters/declare";
import "@intasect/ant-design-vue/es/row/style";
import "@intasect/ant-design-vue/es/col/style";
import "@intasect/ant-design-vue/es/form/style";
import "@intasect/ant-design-vue/es/spin/style";
import type {
    NamePath,
    ValidateErrorEntity,
} from "@intasect/ant-design-vue/es/form/interface";
import { useFormResizeOb } from "./hooks";
import "./style.less";
import { computed } from "@vue/reactivity";
import { IFormContext } from "./declare";
import { IFormRow } from "./IFormRow";
import { IFORM_CONTEXT } from "./common";
import { OperationActionKey } from "../ActionCell/ActionCell";

const Form = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/form")
);

const Spin = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/spin")
);

const IForm = defineComponent({
    name: "i-form",
    emits: ["finish", "finishFailed", "submit", "validate", "tableActionClick"],
    components: {
        Form,
        Spin,
    },
    props: {
        //表单项定义
        items: {
            type: Array as PropType<FormSolutionItemDef[]>,
            required: true,
        },
        //表单的数据模型
        formModel: {
            type: Object as PropType<{ [key: string]: any }>,
            required: true,
        },
        //布局方式 垂直/水平
        layout: {
            type: String as PropType<"horizontal" | "vertical">,
            default: "vertical",
        },
        //表单文字描述的对其方式
        labelAlign: {
            type: String as PropType<"left" | "right">,
            default: "right",
        },
        //是否显示边框样式
        withContainer: {
            type: Boolean,
            default: true,
        },
        labelCol: {
            type: Object as PropType<{
                offset?: number;
                span?: number;
                style?: any;
            }>,
            default: () => ({
                span: 3,
            }),
        },
        //最大列数（会根据表单的尺寸自动调整列数）
        maxCol: {
            type: Number,
            default: 4,
        },
        minCol: {
            type: Number,
            default: 1,
        },
        //模式
        mode: {
            type: String as PropType<"default" | "view">,
            default: "default",
        },
        providers: {
            type: Object as PropType<
                Record<string, (def: FormSolutionItemDef, value: any) => any>
            >,
            default: () => ({}),
        },
        //栅格数量
        gutter: {
            type: Number,
            default: 24,
        },
        formContext: {
            type: Object as PropType<{
                [key: string]: any;
            }>,
            default: () => ({ selectedRecordMaps: {} }),
        },
        tableActionValid: {
            type: Function as PropType<
                (fieldName: string, record: any, formModel: any) => boolean
            >,
            default: () => true,
        },
        tableActionVisible: {
            type: Function as PropType<
                (fieldName: string, record: any, formModel: any) => boolean
            >,
            default: () => true,
        },
        tableActionDisable: {
            type: Function as PropType<
                (fieldName: string, record: any, formModel: any) => boolean
            >,
            default: () => false,
        },
        itemPrefixCheck: {
            type: Function as PropType<
                (data: {
                    def: FormSolutionItemDef;
                    formModel: Record<string, any>;
                }) => boolean
            >,
            default: () => true,
        },
        itemSuffixCheck: {
            type: Function as PropType<
                (data: {
                    def: FormSolutionItemDef;
                    formModel: Record<string, any>;
                }) => boolean
            >,
            default: () => true,
        },
        loading: Boolean,
    },
    setup(props, context) {
        const formRef = ref<FormInstance>();
        const containerRef = ref<HTMLElement>();
        const formModel = toRef(props, "formModel");
        const { colSetting } = useFormResizeOb(
            containerRef,
            props.gutter,
            props.maxCol,
            props.minCol
        );
        const referData: Record<string, OptionType[]> = reactive({});
        const referOptions: Record<string, OptionType[]> = reactive({});
        const onFinish = (values: any) => {
            context.emit("finish", values);
        };
        const onFinishFailed = (params: ValidateErrorEntity<any>) => {
            context.emit("finishFailed", params);
        };
        const onSubmit = (e: Event) => {
            context.emit("submit", e);
        };
        const onValidate = (
            name: string | number | string[] | number[],
            status: boolean,
            errors: string[]
        ) => {
            context.emit("validate", name, status, errors);
        };
        const onTableActionClick = (
            fieldName: string,
            actionKey: OperationActionKey,
            currentRecord?: any,
            selectedRecords?: any[],
            editableRecordContext: {} | undefined = undefined
        ) => {
            context.emit(
                "tableActionClick",
                fieldName,
                actionKey,
                currentRecord,
                selectedRecords,
                editableRecordContext
            );
        };

        const iFormContext: IFormContext = {
            get customContext() {
                return props.formContext;
            },
            onTableActionClick,
            get tableActionValid() {
                return props.tableActionValid;
            },
            get tableActionDisable() {
                return props.tableActionDisable;
            },
            get tableActionVisible() {
                return props.tableActionVisible;
            },
            selectedRecordMaps: {},
            get itemPrefix() {
                return context.slots.itemPrefix;
            },
            get itemSuffix() {
                return context.slots.itemSuffix;
            },
            get formLabel() {
                return context.slots.formLabel;
            },
            get itemPrefixCheck() {
                return props.itemPrefixCheck;
            },
            get itemSuffixCheck() {
                return props.itemSuffixCheck;
            },
            get layout() {
                return props.layout;
            },
            get referData() {
                return referData;
            },
            get referOptions() {
                return referOptions;
            },
        };

        provide<IFormContext>(IFORM_CONTEXT, iFormContext);

        const className = computed(() => {
            return props.withContainer ? "i-form container" : "i-form";
        });

        context.expose({
            clearValidate: (nameList?: NamePath) => {
                formRef.value?.clearValidate(nameList);
            },
            resetFields: (nameList?: NamePath) => {
                formRef.value?.resetFields(nameList);
            },
            scrollToField: (name: NamePath, options: [ScrollOptions]) => {
                formRef.value?.scrollToField(name, options);
            },
            validate: async (nameList?: NamePath[]) => {
                return formRef.value?.validate(nameList);
            },
            validateFields: async (nameList?: NamePath[]) => {
                return formRef.value?.validateFields(nameList);
            },
            submit: (e: Event) => {
                return formRef.value?.submit(e);
            },
            iFormContext,
        });

        return () => (
            <div class={className.value} ref={containerRef}>
                <Spin spinning={props.loading}>
                    <Form
                        layout={props.layout}
                        ref={formRef}
                        model={formModel.value}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        onSubmit={onSubmit}
                        onValidate={onValidate}
                        label-col={
                            props.labelCol && props.layout === "horizontal"
                                ? props.labelCol
                                : undefined
                        }
                        labelAlign={props.labelAlign}
                    >
                        <IFormRow
                            gutter={props.gutter}
                            items={props.items}
                            mode={props.mode}
                            colspan={colSetting.span.value}
                            formModel={formModel.value}
                            providers={props.providers}
                        >
                            {{
                                formFooter: context.slots.formFooter,
                            }}
                        </IFormRow>
                    </Form>
                </Spin>
            </div>
        );
    },
});

export default IForm;
