import { Popconfirm } from "@intasect/ant-design-vue";
import { TableRowSelection } from "@intasect/ant-design-vue/es/table/interface";
import { defineComponent, PropType, ref, watchEffect, watch } from "vue";
import {
    GridOperationAction,
    OperationActionKey,
} from "../ActionCell/ActionCell";
import IButton from "../IButton/IButton";
import IModal from "@/components/i-components/IModal/IModal.vue";
import { ExclamationCircleFilled } from '@ant-design/icons-vue';
export const ActionButton = defineComponent({
    props: {
        rowActionDisable: {
            type: Function as PropType<
                (actionKey: OperationActionKey) => boolean
            >,
            default: () => true,
        },
        rowSelection: {
            type: Object as PropType<TableRowSelection>,
        },
        action: {
            type: Object as PropType<GridOperationAction>,
            required: true,
        },
    },
    emits: ["actionClick"],
    setup(props, { emit }) {
        const visible = ref(false)
        const disabled = ref(false);
        watchEffect(() => {
            disabled.value = props.rowActionDisable(props.action.key);
        });
        watch(
            () => props.rowSelection?.selectedRowKeys?.length,
            () => {
                disabled.value = props.rowActionDisable(props.action.key);
            }
        );
        const content =
            typeof props.action.title === "string"
                ? () => props.action.title
                : props.action.title;
        const onClick = (e: Event) => {
            emit("actionClick", props.action.key);
            visible.value = false
            // props.presenter.dispatchActionClick(props.action.key);
        };
        const openModal = (e: Event) => {
            e.stopPropagation();
            visible.value = true
        };
        const close = () => {
            visible.value = false
        }
        return () => {
            if (props.action.popConfirm) {
                return (
                    <><IButton
                            key={props.action.key}
                            type={props.action.buttonStyle}
                            disabled={disabled.value}
                            onClick={openModal}
                        >
                            {{ default: content }}
                        </IButton>
                        <IModal visible={visible.value} title='提示' width={420} onCancel={close} disableMove={true} disableResize={false} hideFullScreen={true} okText={props.action.popConfirm.okText} cancelText={props.action.popConfirm.cancelText}

                            onOk={onClick} class='hint-modal'>
                            <div class="hint-body">
                                <ExclamationCircleFilled class='hint-icon' /> {props.action.popConfirm.title}
                            </div>

                        </IModal>
                    </>
                    // <Popconfirm
                    //     {...props.action.popConfirm}
                    //     onConfirm={onClick}
                    // >
                    //     <IButton
                    //         key={props.action.key}
                    //         type={props.action.buttonStyle}
                    //         disabled={disabled.value}
                    //     >
                    //         {{ default: content }}
                    //     </IButton>
                    // </Popconfirm>
                );
            } else {
                return (
                    <IButton
                        key={props.action.key}
                        type={props.action.buttonStyle}
                        disabled={disabled.value}
                        onClick={onClick}
                    >
                        {{
                            default: content,
                        }}
                    </IButton>
                );
            }
        };
    },
});
