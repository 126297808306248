// import { Image } from "@intasect/ant-design-vue";
import { imageProps } from "@intasect/ant-design-vue/es/image/index";
import { defineAsyncComponent, defineComponent, PropType, ref } from "vue";
import err from "../../../assets/image/err.png";
import placeholder from "../../../assets/image/placeholder.png";
import '@intasect/ant-design-vue/es/image/style'
import "./style.less";
const Image = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/image'))
const PreviewGroup = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/image/PreviewGroup'))

export default defineComponent({
    name: "i-image",
    components: {
        Image,
        PreviewGroup
    },
    props: {
        ...imageProps,
        src: String,
        placeholderUrl: {
            type: String,
            default: placeholder,
        },
        fallback: {
            type: String,
            default: err,
        },
        width: {
            type: [String, Number],
            default: 200,
        },
        height: {
            type: [String, Number],
            default: 200,
        },
        srclist: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        preview:{
            type:Boolean,
            default:false
        }
    },
    setup(props) {
        const visible = ref(false);
        const renderPlaceholder = () => {
            if (props.placeholderUrl) {
                return (
                    <Image
                        width={props.width}
                        height={props.height}
                        src={props.placeholderUrl}
                        preview={false}
                    />
                );
            } else {
                return true;
            }
        };
        if (props.srclist && props.srclist.length > 0) {
            if (props.src) {
                return () => (
                    <>
                        <Image
                            width={props.width}
                            height={props.height}
                            src={props.src}
                            preview={{ visible: false }}
                            onClick={() => (visible.value = true)}
                        />
                        <div style={{ display: "none" }}>
                            <PreviewGroup
                                //@ts-ignore
                                preview={{
                                    visible: visible.value,
                                    onVisibleChange: (vis: any) =>
                                        (visible.value = vis),
                                }}
                            >
                                {props.srclist.map(val => {
                                    return (
                                        <Image
                                            width={props.width}
                                            height={props.height}
                                            src={val}
                                        />
                                    );
                                })}
                            </PreviewGroup>
                        </div>
                    </>
                );
            }
            return () => (
                <PreviewGroup>
                    {props.srclist.map((val: any, i) => {
                        return (
                            <Image
                                width={props.width}
                                height={props.height}
                                src={val}
                            />
                        );
                    })}
                </PreviewGroup>
            );
        }
        return () => (
            <Image
                width={props.width}
                height={props.height}
                src={props.src}
                placeholder={renderPlaceholder()}
                preview={props.preview}
            ></Image>
        );
    },
});
