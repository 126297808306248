import {
    defineComponent,
    PropType,
    ref,
    watch,
    defineAsyncComponent,
} from "vue";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons-vue";
import type {
    FileType,
    UploadFile,
    UploadListType,
} from "@intasect/ant-design-vue/es/upload/interface";
import { usePlatform } from "@/utils/composables";
import "@intasect/ant-design-vue/es/upload/style";
import "@intasect/ant-design-vue/es/image/style";
import "@intasect/ant-design-vue/es/button/style";

const Image = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/image")
);
const Upload = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/upload")
);
const Button = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/button")
);

import "./style.less";
import { UploadRequestOption } from "@intasect/ant-design-vue/es/vc-upload/interface";
import {
    AttachmentService,
    AttachmentServiceEvents,
} from "@/service/attachment";
import { FileResponse } from "@/service/declare";

const IUpload = defineComponent({
    name: "i-upload",
    components: {
        Upload,
        Image,
        Button,
    },
    emits: ["update:value", "acceptFail", "uploadFail"],
    props: {
        value: {
            type: Array as PropType<FileInfo[]>,
            default: () => [],
        },
        listType: {
            type: String as PropType<UploadListType>,
            default: "text",
        },
        data: {
            type: Object,
            default: () => {},
        },
        params: {
            type: String,
            default: "uploadfile",
        },
        placeholder: {
            type: String,
            default: "",
        },
        withCredentials: {
            type: Boolean,
            default: false,
        },
        maxCount: Number,
        valueResolve: {
            type: Function as PropType<(data: FileInfo[]) => FileInfo[]>,
            default: (data: FileInfo[]) => data,
        },
        maxSize: Number,
        minSize:Number,
        requestUpload: {
            type: Function as PropType<
                (options: UploadRequestOption) => Promise<FileResponse>
            >,
        },
        beforeUpload: {
            type: Function 
        },
        filePreview: {
            type: Function as PropType<(file: FileInfo) => void>,
            default: () => {},
        },
        disabled: Boolean,
        showUploadList: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit, slots, attrs }) {
        const platform = usePlatform();
        const attachmentService = platform.getService(AttachmentService);
        const defaultUpload = attachmentService.upload2;
        const fileList = ref<FileInfo[]>(props.value);
        const uploadImgButton = (title: string) => {
            let name = "上传图片";
            if (title) {
                name = title;
            }
            return (
                <Button
                    style="height:80px;width:80px"
                    disabled={props.disabled}
                >
                    <PlusOutlined />
                    <div>{name}</div>
                </Button>
            );
        };
        const uploadButton = (title?: string) => {
            return (
                <Button disabled={props.disabled}>
                    <UploadOutlined />
                    {title || "上传文件"}
                </Button>
            );
        };
        const handlePreview = async (file: UploadFile) => {
            if (props.listType === "text") {
                return props.filePreview(file);
            } else {
                previewImage.value = file.preview ?? file.thumbUrl ?? "";
                visible.value = true;
            }
        };
        const visible = ref<boolean>(false);
        const setVisible = (value: boolean): void => {
            visible.value = value;
        };
        const previewImage = ref("");

        const imgBeforeUpload = (
            file: FileType,
            filebeforeList: FileType[]
        ) => {
            const imageTypes = [
                "image/png",
                "image/jpeg",
                "image/gif" /*, 'image/svg+xml'*/,
            ];
            const isImage = imageTypes.includes(file.type);
            let isLimit: boolean;
            if (props.maxSize) {
                isLimit = file.size / 1024 / 1024 < props.maxSize;
            } else {
                isLimit = file.size / 1024 / 1024 < 51;
            }
            if(props.minSize){
                if(file.size / 1024 / 1024 < props.minSize){
                    platform.messageError(
                        `上传图片大小不能小于 ${
                           props.minSize + "MB!" 
                        }`
                    );
                    return false;
                }
            }
            return new Promise<boolean | string>((resolve, reject) => {
                if (!isImage) {
                    platform.messageError(
                        "仅支持上传图片的格式：jpg、jpeg、png、gif",
                        "图片上传错误"
                    );
                    return LIST_IGNORE;
                } else if (!isLimit) {
                    platform.messageError(
                        `上传图片大小不能超过 ${
                            props.maxSize ? props.maxSize + "MB!" : "50MB!"
                        }`
                    );
                    return LIST_IGNORE;
                } else {
                    resolve(true);
                }
            });
        };

        const beforeUpload = (file: FileType, filebeforeList: FileType[]) => {
            let isLimit: boolean;
            if (props.maxSize) {
                isLimit = file.size / 1024 / 1024 < props.maxSize;
            } else {
                isLimit = file.size / 1024 / 1024 < 51;
            }
            return new Promise<boolean | string>((resolve, reject) => {
                if (!isLimit) {
                    platform.messageError(
                        `上传文件大小不能超过 ${
                            props.maxSize ? props.maxSize + "MB!" : "50MB!"
                        }`
                    );
                    return LIST_IGNORE;
                } else {
                    resolve(true);
                }
            });
        };
        const customRequest = async (options: UploadRequestOption) => {
            const formData = new FormData();
            if (attrs.accept) {
                if (
                    //@ts-ignore
                    options.file.type === "" ||
                    //@ts-ignore
                    !attrs.accept.includes(options.file.type)
                ) {
                    emit("acceptFail", "无效的文件类型！");
                    attachmentService
                        .events(AttachmentServiceEvents.acceptFail)
                        .dispatch([attrs.accept]);
                    options.onError?.(new Error("无效的文件类型！"));
                    fileList.value = fileList.value.filter(
                        //@ts-ignore
                        a => a.uid !== options.file.uid
                    );
                    return;
                }
            }
            formData.append(props.params, options.file);
            try {
                const result = await (props.requestUpload || defaultUpload)(
                    options
                );
                if (result.success && result.data.length > 0) {
                    (options.file as any).id = result.data;
                    if (options.onSuccess) {
                        options.onSuccess(result.data, result as any);
                    }
                } else {
                    attachmentService
                        .events(AttachmentServiceEvents.uploadFail)
                        .dispatch([result.message || "", options]);
                    emit("uploadFail", result.message || "");
                    if (options.onError) {
                        options.onError(
                            result as any,
                            "上传失败！" + (result.message || "")
                        );
                    }
                }
            } catch (e) {
                if (options.onError) {
                    options.onError(e, "上传失败！" + (e.message || ""));
                }
            }
        };
        const uploadrender = () => {
            if (props.listType === "picture-card") {
                return (
                    <Upload
                        // action={props.url} 看后续需不需要加入链接形式 用这个形式需要加change事件，给返回对象加url
                        list-type={props.listType}
                        maxCount={props.maxCount}
                        v-model={[fileList.value, "file-list"]}
                        onPreview={handlePreview}
                        beforeUpload={imgBeforeUpload}
                        data={props.data}
                        customRequest={customRequest}
                        withCredentials={props.withCredentials}
                        {...attrs}
                        disabled={props.disabled}
                        show-upload-list={props.showUploadList}
                    >
                        {{
                            default: () => uploadImgButton(props.placeholder),
                            ...slots,
                        }}
                    </Upload>
                );
            } else if (props.listType === "picture") {
                return (
                    <Upload
                        beforeUpload={imgBeforeUpload}
                        list-type={props.listType}
                        maxCount={props.maxCount}
                        v-model={[fileList.value, "file-list"]}
                        onPreview={handlePreview}
                        data={props.data}
                        customRequest={customRequest}
                        withCredentials={props.withCredentials}
                        {...attrs}
                        disabled={props.disabled}
                    >
                        {{
                            default: () => uploadImgButton(props.placeholder),
                            ...slots,
                        }}
                    </Upload>
                );
            } else {
                return (
                    <Upload
                        beforeUpload={beforeUpload}
                        list-type={props.listType}
                        v-model={[fileList.value, "file-list"]}
                        maxCount={props.maxCount}
                        data={props.data}
                        onPreview={handlePreview}
                        customRequest={customRequest}
                        withCredentials={props.withCredentials}
                        progress={{ type: "circle", width: 20 } as any}
                        {...attrs}
                        disabled={props.disabled}
                        beforeUpload={props.beforeUpload?props.beforeUpload:true}
                    >
                        {{
                            default: () => uploadButton(props.placeholder),
                            ...slots,
                        }}
                    </Upload>
                );
            }
        };
        watch(
            () => fileList.value,
            data => {
                emit("update:value", props.valueResolve(data || []));
            }
        );
        return () => (
            <div class="i-upload">
                {uploadrender()}
                <Image
                    class="image"
                    preview={{
                        visible: visible.value,
                        onVisibleChange: setVisible,
                    }}
                    src={previewImage.value}
                />
            </div>
        );
    },
});
export const LIST_IGNORE = Upload.LIST_IGNORE;

export type FileInfo = {
    id?: string;
    name: string;
    size?: string | number;
    type?: string;
    status?: string;
    thumbUrl?: string;
};
export default IUpload;
// function getBase64(file: File) {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onload = () => resolve(reader.result);
//         reader.onerror = error => reject(error);
//     });
// }
