import { defineAsyncComponent, defineComponent } from "vue";
import '@intasect/ant-design-vue/es/badge/style'

const Badge = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/badge'))

export default defineComponent({
    name: "i-badge",
    components: {
        Badge,
    },
    props: {
        // ...badgeProps(),
        max: {
            type: Number,
            default: 99,
        },
    },
    setup(props, { slots }) {
        return () => <Badge overflowCount={props.max}>{slots}</Badge>;
    },
});
