import { resultProps } from "@intasect/ant-design-vue/es/result/index";
import { defineAsyncComponent, defineComponent } from "vue";
import '@intasect/ant-design-vue/es/result/style'
import "./index.less";

const Result = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/result'))

export default defineComponent({
    name: "i-result",
    components: {
        Result,
    },
    props: {
        ...resultProps(),
    },
    setup(props, { slots }) {
        return () => <Result {...props}>{slots}</Result>;
    },
});
