import {
    EntityController,
    EntityData,
    IDisposer,
    IObserver,
    LoadingResult,
    PageCondition,
    PageResonse,
    useEntity,
    UseEntityType,
} from "@intasect/platform-core";
import { ref, watch, Ref } from "vue";
import { FormItemDef, FormSolutionItemDef, FormTableActionClickEvent, FormTableActionParams } from "./declare";
import { genFormItemDef } from "./utils";
export abstract class FormPresenterCore<
    EntityDef extends { [key: string]: any },
    Controller extends EntityController<EntityDef, PC, PR, ED>,
    PC = PageCondition<EntityDef>,
    PR = PageResonse<EntityDef>,
    ED = EntityData<EntityDef>
> implements IDisposer {
    protected disposeList: Array<() => void> = [];
    public formFieldDef: Ref<Array<FormItemDef>>;
    protected entityDef: UseEntityType<EntityDef>; //[entity,save,refresh]
    private onActionClickEvents: FormTableActionClickEvent<EntityDef>[] = [];
    protected actionVisibleCheckMap: {
        [a in keyof EntityDef]?: {
            [actionKey: string]: (params: FormTableActionParams<Record<string, any>>) => boolean;
        }
    } = {};
    protected actionDisableCheckMap: {
        [a in keyof EntityDef]?: {
            [actionKey: string]: (params: FormTableActionParams<Record<string, any>>) => boolean;
        }
    } = {};

    protected selectRecordsMap: {
        [a in keyof EntityDef]?: Ref<any[]>
    } = {};
    //实体模型到视图模型的转换
    public transformToModelView = (entityView: EntityData<EntityDef>) => {
        for (const key in entityView) {
            if (key in this.viewModel) {
                //@ts-ignore
                this.viewModel[key] = entityView[key];
            }
        }
    };
    //视图模型到实体模型的转换
    public transformViewToEntityView = (modelView: Record<string, any>) => {
        for (const key in modelView) {
            if (key in this.entityModel) {
                //@ts-ignore
                this.entityModel[key] = modelView[key];
            }
        }
    };
    private _loading = ref(false);
    dispose = () => {
        this.disposeList.forEach(d => {
            d();
        });
    };
    public viewModel: Record<string, any>;
    constructor(
        itemDefs: FormSolutionItemDef[],
        protected readonly formController: Controller,
        protected readonly observerApi: IObserver
    ) {
        this.viewModel = this.observerApi.reactive<Record<string, any>>({});
        this.formFieldDef = ref<FormItemDef[]>(genFormItemDef(itemDefs));
        this.entityDef = this.generateFormEntity();
        this.disposeList.push(
            watch(
                () => ({
                    loadingResult: this.entityDef[0].loadingResult,
                    loading: this.entityDef[0].loading,
                }),
                val => {
                    if (val.loading) {
                        this._loading.value = true;
                    } else {
                        switch (val.loadingResult) {
                            case LoadingResult.initial:
                                break;
                            case LoadingResult.success:
                                this.transformToModelView(this.entityModel);
                                this._loading.value = false;
                                break;
                            case LoadingResult.failed:
                                this._loading.value = false;
                                break;
                        }
                    }
                },
                { immediate: true }
            )
        );
    }

    protected generateFormEntity(): UseEntityType<EntityDef> {
        const entity: Record<string, any> = { id: "" };
        this.formFieldDef.value.forEach(def => {
            entity[def.meta.fieldName as string] = undefined;
        });
        return useEntity<EntityDef>(
            this.observerApi,
            this.formController,
            entity as any
        );
    }

    addActionClickEvent = (fn: FormTableActionClickEvent<EntityDef>) => {
        this.onActionClickEvents.push(fn);
    };

    dispatchActionClick = (
        fieldName: string,
        actionKey: string,
        currentRecord?: EntityDef,
        selectedRecords?: EntityDef[],
        editableRecordContext:
            | {
                validate: () => Promise<boolean>;
            }
            | undefined = undefined
    ) => {
        this.onActionClickEvents.forEach(f => {
            f.call(null, {
                fieldName,
                actionKey,
                currentRecord,
                selectedRecords,
                context: editableRecordContext,
            });
        });
    };

    actionVisible = (
        fieldName: keyof EntityDef,
        actionKey: string,
        currentRecord: EntityDef | undefined = undefined
    ) => {
        if (this.actionVisibleCheckMap[fieldName] && this.actionVisibleCheckMap[fieldName]![actionKey]) {
            return this.actionVisibleCheckMap[fieldName]![actionKey].call(null, {
                fieldName: fieldName as string,
                actionKey,
                currentRecord,
                selectedRecords: this.selectRecordsMap[fieldName]?.value,
                formModel: this.entityDef[0],
                context: {
                    data: 'to do'
                },
            });
        }
        return true;
    };

    actionDisable = (
        fieldName: keyof EntityDef,
        actionKey: string,
        currentRecord: EntityDef | undefined = undefined
    ) => {
        if (this.actionDisableCheckMap[fieldName] && this.actionDisableCheckMap[fieldName]![actionKey]) {
            return this.actionDisableCheckMap[fieldName]![actionKey].call(null, {
                fieldName: fieldName as string,
                actionKey,
                currentRecord,
                selectedRecords: this.selectRecordsMap[fieldName]?.value,
                formModel: this.entityDef[0],
                context: {
                    data: 'to do'
                },
            });
        }
        return false;
    };

    addActionVisibleRule = <T extends Record<string, any> = Record<string, any>>(
        fieldName: keyof EntityDef,
        actionKey: string,
        rule: (params: FormTableActionParams<T>) => boolean
    ) => {
        if (this.actionVisibleCheckMap[fieldName] === undefined) {
            this.actionVisibleCheckMap[fieldName] = {}
        }
        Object.assign(this.actionVisibleCheckMap[fieldName]!, {
            [actionKey]: rule
        })
    };

    addActionDisableRule = <T extends Record<string, any> = Record<string, any>>(
        fieldName: keyof EntityDef,
        actionKey: string,
        rule: (params: FormTableActionParams<T>) => boolean
    ) => {
        if (this.actionDisableCheckMap[fieldName] === undefined) {
            this.actionDisableCheckMap[fieldName] = {}
        }
        Object.assign(this.actionDisableCheckMap[fieldName]!, {
            [actionKey]: rule
        })
    };

    //视图模型
    get dataModel() {
        return this.viewModel;
    }
    //实体模型
    get entityModel() {
        return this.entityDef[0].entity;
    }

    loadForm(id: string) {
        this.entityDef[0].entity.id = id;
    }

    getField(fieldName: string) {
        return this.formFieldDef.value.find(
            a => a.meta.fieldName === fieldName
        );
    }

    async save() {
        //@ts-ignore
        this.transformViewToEntityView(this.viewModel);
        return this.entityDef[1]();
    }

    get loading() {
        return this._loading;
    }
}
