import { PropType, computed, defineComponent } from "vue";
import { GridSolutionItemDef } from "../Presenters/declare";
import { PlatformContext } from "@intasect/platform-core";
import type { ColumnType } from "@intasect/ant-design-vue/es/table";
import { EditableGridItem } from "./EditableGridItem";
import { useIGridContext } from "./hooks";

export const EditableCell = defineComponent({
    props: {
        options: {
            type: Object as PropType<{
                value: any;
                text: any;
                record: any;
                index: number;
                renderIndex: number;
                column: ColumnType<any>;
            }>,
            required: true,
        },
        actionClick: {
            type: Function as PropType<
                (actionKey: string, record: any, context: any) => void
            >,
            required: true,
        },
        def: {
            type: Object as PropType<GridSolutionItemDef>,
            required: true,
        },
        editMode: Boolean,
        context: {
            type: Object as PropType<PlatformContext>,
            required: true,
        },
    },
    setup(props) {
        const iGridContext = useIGridContext();
        const formDef = computed(() => {
            return iGridContext.formItemDefs.value.find(
                a => a.meta.fieldName === props.def.meta.fieldName
            );
        });
        return () => {
            if (
                formDef.value &&
                iGridContext.rowEditable({
                    record: props.options.record,
                    rowIndex: props.options.index,
                }) &&
                iGridContext.cellEditable({
                    record: props.options.record,
                    rowIndex: props.options.index,
                    formItemDef: formDef.value,
                })
            ) {
                return (
                    <EditableGridItem
                        fieldName={formDef.value.meta.fieldName as string}
                        formModel={props.options.record}
                    />
                );
            } else if (props.def.cellRender) {
                return props.def.cellRender(
                    props.options.value,
                    props.options.record,
                    props.options.index,
                    props.context,
                    props.def,
                    (actionKey: string) =>
                        props.actionClick(
                            actionKey,
                            props.options.record,
                            props.context
                        )
                );
            } else {
                return props.options.text;
            }
        };
    },
});
