import { inputNumberProps } from "@intasect/ant-design-vue/es/input-number";
import { computed, defineAsyncComponent, defineComponent, PropType } from "vue";
import "./style.less";
import '@intasect/ant-design-vue/es/input-number/style'
import { ValidationStatus } from "../Presenters/declare";

const InputNumber = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/input-number'))
export type NumType = "thousands" | "default" | "percent"
export const IInputNumber = defineComponent({
    name: "i-input-number",
    components: {
        InputNumber,
    },
    props: {
        ...inputNumberProps(),
        status: {
            type: String as PropType<ValidationStatus>,
            default: "default",
        },
        type: {
            type: String as PropType<NumType>,
            default: "default",
        }
    },
    setup(props, ctx) {
        const formatter = ((value: string | number, info: {
            userTyping: boolean;
            input: string;
        }) => {
            if (props.type === "thousands") {
                return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            } else if (props.type === "percent") {
                return `${value}%`
            }
            return props.formatter ? props.formatter(value, info) : String(value)

        });
        const parser = ((value: string) => {
            if (props.type === "thousands") {
                return value.replace(/\$\s?|(,*)/g, '')
            } else if (props.type === "percent") {
                return value.replace('%', '')
            }
            return props.parser ? props.parser(value) : value
        })
        const classNames = computed(() => {
            //@ts-ignore
            return `i-input-number i-input-number-${props.status} ${props.class || ""}`;
        });
        return () => (
            <InputNumber {...ctx.attrs} class={classNames.value} {...props} formatter={formatter} parser={parser} />
        );
    },
});
