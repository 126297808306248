import { checkboxGroupProps } from "@intasect/ant-design-vue/es/checkbox";
import { defineAsyncComponent, defineComponent } from "vue";
import '@intasect/ant-design-vue/es/checkbox/style'

const Checkbox = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/checkbox'))
const Group = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/checkbox/Group'))

export const ICheckboxGroup = defineComponent({
    name: "i-radio-group",
    components: {
        Checkbox,
        Group
    },
    props: {
        ...checkboxGroupProps(),
    },
    setup(props) {
        return () => <Group {...props}></Group>;
    },
});
