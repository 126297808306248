import { defineAsyncComponent, defineComponent } from "vue";
import { treeSelectProps } from "@intasect/ant-design-vue/es/tree-select";
import "@intasect/ant-design-vue/es/tree-select/style";

const TreeSelect = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/tree-select")
);
export default defineComponent({
    name: "i-select-tree",
    props: {
        ...treeSelectProps(),
    },
    components: {
        TreeSelect,
    },
    setup(props, { slots, attrs }) {
        return () => (
            <TreeSelect
                class={["i-tree-select", attrs.class]}
                {...props}
                dropdownStyle={{ maxHeight: "400px", overflow: "auto" }}
            >
                {{
                    ...slots,
                }}
            </TreeSelect>
        );
    },
});
