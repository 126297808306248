import { defineAsyncComponent, defineComponent, h } from "vue";
import IButton from "../IButton/IButton";
import '@intasect/ant-design-vue/es/drawer/style'

const Drawer = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/drawer'))

export default defineComponent({
    name: "i-drawer",
    components: {
        Drawer,
        IButton,
    },
    emit: ["ok"],
    props: {
        buttonState: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },

    setup(props, { emit }) {
        const buttonOk = () => {
            emit("ok");
        };
        return { buttonOk };
    },
    render() {
        return h(
            Drawer,
            {
                ...this.$attrs,
                visible: this.$props.visible,
                on: this.$attrs,
            },
            {
                ...this.$slots,
                placement: () => "right",
                extra: () => {
                    if (this.$props.buttonState) {
                        return h(
                            IButton,
                            {
                                type: "primary",
                                onclick: this.buttonOk,
                            },
                            "确定"
                        );
                    } else {
                        return null;
                    }
                },
            }
        );
    },
});
