import {
    computed,
    defineAsyncComponent,
    defineComponent,
    h,
    PropType,
} from "vue";
import "@intasect/ant-design-vue/es/tooltip/style";
import { CellColorStyle } from "./ITable";

const Tooltip = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/tooltip")
);

export const TitleCell = defineComponent({
    components: {
        Tooltip,
    },
    props: {
        title: String,
        ellipsis: Boolean,
        titleTip: Function,
        cellStyle: {
            type: Object as PropType<{
                backColor?: CellColorStyle;
                fontColor?: CellColorStyle;
            }>,
        },
    },
    setup(props, context) {
        const cellClassName = computed(() => {
            if (props.cellStyle) {
                switch (props.cellStyle.backColor) {
                    case "primary":
                    case "success":
                    case "error":
                    case "normal":
                    case "warn":
                        return `i-cell-${props.cellStyle.backColor}`;
                    default:
                        return "";
                }
            }
            return "";
        });

        const fontClassName = computed(() => {
            if (props.cellStyle) {
                switch (props.cellStyle.fontColor) {
                    case "primary":
                    case "success":
                    case "error":
                    case "normal":
                    case "warn":
                        return `i-cell-font-${props.cellStyle.fontColor}`;
                    default:
                        return "";
                }
            }
            return "";
        });
        if (props.ellipsis) {
            return () => (
                <td
                    {...context.attrs}
                    class={[cellClassName.value, fontClassName.value]}
                >
                    <Tooltip placement="topLeft">
                        {{
                            default: () =>
                                h(
                                    "div",
                                    {
                                        class: "ellipsis",
                                    },
                                    { default: context.slots.default }
                                ),
                            title: () => {
                                if (props.titleTip) {
                                    return props.titleTip(
                                        props.title ?? context.slots.default?.()
                                    );
                                }
                            },
                        }}
                    </Tooltip>
                </td>
            );
        }
        return () => (
            <td
                {...context.attrs}
                class={[cellClassName.value, fontClassName.value]}
            >
                {{
                    default: context.slots.default,
                }}
            </td>
        );
    },
});
