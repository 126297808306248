import {
    SettingOutlined,
    VerticalAlignTopOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, PropType } from "vue";
import { ListEditor } from "../ListEditor/ListEditor";

import "./ColumnsEditor.less";
import { EnhanceColType, getColSpanTarget } from "./ITable";

export const visibleKey = "__visible";

export type ColumnFixedType = "left" | "right" | undefined;

export const ColumnsEditor = defineComponent({
    emits: ["columnOrderChanged", "columnFixedChanged", "reset"],
    props: {
        columns: { type: Array as PropType<Array<EnhanceColType>> },
        visibleKey: {
            type: String,
            default: visibleKey,
        },
    },
    components: {
        ListEditor,
    },
    setup(props, context) {
        const onColumnOrderChanged = (oldIndex: number, newIndex: number) => {
            const oldColumns = props.columns || [];
            const dragItem = oldColumns[oldIndex];
            const dropItem = oldColumns[newIndex];
            const orderColmuns: Array<EnhanceColType> = [];
            const dropOrderItem = getColSpanTarget(dropItem);
            const dragOrderItem = getColSpanTarget(dragItem);

            if (
                dragOrderItem &&
                dropOrderItem !== dragOrderItem &&
                dragOrderItem.colSpan
            ) {
                oldIndex = oldColumns.indexOf(dragOrderItem);
                for (let i = 0; i < dragOrderItem.colSpan; i++) {
                    orderColmuns.push(oldColumns[oldIndex + i]);
                }
            } else {
                orderColmuns.push(dragItem);
            }
            const newColumns = oldColumns.filter(
                a => !orderColmuns.includes(a)
            );
            const insertInex = newColumns.indexOf(dropItem);
            if (oldIndex === newIndex) {
                return;
            }
            if (oldIndex > newIndex) {
                newColumns.splice(insertInex, 0, ...orderColmuns);
            } else {
                newColumns.splice(insertInex + 1, 0, ...orderColmuns);
            }

            context.emit("columnOrderChanged", newColumns);
        };
        const onReset = () => {
            context.emit("reset");
        };
        const onFixedChange = (item: any, direction: ColumnFixedType) => {
            context.emit("columnFixedChanged", item, direction);
        };
        const slots = {
            default: () => <SettingOutlined />,
            operation: (item: any) => (
                <>
                    <VerticalAlignTopOutlined
                        title="左侧锁定列"
                        class={{ left: true, active: item.fixed === "left" }}
                        onClick={() => {
                            onFixedChange(item, "left");
                        }}
                    />
                    <VerticalAlignTopOutlined
                        title="右侧锁定列"
                        class={{ right: true, active: item.fixed === "right" }}
                        onClick={() => {
                            onFixedChange(item, "right");
                        }}
                    />
                </>
            ),
        };
        const sortCondition = (dragItem: any, dropItem: any) => {
            return dragItem.fixed === dropItem.fixed;
        };
        return () => (
            <ListEditor
                headerTitle="列配置"
                items={props.columns}
                onOrderChanged={onColumnOrderChanged}
                visibleKey={props.visibleKey}
                onReset={onReset}
                sortCondition={sortCondition}
            >
                {slots}
            </ListEditor>
        );
    },
});
