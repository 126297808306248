import buttonTypes from "@intasect/ant-design-vue/es/button/buttonTypes";
import {
    computed,
    defineAsyncComponent,
    defineComponent,
    PropType,
    ref,
    watch,
} from "vue";
import "@intasect/ant-design-vue/es/button/style";
import "./style.less";

const Button = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/button")
);

const props = buttonTypes();
export type IButtonType =
    | "primary"
    | "success"
    | "warning"
    | "danger"
    | "default"
    | "link"
    | "excel";
export type IButtonSize = "large" | "small" | "default";
export default defineComponent({
    name: "i-button",
    components: {
        Button,
    },
    emits: ["click"],
    props: {
        // ...props,
        // color: String as PropType<"blue" | "yellow" | "primary">,
        type: {
            type: String as PropType<IButtonType>,
            default: "default",
        },
        size: {
            type: String as PropType<IButtonSize>,
            default: "default",
        },
        loading: Boolean,
        icon: String,
        block: Boolean,
        disabled: Boolean,
    },
    setup(props, { slots, emit }) {
        const onClick = (e: MouseEvent) => {
            emit("click", e);
        };
        const className = computed<string>(() => {
            switch (props.type) {
                case "primary":
                    return "i-button i-button-primary";

                case "warning":
                    return "i-button i-button-yellow";

                case "success":
                    return "i-button i-button-green";

                case "danger":
                    return "i-button i-button-red";

                case "excel":
                    return "i-button i-button-excel";

                default:
                    return "i-button";

            }
        });
        return () => (
            <Button
                block={props.block}
                size={props.size === "default" ? "middle" : props.size}
                type={props.type as any}
                class={className.value}
                onClick={onClick}
                disabled={props.disabled}
            >
                {slots}
            </Button>
        );
    },
});
