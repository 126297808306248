import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import "./style.less";
import {
    PropType,
    computed,
    defineComponent,
    onUnmounted,
    shallowRef,
    watch,
} from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { IDomEditor, IEditorConfig, IToolbarConfig } from "@wangeditor/editor";
import { useInjectFormItemContext } from "@intasect/ant-design-vue/es/form";

const toolbarConfig = {
    toolbarKeys: [
        "bold",
        "underline",
        "italic",
        "color",
        "bgColor",
        "|",
        "fontSize",
        "fontFamily",
        "lineHeight",
        "|",
        {
            key: "group-justify",
            title: "对齐",
            iconSvg:
                '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            menuKeys: [
                "justifyLeft",
                "justifyRight",
                "justifyCenter",
                "justifyJustify",
            ],
        },
        {
            key: "group-indent",
            title: "缩进",
            iconSvg:
                '<svg viewBox="0 0 1024 1024"><path d="M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z"></path></svg>',
            menuKeys: ["indent", "delIndent"],
        },
        "undo",
        "redo",
    ],
};

export default defineComponent({
    components: { Editor, Toolbar },
    props: {
        value: String,
        height: {
            type: Number,
            default: 250,
        },
        placeholder: {
            type: String,
            default: "请输入内容...",
        },
        toolBarConfig: {
            type: Object as PropType<Partial<IToolbarConfig>>,
            default: () => toolbarConfig,
        },
        disabled: Boolean,
    },
    setup(props, { emit }) {
        // 编辑器实例，必须用 shallowRef
        const editorRef = shallowRef();
        const ctx = useInjectFormItemContext();
        // 内容 HTML
        const valueHtml = computed({
            get() {
                return props.value;
            },
            set(val) {
                emit("update:value", val === "<p><br></p>" ? "" : val);
                ctx.onFieldChange();
            },
        });
        const editorConfig: Partial<IEditorConfig> = {
            placeholder: props.placeholder,
        };
        // 组件销毁时，也及时销毁编辑器
        onUnmounted(() => {
            if (editorRef.value) {
                editorRef.value.destroy();
            }
        });
        const handleCreated = (editor: IDomEditor) => {
            editorRef.value = editor; // 记录 editor 实例，重要
            watch(
                () => props.disabled,
                d => {
                    if (d) {
                        editorRef.value.disable();
                    } else {
                        editorRef.value.enable();
                    }
                },
                { immediate: true }
            );
        };
        return () => (
            <div class="i-rich-text">
                <Toolbar
                    class="toolbar"
                    editor={editorRef.value}
                    defaultConfig={props.toolBarConfig}
                    mode="default"
                />
                <Editor
                    class="editor"
                    style={`height:${props.height}px`}
                    v-model={valueHtml.value}
                    defaultConfig={editorConfig}
                    mode="default"
                    onOnCreated={handleCreated}
                />
            </div>
        );
    },
});
