import {
    computed,
    defineAsyncComponent,
    defineComponent,
    PropType,
    ref,
    watch,
} from "vue";
import dayjs, { Dayjs } from "dayjs";
import "@intasect/ant-design-vue/es/date-picker/style";
import "@intasect/ant-design-vue/es/time-picker/style";
import "./style.less";
import {
    DatePickerProps,
    DisableDayValue,
    DisabledDay,
    DisabledTime,
    DisableTimeValue,
    TimePickerProps,
    ValidationStatus,
} from "../Presenters/declare";
import { EventValue } from "@intasect/ant-design-vue/es/vc-picker/interface";

const DatePicker = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/date-picker")
);
const TimePicker = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/time-picker")
);
const RangePicker = defineAsyncComponent(
    async () =>
        (await import("@intasect/ant-design-vue/es/date-picker/dayjs"))
            .RangePicker
);
const TimeRangePicker = defineAsyncComponent(
    async () =>
        (await import("@intasect/ant-design-vue/es/time-picker"))
            .TimeRangePicker
);

export default defineComponent({
    name: "i-date-time",
    components: {
        DatePicker,
        RangePicker,
        TimePicker,
        TimeRangePicker,
    },
    emits: ["update:value"],
    props: {
        picker: {
            type: String as PropType<
                "date" | "month" | "year" | "week" | "time"
            >,
            default: "date",
        },
        valueType: {
            type: String as PropType<"timestamp" | "ISO8601">,
            default: "timestamp",
        },
        disabledFuture: {
            type: Boolean,
            default: false,
        },
        range: {
            type: Boolean,
            default: false,
        },
        format: {
            type: String,
        },
        valueFormat: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledTime: {
            type: Object as PropType<DisabledTime>,
        },
        disabledDate: {
            type: Object as PropType<DisabledDay>,
        },
        showTime: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number, Array] as PropType<
                string | number | [string, string] | [number, number]
            >,
        },
        status: {
            type: String as PropType<ValidationStatus>,
            default: "default",
        },
    },
    setup(props, { emit, attrs, slots }) {
        const classNames = computed(() => {
            return `i-date i-date-${props.status} ${attrs.class || ""}`;
        });
        const timeValue = computed({
            get() {
                if (props.value) {
                    if (Array.isArray(props.value)) {
                        return props.value.map(a => dayjs(a));
                    } else {
                        return [dayjs(props.value)];
                    }
                }
                return [];
            },
            set(val) {
                switch (props.valueType) {
                    case "ISO8601":
                        if (props.range && val) {
                            emit("update:value", [val[0], val[1]]);
                        } else if (!props.range && val[0]) {
                            emit("update:value", val[0]);
                        } else {
                            emit("update:value", props.range ? [] : "");
                        }

                        break;
                    case "timestamp":
                        if (props.range && val) {
                            const timeList = val.map(a => a.valueOf());
                            emit("update:value", [timeList[0], timeList[1]]);
                        } else if (!props.range && val[0]) {
                            const timeList = val.map(a => a.valueOf());
                            emit("update:value", timeList[0]);
                        } else {
                            emit("update:value", props.range ? [] : "");
                        }
                        break;
                }
            },
        });
        const timeFormat = computed(() => {
            if (props.valueType === "timestamp") {
                return undefined;
            } else {
                return props.valueFormat;
            }
        });

        const disabledTime = computed(() => {
            if (props.picker === "time" && props.disabledTime) {
                const obj = {
                    format: props.format || "HH:mm:ss",
                    disabledTime: props.disabledTime!,
                };
                return (day: EventValue<Dayjs>, type?: "start" | "end") => {
                    return {
                        disabledHours: () => disabledHours(obj, type),
                        disabledMinutes: (selectedHour: number) =>
                            disabledMinutes(obj, selectedHour, type),
                        disabledSeconds: (
                            selectedHour: number,
                            selectedMinute: any
                        ) =>
                            disabledSeconds(
                                obj,
                                selectedHour,
                                selectedMinute,
                                type
                            ),
                    };
                };
            }
            if (props.showTime && props.disabledDate) {
                return (day: EventValue<Dayjs>, type?: "start" | "end") =>
                    disabledDateTime(day, {
                        format: props.format || "YYYY/MM/DD HH:mm:ss",
                        disabledDay: props.disabledDate!,
                    });
            }
        });

        const disabledDate = computed(() => {
            if (
                (props.picker !== "time" || props.showTime) &&
                props.disabledDate
            ) {
                const obj = {
                    format:
                        props.format ||
                        (props.showTime ? `YYYY/MM/DD HH:mm:ss` : `YYYY/MM/DD`),
                    disabledDay: props.disabledDate,
                };
                return (current: Dayjs) => disabledDay(current, obj);
            }
        });

        if (props.picker === "time") {
            if (props.range) {
                return () => (
                    <TimeRangePicker
                        class={classNames.value}
                        disabled={props.disabled}
                        format={props.format}
                        value-format={timeFormat.value}
                        v-model={[timeValue.value, "value"]}
                        disabledTime={disabledTime.value}
                        v-slots={slots}
                    />
                );
            }
            const val = ref<Dayjs>(timeValue.value[0]);
            watch(
                () => val.value,
                v => {
                    timeValue.value = [v!];
                }
            );
            return () => (
                <TimePicker
                    class={classNames.value}
                    disabled={props.disabled}
                    format={props.format}
                    value-format={timeFormat.value}
                    v-model={[val.value, "value"]}
                    disabledTime={disabledTime.value}
                    v-slots={slots}
                />
            );
        }
        const showTime = ref(props.showTime);
        const onChange = (e: any) => {
            timeValue.value = [e];
        };
        if (props.range) {
            return () => (
                <RangePicker
                    class={classNames.value}
                    disabled={props.disabled}
                    show-time={showTime.value}
                    format={props.format}
                    value-format={timeFormat.value}
                    picker={props.picker}
                    v-model={[timeValue.value, "value"]}
                    disabledTime={disabledTime.value}
                    disabledDate={disabledDate.value}
                    v-slots={slots}
                />
            );
        }
        return () => (
            <DatePicker
                class={classNames.value}
                disabled={props.disabled}
                show-time={showTime.value}
                format={props.format}
                value-format={timeFormat.value}
                picker={props.picker}
                v-model={[timeValue.value[0], "value"]}
                onChange={onChange}
                disabled-date={disabledDate.value}
                v-slots={slots}
            />
        );
    },
});

const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }

    return result;
};

const getIntersection = (args: { min: number; max: number }[]) => {
    let minArr = [];
    let maxArr = [];
    for (let item of args) {
        minArr.push(item.min);
        maxArr.push(item.max);
    }
    let FMax = Math.max(...minArr);
    let Rmin = Math.min(...maxArr);
    if (FMax > Rmin) {
        return null; //无交集
    } else {
        return [FMax, Rmin]; //有交集
    }
};

const getDayTypeVal = (val: DisableDayValue, type?: "start" | "end") => {
    if (typeof val === "function") {
        //to do add type params
        return val(new Date());
    }
    return val;
};

const disabledDay = (current: Dayjs, props: DatePickerProps) => {
    if (props.disabledDay) {
        const val = getDayTypeVal(props.disabledDay.value);
        const includeMode = props.disabledDay.disabledType === "include";
        for (const item of val) {
            const startDay = dayjs(item.min ?? "1900-1-1").startOf("days");
            const endDay = dayjs(item.max ?? "2100-1-1").endOf("days");
            const max = includeMode ? endDay : startDay;
            const min = includeMode ? startDay : endDay;
            let maxPass = false;
            let minPass = false;
            if (max >= current) {
                maxPass = true;
            }
            if (min <= current) {
                minPass = true;
            }
            if (minPass && maxPass) {
                return includeMode;
            }
        }
        // if (props.disabledDay.disabledType === "exclude") {
        //     for (const item of val) {
        //         let max = dayjs(item.max).endOf("days");
        //         let min = dayjs(item.min).startOf("days");
        //         let maxPass = false;
        //         let minPass = false;
        //         if (item.max !== undefined && max >= current) {
        //             maxPass = true;
        //         }
        //         if (item.min !== undefined && min <= current) {
        //             minPass = true;
        //         }
        //         if (minPass && maxPass) {
        //             return false;
        //         }
        //     }
        //     return true;
        // } else if (props.disabledDay.disabledType === "include") {
        //     for (const item of val) {
        //         let max = dayjs(item.max).startOf("days");
        //         let min = dayjs(item.min).endOf("days");
        //         let maxPass = false;
        //         let minPass = false;
        //         if (item.max !== undefined && max >= current) {
        //             maxPass = true;
        //         }
        //         if (item.min !== undefined && min <= current) {
        //             minPass = true;
        //         }
        //         if (minPass && maxPass) {
        //             return true;
        //         }
        //     }
        //     return false;
        // }
    }
    return false;
};

const disabledDateTime = (time: any, props: DatePickerProps) => {
    //获取当前日期段
    const current = time;
    //获取当前日子的时间范围
    const strat = dayjs(current).startOf("days");
    const end = dayjs(current).endOf("day");
    const dateRange = [
        {
            min: strat.unix(),
            max: end.unix(),
        },
    ];
    const val = getDayTypeVal(props.disabledDay.value);
    //获取范围的交集 与 补集
    if (props.disabledDay) {
        for (const item of val) {
            dateRange.push({
                min: dayjs(item.min).unix(),
                max: dayjs(item.max).unix(),
            });
        }
    } else {
        return {
            disabledHours: () => [],
            disabledMinutes: () => [],
            disabledSeconds: () => [],
        };
    }
    const seRange = getIntersection(dateRange); //获取区间交集
    if (!seRange) {
        return {
            disabledHours: () => [],
            disabledMinutes: () => [],
            disabledSeconds: () => [],
        };
    }
    //根据当前日期 判断小时可选范围
    return {
        disabledHours: () => {
            if (seRange) {
                let hoursMin = 0;
                let hoursMax = 24;
                if (dayjs.unix(seRange[0]).hour() > strat.hour()) {
                    hoursMin = dayjs.unix(seRange[0]).hour();
                    if (
                        props.disabledDay &&
                        props.disabledDay.disabledType === "include"
                    ) {
                        hoursMin += 1;
                    }
                }
                if (dayjs.unix(seRange[1]).hour() < end.hour()) {
                    hoursMax = dayjs.unix(seRange[1]).hour();
                    if (
                        props.disabledDay &&
                        props.disabledDay.disabledType === "exclude"
                    ) {
                        hoursMax += 1;
                    }
                }
                if (
                    props.disabledDay &&
                    props.disabledDay.disabledType === "exclude"
                ) {
                    return [...range(0, hoursMin), ...range(hoursMax, 24)];
                }

                return range(hoursMin, hoursMax);
            }
            return [];
        },
        disabledMinutes: (hours: number) => {
            if (seRange) {
                //当前时间段分钟区间minutesMin-minutesMax
                const minutesMinDate = strat.hour(hours).minute(0).second(0);
                const minutesMaxDate = end.hour(hours).minute(59).second(0);
                let minutesMin = 0;
                let minutesMax = 60;
                let State = false;
                if (
                    dayjs.unix(seRange[0]) > minutesMinDate &&
                    dayjs.unix(seRange[0]).hour() === hours &&
                    dayjs.unix(seRange[0]).minute() > strat.minute()
                ) {
                    State = true;
                    minutesMin = dayjs.unix(seRange[0]).minute();
                    if (
                        props.disabledDay &&
                        props.disabledDay.disabledType === "include"
                    ) {
                        minutesMin += 1;
                    }
                }
                if (
                    minutesMaxDate > dayjs.unix(seRange[1]) &&
                    dayjs.unix(seRange[1]).hour() === hours &&
                    dayjs.unix(seRange[1]).minute() < end.minute()
                ) {
                    State = true;
                    minutesMax = dayjs.unix(seRange[1]).minute();
                    if (
                        props.disabledDay &&
                        props.disabledDay.disabledType === "exclude"
                    ) {
                        minutesMax += 1;
                    }
                }
                if (
                    props.disabledDay &&
                    props.disabledDay.disabledType === "exclude"
                ) {
                    return [...range(0, minutesMin), ...range(minutesMax, 60)];
                }
                if (!State) {
                    return [];
                }
                return range(minutesMin, minutesMax);
            }
            return [];
        },
        disabledSeconds: (hours: number, minute: any) => {
            if (seRange) {
                //当前时间段分钟区间minutesMin-minutesMax
                const secondMinDate = strat
                    .hour(hours)
                    .minute(minute.value)
                    .second(0);
                const secondMaxDate = end
                    .hour(hours)
                    .minute(minute.value)
                    .second(59);
                //范围最大值分钟小于选择时间 部分可选
                let secondMin = 0;
                let secondMax = 60;
                let State = false;
                if (
                    dayjs.unix(seRange[0]) > secondMinDate &&
                    dayjs.unix(seRange[0]).hour() === hours &&
                    dayjs.unix(seRange[0]).minute() === minute.value &&
                    dayjs.unix(seRange[0]).second() > strat.second()
                ) {
                    State = true;
                    secondMin = dayjs.unix(seRange[0]).second();
                    if (
                        props.disabledDay &&
                        props.disabledDay.disabledType === "include"
                    ) {
                        secondMin += 1;
                    }
                }
                if (
                    secondMaxDate > dayjs.unix(seRange[1]) &&
                    dayjs.unix(seRange[1]).hour() === hours &&
                    dayjs.unix(seRange[1]).minute() === minute.value &&
                    dayjs.unix(seRange[1]).second() < end.second()
                ) {
                    State = true;
                    secondMax = dayjs.unix(seRange[1]).second();
                    if (
                        props.disabledDay &&
                        props.disabledDay.disabledType === "exclude"
                    ) {
                        secondMax += 1;
                    }
                }
                if (!State) {
                    return [];
                }
                if (
                    props.disabledDay &&
                    props.disabledDay.disabledType === "exclude"
                ) {
                    return [...range(0, secondMin), ...range(secondMax, 60)];
                }
                return range(secondMin, secondMax);
            }
            return [];
        },
    };
};

const getTimeTypeVal = (val: DisableTimeValue, type?: "start" | "end") => {
    if (typeof val === "function") {
        return val(new Date(), type);
    }
    return val;
};

const disabledHours = (props: TimePickerProps, type?: "start" | "end") => {
    const val = getTimeTypeVal(props.disabledTime.value, type);
    let min: number = Number(val.min?.hour);
    let max: number = Number(val.max?.hour);

    if (props.disabledTime.disabledType === "include") {
        max += 1;
        return [...range(0, min), ...range(max, 24)];
    }
    min += 1;
    return range(min, max);
};

const disabledMinutes = (
    props: TimePickerProps,
    hour: number,
    type?: "start" | "end"
) => {
    const val = getTimeTypeVal(props.disabledTime.value, type);
    const minHour: number = Number(val.min?.hour);
    const maxHour: number = Number(val.max?.hour);
    let State = false;
    let min = 0;
    let max = 60;
    if (minHour === hour) {
        State = true;
        min = Number(val.min?.minute);
        if (props.disabledTime.disabledType === "exclude") {
            min += 1;
        }
    }
    if (maxHour === hour) {
        State = true;
        max = Number(val.max?.minute);
        if (props.disabledTime.disabledType === "include") {
            max += 1;
        }
    }
    if (!State) {
        return [];
    }
    if (props.disabledTime.disabledType === "include") {
        return [...range(0, min), ...range(max, 60)];
    }
    return range(min, max);
};

const disabledSeconds = (
    props: TimePickerProps,
    hour: number,
    minute: any,
    type?: "start" | "end"
) => {
    const val = getTimeTypeVal(props.disabledTime.value, type);
    const minHour: number = Number(val.min?.hour);
    const maxHour: number = Number(val.max?.hour);
    const minMinute: number = Number(val.min?.minute);
    const maxMinute: number = Number(val.max?.minute);
    let State = false;
    let min = 0;
    let max = 60;
    if (minHour === hour && minMinute === minute.value) {
        State = true;
        min = Number(val.min?.second);
        if (props.disabledTime.disabledType === "exclude") {
            min += 1;
        }
    }
    if (maxHour === hour && maxMinute === minute.value) {
        State = true;
        max = Number(val.max?.second);
        if (props.disabledTime.disabledType === "include") {
            max += 1;
        }
    }
    if (!State) {
        return [];
    }
    if (props.disabledTime.disabledType === "include") {
        return [...range(0, min), ...range(max, 60)];
    }
    return range(min, max);
};
