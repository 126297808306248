import {
    defineAsyncComponent,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref,
    VNodeTypes,
    watch,
} from "vue";

import { SplitCellsOutlined } from "@ant-design/icons-vue";
import Sortable from "sortablejs";
import "./style.less";
import type { CheckboxChangeEvent } from "@intasect/ant-design-vue/es/checkbox/interface";
import '@intasect/ant-design-vue/es/checkbox/style'
import '@intasect/ant-design-vue/es/popover/style'

const Checkbox = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/checkbox'))
const Popover = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/popover'))

type DragItem = { newIndex: number; oldIndex: number; item: HTMLElement };
type SelectStatusType = { indeterminate: boolean; checkAll: boolean };
const getSelectStatus = (items: any, visibleKey: string): SelectStatusType => {
    let hasSelected = false;
    let hasNotSelected = false;
    let checkAll = false;
    for (const item of items) {
        if (item[visibleKey]) {
            hasSelected = true;
        } else {
            hasNotSelected = true;
        }
    }
    if (hasSelected && !hasNotSelected) {
        checkAll = true;
    }
    //当 indeterminate 为true时 无论checkAll值是什么，ui显示半选中
    //当 indeterminate 为false时 跟据checkAll显示 全选中、全不选中
    return { indeterminate: hasSelected && hasNotSelected, checkAll };
};

export const ListEditor = defineComponent({
    components: {
        Popover,
    },
    emits: ["visibleChanged", "orderChanged", "reset"],
    props: {
        //头部标题
        headerTitle: {
            type: String,
            default: "名称",
        },
        //列表项
        items: {
            type: Array as PropType<Array<any>>,
            default: () => [],
        },
        //value的key值
        itemKey: {
            type: String,
            default: "key",
        },
        //控制显示的key值
        visibleKey: {
            type: String,
            default: "visible",
        },
        //选项的标题
        itemTitle: {
            type: [String, Function] as PropType<
                string | ((item: any) => VNodeTypes)
            >,
            default: "title",
        },
        //排序的条件
        sortCondition: {
            type: Function as PropType<
                (dargItem: any, dropItem: any) => boolean
            >,
            default: () => true,
        },
    },
    setup(props, context) {
        const headerProps = reactive(
            getSelectStatus(props.items, props.visibleKey)
        );
        const sorterRef = ref();
        const checkAllChanged = (e: CheckboxChangeEvent) => {
            sorterRef.value.setAllVisible(e.target.checked);
            context.emit("visibleChanged", [props.items]);
        };
        const handleReset = () => {
            context.emit("reset");
        };
        const onVisibleChanged = (items: any[]) => (checked: boolean) => {
            context.emit("visibleChanged", checked, items);
        };

        const onOrderChanged = (oldIndex: number, newIndex: number) => {
            context.emit("orderChanged", oldIndex, newIndex);
        };

        const onSelectAllChanged = (result: SelectStatusType) => {
            headerProps.checkAll = result.checkAll;
            headerProps.indeterminate = result.indeterminate;
        };

        const visibleKeysMap = ref<{ [key: string]: boolean }>({});

        watch(
            () => props.items,
            newItems => {
                const newMap: { [key: string]: boolean } = {};
                newItems?.forEach(item => {
                    newMap[item[props.itemKey] as string] =
                        item[props.visibleKey];
                });
                visibleKeysMap.value = newMap;
            },
            { immediate: true }
        );
        const slots = {
            content: () => (
                <SorterList
                    items={props.items}
                    itemKey={props.itemKey}
                    itemTitle={props.itemTitle}
                    visibleKey={props.visibleKey}
                    onSelectChanged={onVisibleChanged}
                    onOrderChanged={onOrderChanged}
                    onSelectAllChanged={onSelectAllChanged}
                    checkStatus={headerProps}
                    sortCondition={props.sortCondition}
                    ref={sorterRef}
                >
                    {{ operation: context.slots.operation }}
                </SorterList>
            ),
            title: () => (
                <div class="list-editor-header">
                    <div class="selector">
                        <Checkbox
                            v-model={[headerProps.checkAll, "checked"]}
                            indeterminate={headerProps.indeterminate}
                            onChange={checkAllChanged}
                        />
                    </div>
                    <div class="title">{props.headerTitle}</div>
                    <div class="reset">
                        <span onClick={handleReset}>重置</span>
                    </div>
                </div>
            ),
            default: context.slots.default,
        };

        return () => (
            <Popover
                overlayClassName="list-editor-popver"
                v-slots={slots}
                placement="bottomRight"
            ></Popover>
        );
    },
});

const SorterList = defineComponent({
    emits: ["selectChanged", "orderChanged", "selectAllChanged"],
    components: {
        SplitCellsOutlined,
    },
    props: {
        items: {
            type: Array as PropType<Array<any>>,
            default: () => [],
        },
        itemKey: {
            type: String,
            default: "key",
        },
        itemTitle: {
            type: [String, Function] as PropType<
                string | ((item: any) => VNodeTypes)
            >,
            default: "title",
        },
        visibleKey: {
            type: String,
            default: "visible",
        },
        checkStatus: {
            type: Object as PropType<SelectStatusType>,
            required: true,
        },
        sortCondition: {
            type: Function as PropType<
                (dargItem: any, dropItem: any) => boolean
            >,
            default: () => true,
        },
    },
    setup(props, context) {
        const listRef = ref<HTMLElement>();
        const visibleKeysMap = ref<{ [key: string]: boolean }>({});
        const onSelectChange = (item: any) => (e: CheckboxChangeEvent) => {
            const val = (e.target as any).checked as boolean;
            item[props.visibleKey] = val;
            context.emit("selectChanged", val, [item]);
            checkSelectAll();
        };
        const checkSelectAll = () => {
            const result = getSelectStatus(props.items, props.visibleKey);
            if (
                result.checkAll !== props.checkStatus.checkAll ||
                result.indeterminate !== props.checkStatus.indeterminate
            ) {
                context.emit("selectAllChanged", result);
            }
        };
        const renderItemTitle = (
            item: any,
            title: string | ((i: any) => VNodeTypes)
        ) => {
            if (typeof title === "string") {
                return () => item[title];
            } else {
                return () => title(item);
            }
        };

        watch(
            () => props.items,
            newItems => {
                const newMap: { [key: string]: boolean } = {};
                newItems?.forEach(item => {
                    newMap[item[props.itemKey] as string] =
                        item[props.visibleKey];
                });
                visibleKeysMap.value = newMap;
                checkSelectAll();
            },
            { immediate: true }
        );

        const setAllVisible = (selected: boolean) => {
            props.items.forEach(item => {
                item[props.visibleKey] = selected;
                visibleKeysMap.value[item[props.itemKey] as string] = selected;
            });
            context.emit("selectChanged", selected, props.items);
            checkSelectAll();
        };

        context.expose({
            setAllVisible,
        });

        onMounted(() => {
            //@ts-ignore
            new Sortable(listRef.value, {
                handle: ".sorter",
                animation: 150,
                // ghostClass: "",
                // group: "",
                sort: true,
                onEnd({ newIndex, oldIndex }: DragItem) {
                    context.emit("orderChanged", oldIndex, newIndex);
                },
                onMove(item: any) {
                    const dragIndex = Array.prototype.indexOf.call(
                        item.dragged.parentElement.children,
                        item.dragged
                    );
                    const dropIndex = Array.prototype.indexOf.call(
                        item.dragged.parentElement.children,
                        item.related
                    );
                    const dragItem = props.items[dragIndex];
                    const dropItem = props.items[dropIndex];
                    return props.sortCondition(dragItem, dropItem);
                },
            });
        });

        return () => (
            <div ref={listRef}>
                {props.items?.map(item => {
                    return (
                        <div class="list-editor-item" key={item[props.itemKey]}>
                            <div class="selector">
                                <Checkbox
                                    v-model={[
                                        visibleKeysMap.value[
                                        item[props.itemKey]
                                        ],
                                        "checked",
                                    ]}
                                    onChange={onSelectChange(item)}
                                />
                            </div>
                            <div class="sorter" title="调整顺序">
                                <SplitCellsOutlined />
                            </div>
                            <div class="title">
                                {{
                                    default: renderItemTitle(
                                        item,
                                        props.itemTitle
                                    ),
                                }}
                            </div>
                            <div class="operation">
                                {{
                                    default: () =>
                                        context.slots.operation &&
                                        context.slots.operation(item),
                                }}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    },
});
