import type {
    ColumnGroupType,
    ColumnType,
    TablePaginationConfig,
} from "@intasect/ant-design-vue/es/table";
import { tableProps } from "@intasect/ant-design-vue/es/table";
import {
    computed,
    ComputedRef,
    defineAsyncComponent,
    defineComponent,
    h,
    isReactive,
    onMounted,
    onUnmounted,
    PropType,
    Ref,
    ref,
    SetupContext,
    toRaw,
    toRef,
    toRefs,
    VNode,
    VNodeTypes,
    watch,
    WatchStopHandle,
} from "vue";
import { TitleCell } from "./TitleCell";
import { ColumnFixedType, ColumnsEditor, visibleKey } from "./ColumnEidtor";

import "./style.less";
import type {
    AdditionalProps,
    GetRowKey,
    Key,
} from "@intasect/ant-design-vue/es/vc-table/interface";
import { devConsole, uuid } from "@intasect/platform-core";
import {
    FilterValue,
    SorterResult,
    TableCurrentDataSource,
    TableRowSelection,
    TransformCellTextProps,
} from "@intasect/ant-design-vue/es/table/interface";

import Sortable from "sortablejs";
import type { RowSelectionPositionType } from "../Presenters/declare";

export const antTableProps = tableProps();
const enhancedKey = Symbol("__enhanced");

const parent = Symbol("parent");
export const selectionColumnKey = Symbol("selection"); //选择列的key

export type CellColorStyle =
    | "normal"
    | "primary"
    | "success"
    | "warn"
    | "error";

export type ITableCommonColumnType = {
    cellStyle?: {
        backColor?: CellColorStyle | ((record: any) => CellColorStyle);
        fontColor?: CellColorStyle | ((record: any) => CellColorStyle);
    };
};

type EnhanceType = {
    [enhancedKey]?: boolean;
    colSpanTarget?: EnhanceColType;
    [visibleKey]?: boolean;
    titleTip?: (record: any) => string;
    key: string;
    dataIndex?: string;
} & ITableCommonColumnType;
export type EnhanceColType =
    | (ColumnGroupType<any> & EnhanceType)
    | (ColumnType<any> & EnhanceType);

export type RowspanKeyResolveType = (
    fieldName: string | symbol
) => ((record: any) => string) | undefined;
import "@intasect/ant-design-vue/es/table/style";
const Table = defineAsyncComponent(
    () => import("@intasect/ant-design-vue/es/table")
);

export type ITableColumnType<RecordType = any> = Array<
    (ColumnGroupType<RecordType> | ColumnType<RecordType>) &
        ITableCommonColumnType
>;

export type ColSpanItemType = {
    list: string[];
    resolve: (record: any) => boolean;
};

export default defineComponent({
    name: "i-table",
    emits: ["update:columns", "update:dataSource", "clickRow"],
    components: {
        Table,
        ColumnsEditor,
    },
    props: {
        ...antTableProps,
        //是否启用行点击选中
        selectWhenClick: { type: Boolean, default: true },
        //行选中是否可用的判断函数
        rowSelectDisabledCheck: {
            type: Function as PropType<(record: any) => boolean>,
            default: () => false,
        },
        //默认列宽
        colDefaultWidth: { type: Number, default: 200 },
        //是否使用自动行列滚动
        autoScroll: { type: Boolean, default: false },
        //是否使用列配置
        showColumnEditor: { type: Boolean, default: true },
        //横向合并的字段
        colSpanItems: {
            type: Array as PropType<ColSpanItemType[]>,
            default: () => [],
        },
        //纵向合并的字段
        rowSpanItems: {
            type: Array as PropType<(string | symbol)[]>,
            default: () => [],
        },
        //识别列合并的函数，搭配rowSpanItems使用，如果rowSpanKeyResolve为空，则每个单元格根据自己的值和上一行值是否相同进行判断
        rowSpanKeyResolve: {
            type: Function as PropType<RowspanKeyResolveType>,
            default: undefined,
        },
        //是否开启拖拽排序 基于sortablejs
        dragSort: {
            type: Boolean,
            default: false,
        },
        //sortablejs的配置
        dragSortOptions: {
            type: Object as PropType<Sortable.Options>,
            default: () => {},
        },
        //树形数据的父级id字段
        parentKey: {
            type: String,
            default: "parentId",
        },
        //是否显示选行信息
        showRowSelectInfo: {
            type: Boolean,
            default: false,
        },
        //选行信息渲染函数
        rowSelectInforRender: {
            type: Function as PropType<(count: number) => VNodeTypes>,
            default: (count: number) => (
                <>
                    已选择<span class="count">{count}</span>项
                </>
            ),
        },
        //选行信息显示位置
        rowSelectionPosition: {
            type: String as PropType<RowSelectionPositionType>,
            default: "left first",
        },
        //是否显示边框容器
        withContainer: {
            type: Boolean,
            default: false,
        },
        transformCellText: {
            type: Function,
            default: (column: TransformCellTextProps) => {
                if (column.text === 0 || column.text === "0") {
                    return "0";
                }
                return column.text ? column.text : "-";
            },
        },
        columns: {
            type: Array as PropType<ITableColumnType>,
        },
    },
    setup(props, context) {
        const currentDataSource = computed(() => {
            return props.dataSource || [];
        });

        const handleResizeColumn = (w: number, col: any) => {
            col.width = w;
        };
        const rowKey = computed(() => {
            return props.rowKey || "key";
        });

        const childrenKey = computed(() => {
            return props.childrenColumnName || "children";
        });

        const parentKey = computed(() => {
            return props.parentKey;
        });

        const expandRecords = ref<any[]>([]);

        const {
            findPrevRecord,
            findNextRecord,
            findParentRecord,
            findAllRecords,
            findRootRecord,
            sortRecord,
            expandMode,
        } = useFindRecord(
            currentDataSource,
            rowKey as ComputedRef<string>,
            childrenKey,
            parentKey,
            expandRecords
        );
        const {
            columns,
            colSpanItems,
            rowSpanItems,
            pagination: oriPagination,
            autoScroll,
            colDefaultWidth,
            selectWhenClick,
        } = toRefs(props);

        const table = ref();

        const rowSpanKeyResolve = toRef(props, "rowSpanKeyResolve");

        const rowSelection = ref<TableRowSelection<any>>();

        const findAllRows = (rows: Array<Record<string, any>>) => {
            const list: Key[] = [];
            const records: any[] = [];
            rows.forEach(row => {
                records.push(row);
                list.push(row[rowKey.value as string]);
                if (Array.isArray(row[childrenKey.value])) {
                    const [childList, childRecords] = findAllRows(
                        row[childrenKey.value]
                    );
                    list.push(...childList);
                    records.push(...childRecords);
                }
            });
            return [Array.from(new Set(list)), Array.from(new Set(records))];
        };

        const combineRowSelection = computed(() => {
            return props.rowSpanItems.includes(selectionColumnKey);
        });

        const selectRowParams = ref<{
            record: any;
            selected: boolean;
            selectedRows: any[];
            e: Event;
        }>();
        const currentRows = ref<any>([]);
        const selectRowCount = computed(() => {
            if (props.rowSelection) {
                return (props.rowSelection.selectedRowKeys ?? []).length;
            }
            return (currentRows.value.length = 0);
        });
        watch(
            () => props.dataSource,
            () => {
                currentRows.value.length = 0;
            }
        );

        const rowSpanCellMap: { [id: string]: Set<any> } = {};

        const rowSpanCellParentMap: { [id: string]: string } = {};

        const addRowSpanMap = (
            parentKey: string,
            parent: any,
            childKey: string,
            child: any
        ) => {
            if (!Array.isArray(rowSpanCellMap[parentKey])) {
                rowSpanCellMap[parentKey] = new Set([parent]);
                rowSpanCellParentMap[parentKey] = parentKey;
            }
            rowSpanCellMap[parentKey].add(child);
            rowSpanCellParentMap[childKey] = parentKey;
        };

        const selectedRowKeysWatcher = ref<WatchStopHandle>();

        watch(
            () => ({
                rowSpanItem: props.rowSpanItems,
                rowSelection: props.rowSelection,
            }),
            () => {
                if (props.rowSelection) {
                    rowSelection.value = {
                        ...props.rowSelection,
                        //@ts-ignore
                        customCell: combineRowSelection.value
                            ? useRowSpanCustomCell(
                                  selectionColumnKey,
                                  findPrevRecord,
                                  findNextRecord,
                                  rowSpanKeyResolve,
                                  addRowSpanMap,
                                  rowKey as ComputedRef<string>
                              )
                            : undefined,
                        onChange: (
                            changedRowKeys: Key[],
                            changedRows: any[]
                        ) => {
                            if (props.rowSelection?.onChange) {
                                // 这部分逻辑会导致onChange变成上一次的结果来触发
                                // if (selectRowParams.value) {
                                //     props.rowSelection?.onChange(
                                //         selectRowParams.value.selectedRows
                                //             .filter(a => a) //id不匹配时会出现undefined的row
                                //             .map(
                                //                 r => r[rowKey.value as string]
                                //             ),
                                //         selectRowParams.value.selectedRows
                                //     );
                                //     currentRows.value =
                                //         selectRowParams.value.selectedRows;
                                //     selectRowParams.value = undefined;
                                // } else {
                                props.rowSelection.onChange(
                                    changedRowKeys,
                                    changedRows
                                );
                                currentRows.value = changedRows;
                                // }
                            }
                        },
                        onSelect: (
                            record: any,
                            selected: boolean,
                            selectedRows: any[],
                            e: Event
                        ) => {
                            if (record) {
                                let rows = selectedRows;
                                const rootRecord = findRootRecord(record);
                                if (
                                    rowSpanCellParentMap[
                                        rootRecord[rowKey.value as string]
                                    ]
                                ) {
                                    rows = selected
                                        ? [
                                              ...rows,
                                              ...rowSpanCellMap[
                                                  rowSpanCellParentMap[
                                                      rootRecord[
                                                          rowKey.value as string
                                                      ]
                                                  ]
                                              ],
                                          ]
                                        : rows.filter(
                                              r =>
                                                  !rowSpanCellMap[
                                                      rowSpanCellParentMap[
                                                          rootRecord[
                                                              rowKey.value as string
                                                          ]
                                                      ]
                                                  ].has(r)
                                          );

                                    rows = Array.from(new Set(rows));
                                }
                                if (combineRowSelection.value) {
                                    if (selected) {
                                        rows = findAllRows(rows)[1];
                                    } else {
                                        const rootRecord =
                                            findRootRecord(record);
                                        let excludeRecords: Array<
                                            Record<string, any>
                                        > = [];
                                        if (
                                            rowSpanCellParentMap[
                                                record[rowKey.value as string]
                                            ]
                                        ) {
                                            excludeRecords = findAllRows(
                                                Array.from(
                                                    rowSpanCellMap[
                                                        rowSpanCellParentMap[
                                                            record[
                                                                rowKey.value as string
                                                            ]
                                                        ]
                                                    ]
                                                )
                                            )[1];
                                        } else {
                                            excludeRecords = findAllRows([
                                                findRootRecord(record),
                                            ])[1];
                                        }
                                        rows = rows.filter(
                                            r => !excludeRecords.includes(r)
                                        );
                                    }
                                }
                                selectRowParams.value = {
                                    record,
                                    selected,
                                    selectedRows: rows,
                                    e,
                                };
                                if (props.rowSelection?.onSelect) {
                                    props.rowSelection.onSelect(
                                        record,
                                        selected,
                                        rows,
                                        e
                                    );
                                }
                            }
                        },
                    };
                    if (isReactive(props.rowSelection)) {
                        if (selectedRowKeysWatcher.value) {
                            selectedRowKeysWatcher.value();
                        }
                        selectedRowKeysWatcher.value = watch(
                            () => props.rowSelection?.selectedRowKeys,
                            v => {
                                rowSelection.value!.selectedRowKeys = v;
                            }
                        );
                    }
                } else {
                    rowSelection.value = undefined;
                }
            },
            { immediate: true }
        );

        // watch(
        //     () => props.rowSelection?.selectedRowKeys,
        //     values => {
        //         if (rowSelection.value) {
        //             rowSelection.value.selectedRowKeys = values;
        //             if (table.value) {
        //                 table.value.table.triggerMultipleSelection(
        //                     values
        //                 );
        //             }
        //         }
        //     },
        //     { immediate: true }
        // );

        const visibleColumns = useEnhancedColumns(
            columns,
            colSpanItems,
            rowSpanItems,
            findPrevRecord,
            findNextRecord,
            rowSpanKeyResolve,
            addRowSpanMap,
            rowKey as ComputedRef<string>
        );
        const pagination = useDefaultPagination(oriPagination);
        const scroll = useAutoScroll(
            rowSelection,
            colDefaultWidth,
            visibleColumns,
            autoScroll
        );
        const rowSelectCustomRow = useRowSelectCutomRow(
            selectWhenClick,
            rowKey,
            rowSelection,
            currentDataSource,
            props.rowSelectDisabledCheck,
            (record: Record<string, any>) => {
                const parentRecord = findParentRecord(record);
                if (parentRecord) {
                    return findParentRecord(record)[childrenKey.value];
                } else {
                    return currentDataSource.value;
                }
            },
            findAllRecords,
            findRootRecord,
            expandMode,
            combineRowSelection,
            context as any,
            table
        );

        context.expose({
            table,
        });

        const tbodyRef: Ref<HTMLTableSectionElement | undefined> = ref();

        const onSortChanged = (
            newSource: Record<string, any>[],
            dragRecord: any,
            desRecord: any
        ) => {
            context.emit("update:dataSource", newSource, dragRecord, desRecord);
        };

        useTableRowSort(
            props.dragSortOptions,
            tbodyRef,
            toRef(props, "dragSort"),
            sortRecord,
            onSortChanged
        );

        const components = computed(() => {
            let c = props.components;
            if (c) {
                //@ts-ignore
                if (c.body?.wrapper) {
                    const oldWrapper: (
                        props: any,
                        context: SetupContext
                    ) => VNode =
                        //@ts-ignore
                        c.body.wrapper;
                    //@ts-ignore
                    c.body.wrapper = (props: any, context: SetupContext) => {
                        const r = oldWrapper(props, context);
                        if (r.ref) {
                            //@ts-ignore
                            tbodyRef = r.ref;
                        } else {
                            //@ts-ignore
                            r.ref = tbodyRef;
                        }
                    };
                } else {
                    //@ts-ignore
                    c.body.wrapper = buildBodyWrapper(tbodyRef);
                }
            } else {
                c = {
                    body: {
                        wrapper: buildBodyWrapper(tbodyRef),
                    },
                };
            }
            //@ts-ignore
            c.body!.cell = TitleCell;
            return c;
        });

        const renderTable = function () {
            const { actionBarLeft, actionBarRight, header, ...tableSlots } =
                context.slots;
            return h(
                //@ts-ignore
                Table,
                {
                    ...props,
                    ...context.attrs,
                    rowSelection: rowSelection.value,
                    columns: visibleColumns.value,
                    bordered: true,
                    scroll: props.scroll || scroll.value,
                    pagination: pagination.value,
                    ref: table,
                    customRow: (record: Record<string, any>, index: number) => {
                        const rowSelectClick = rowSelectCustomRow(
                            record,
                            index
                        );
                        if (props.customRow) {
                            const obj = props.customRow(record, index);
                            const oldClick = obj.onClick;
                            obj.onClick = (e: MouseEvent) => {
                                if (oldClick) {
                                    oldClick(e);
                                }
                                rowSelectClick.onClick(e);
                            };
                            return obj;
                        }
                        return rowSelectClick;
                    },
                    components: components.value,
                    onResizeColumn: (w: number, col: any) => {
                        if (props.onResizeColumn) {
                            props.onResizeColumn(w, col);
                        }
                        handleResizeColumn(w, col);
                    },
                    onChange: (
                        pagination: TablePaginationConfig,
                        filters: Record<string, FilterValue | null>,
                        sorter: SorterResult | SorterResult[],
                        extra: TableCurrentDataSource
                    ) => {
                        if (props.onChange) {
                            props.onChange(pagination, filters, sorter, extra);
                        }
                    },
                    onExpand: (expanded: boolean, record: any) => {
                        if (props.onExpand) {
                            props.onExpand(expanded, record);
                        }
                        if (expanded && !expandRecords.value.includes(record)) {
                            expandRecords.value.push(record);
                        } else if (expandRecords.value.includes(record)) {
                            expandRecords.value.splice(
                                expandRecords.value.indexOf(record),
                                1
                            );
                        }
                    },
                },
                tableSlots
            );
        };

        const renderActionBarLeft = computed(() => {
            if (context.slots.actionBarLeft) {
                return h(
                    "div",
                    { class: "action-bar-left" },
                    { default: context.slots.actionBarLeft }
                );
            }
        });
        const renderHeader = () => {
            return h(
                "div",
                { class: "action-header" },
                { default: context.slots.header }
            );
        };
        const renderActionBarRight = computed(() => {
            if (context.slots.actionBarRight) {
                return h(
                    "div",
                    { class: "action-bar-right" },
                    { default: context.slots.actionBarRight }
                );
            }
        });

        const rowSelectInfoClass = computed(() => {
            switch (props.rowSelectionPosition) {
                default:
                case "left first":
                    return "order:0;padding-right:24px";
                case "right first":
                    return "order:4;padding-left:8px";
                case "left last":
                    return "order:2;padding-left:24px;flex:99";
                case "right last":
                    return "order:8;padding-left:8px";
            }
        });

        const renderRowSelectInfo = () => {
            if (props.showRowSelectInfo) {
                return (
                    <div
                        class="row-select-info"
                        style={rowSelectInfoClass.value}
                    >
                        {props.rowSelectInforRender(selectRowCount.value)}
                    </div>
                );
            }
            return null;
        };

        const onColumnOrderChanged = (newColumns: any[]) => {
            context.emit("update:columns", newColumns);
            // context.emit("columnsOrderChanged", newColumns);
        };

        const onColumnFixedChanged = (
            item: EnhanceColType,
            direction: ColumnFixedType
        ) => {
            if (item.fixed === direction) {
                item.fixed = undefined;
            } else {
                item.fixed = direction;
            }
            const oldColumns = (props.columns || []) as EnhanceColType[];
            const colSpanTarget = getColSpanTarget(item);
            if (colSpanTarget && colSpanTarget.colSpan) {
                colSpanTarget.fixed = item.fixed;
                const oldIndex = oldColumns.indexOf(colSpanTarget);
                for (let i = 1; i < colSpanTarget.colSpan; i++) {
                    oldColumns[oldIndex + i].fixed = item.fixed;
                }
            }
            const leftFixedColumns = oldColumns.filter(a => a.fixed === "left");
            const normalColumns = oldColumns.filter(
                a => a.fixed !== "left" && a.fixed !== "right"
            );
            const rightFixedColumns = oldColumns.filter(
                a => a.fixed === "right"
            );
            const newColumns = [
                ...leftFixedColumns,
                ...normalColumns,
                ...rightFixedColumns,
            ];
            context.emit("update:columns", newColumns);
            // context.emit("columnsFixedChanged", newColumns);
        };
        const onReset = () => {
            devConsole("itable onreset");
        };
        const renderColumnsSetting = () => {
            if (props.showColumnEditor) {
                return (
                    <>
                        <div class="seprator"></div>
                        <div class="setting">
                            <ColumnsEditor
                                columns={props.columns as EnhanceColType[]}
                                onColumnOrderChanged={onColumnOrderChanged}
                                onColumnFixedChanged={onColumnFixedChanged}
                                onReset={onReset}
                            />
                        </div>
                    </>
                );
            }
            return null;
        };

        const actionBarChildren = computed(() => {
            return [
                renderRowSelectInfo(),
                renderActionBarLeft.value,
                renderActionBarRight.value,
                renderColumnsSetting(),
            ];
        });

        const renderActionBarChildren = () => actionBarChildren.value;

        const renderActionBar = () => {
            if (
                context.slots.actionBarLeft ||
                context.slots.actionBarRight ||
                props.showRowSelectInfo ||
                props.showColumnEditor
            ) {
                return h(
                    "div",
                    { class: "action-bar" },
                    { default: renderActionBarChildren }
                );
            } else {
                return null;
            }
        };

        const renderChildren = () => [
            renderHeader(),
            renderActionBar(),
            renderTable(),
        ];

        const className = computed(() => {
            return props.withContainer ? "i-table container" : "i-table";
        });

        return () => (
            <div class={className.value}>
                {{
                    default: renderChildren,
                }}
            </div>
        );
    },
});

export const getColSpanTarget = (column: EnhanceColType) => {
    if (typeof column.colSpan === "number" && column.colSpan > 0) {
        return column;
    } else if (column.colSpanTarget) {
        return column.colSpanTarget;
    }
    return null;
};

const colSpanCheck = (key: string, colSpanItems: Ref<ColSpanItemType[]>) => {
    return colSpanItems.value.filter(a => {
        return a.list.includes(key);
    });
};

const useFindRecord = (
    currentDataSource: ComputedRef<any[]>,
    rowKey: ComputedRef<string>,
    childrenKey: ComputedRef<string>,
    parentKey: ComputedRef<string>,
    expandRecords: Ref<any[]>
) => {
    let recordParentMap: Record<string, any> = {};
    const expandMode = ref(false);
    watch(
        () => currentDataSource.value,
        value => {
            recordParentMap = {};
            buildChildMap(
                value,
                recordParentMap,
                childrenKey.value,
                rowKey.value as string
            );
            for (const item of value) {
                if (Array.isArray(item[childrenKey.value])) {
                    expandMode.value = true;
                    break;
                }
            }
        },
        { immediate: true }
    );

    //寻找第一个child
    const findFirstChild = (
        record: Record<string, any>,
        current?: Record<string, any>
    ): Record<string, any> => {
        if (
            expandRecords.value.includes(record) &&
            record[childrenKey.value] &&
            record[childrenKey.value].length > 0 &&
            record === current
        ) {
            return findFirstChild(record[childrenKey.value][0], current);
        }
        return record as Record<string, any>;
    };

    //寻找最后一个child
    const findLastChild = (
        record: Record<string, any>
    ): Record<string, any> => {
        if (
            expandRecords.value.includes(record) &&
            record[childrenKey.value] &&
            record[childrenKey.value].length > 0
        ) {
            return findLastChild(
                record[childrenKey.value][record[childrenKey.value].length - 1]
            );
        }
        return record as Record<string, any>;
    };

    const findPrevRecord = (
        record: Record<string, any>
    ): Record<string, any> | undefined => {
        if (
            expandMode.value &&
            record[rowKey.value as string] &&
            recordParentMap[record[rowKey.value as string]]
        ) {
            const parentRecord =
                recordParentMap[record[rowKey.value as string]];
            const index = parentRecord[childrenKey.value].indexOf(record);
            if (index > 0) {
                return findLastChild(
                    parentRecord[childrenKey.value][index - 1]
                ) as Record<string, any>;
            } else {
                return parentRecord;
            }
        } else {
            const index = currentDataSource.value.indexOf(record);
            return currentDataSource.value[index - 1];
        }
    };

    const findNextRecord = (
        record: Record<string, any>
    ): Record<string, any> | undefined => {
        if (
            expandRecords.value.includes(record) &&
            Array.isArray(record[childrenKey.value])
        ) {
            return findFirstChild(record, record);
        }
        if (
            expandMode.value &&
            record[rowKey.value as string] &&
            recordParentMap[record[rowKey.value as string]]
        ) {
            const parentRecord =
                recordParentMap[record[rowKey.value as string]];
            const index = parentRecord[childrenKey.value].indexOf(record);
            if (index < parentRecord[childrenKey.value].length - 1) {
                return findFirstChild(
                    parentRecord[childrenKey.value][index + 1]
                ) as Record<string, any>;
            } else {
                const valibleParent = findNotLastParentRecord(record);
                return findFirstChild(valibleParent);
            }
        } else {
            const index = currentDataSource.value.indexOf(record);
            return currentDataSource.value[index + 1];
        }
    };

    const findNotLastParentRecord = (
        record: Record<string, any>
    ): Record<string, any> => {
        const parent = findParentRecord(record);
        const index = parent[childrenKey.value].indexOf(record);
        if (index === parent[childrenKey.value].length - 1) {
            return findNotLastParentRecord(parent);
        } else {
            return parent[childrenKey.value][index + 1] as Record<string, any>;
        }
    };

    const findParentRecord = (record: Record<string, any>) => {
        return (
            recordParentMap[record[rowKey.value]] || {
                [childrenKey.value]: currentDataSource.value || [],
                [parentKey.value]: record[parentKey.value],
            }
        );
    };

    const findAllRecords = (selectedRowKeys: string[], rowKey: string) => {
        if (expandMode.value) {
            const records: any[] = [];
            const findRecords = (
                record: Record<string, any>,
                selectedRowKeys: string[],
                rowKey: string
            ) => {
                if (selectedRowKeys.includes(record[rowKey])) {
                    records.push(record);
                }
                if (Array.isArray(record[childrenKey.value])) {
                    record[childrenKey.value].forEach((item: any) => {
                        findRecords(item, selectedRowKeys, rowKey);
                    });
                }
            };
            (currentDataSource.value || []).forEach(item => {
                findRecords(item, selectedRowKeys, rowKey);
            });
            return records;
        } else {
            return (currentDataSource.value || []).filter(
                (a: Record<string, any>) => selectedRowKeys.includes(a[rowKey])
            );
        }
    };

    const findRootRecord = (
        record: Record<string, any>
    ): Record<string, any> => {
        const parent = recordParentMap[record[rowKey.value]];
        if (parent) {
            return findRootRecord(parent);
        }
        return record;
    };

    const genParentPath = (record: any) => {
        const list: string[] = [];
        let parentRecord = findParentRecord(record);
        while (parentRecord[rowKey.value]) {
            list.push(parentRecord[rowKey.value]);
            parentRecord = findParentRecord(parentRecord);
        }
        return list;
    };

    const genNewParentRecord = (
        parentPath: string[],
        newSource: any[]
    ): [any[], any] => {
        let currentParent: any;
        let current = newSource;
        const getRecord = (key: string, record: any[]) => {
            currentParent = record.find(a => a[rowKey.value] === key);
            current = currentParent![childrenKey.value] as any[];
        };
        if (parentPath.length === 0) {
            currentParent = {
                [childrenKey.value]: current,
            };
        } else {
            parentPath.reverse().forEach(p => {
                getRecord(p, current);
            });
        }
        return [current, currentParent];
    };

    const sortRecord = (newIndex: number, oldIndex: number) => {
        const [desRecord, dragRecord] = findRecordByIndex(newIndex, oldIndex);
        let desParentRecord = findParentRecord(desRecord);
        const dragParentRecord = findParentRecord(dragRecord);
        if (desParentRecord[parentKey.value] === dragRecord[parentKey.value]) {
            desParentRecord = dragParentRecord;
        }
        let newSource: Array<Record<string, any>> = toRaw(
            currentDataSource.value
        );
        const desPath: string[] = genParentPath(desRecord);
        const dragPath: string[] = genParentPath(dragRecord);

        let [current, currentParent] = genNewParentRecord(dragPath, newSource);
        currentParent![childrenKey.value] = current.filter(
            a => a[rowKey.value] !== dragRecord[rowKey.value]
        );
        if (!currentParent![rowKey.value]) {
            newSource = currentParent[childrenKey.value];
        }

        [current, currentParent] = genNewParentRecord(desPath, newSource);
        const newDesRecord = current.find(
            a => a[rowKey.value] === desRecord[rowKey.value]
        );
        const newDesIndex = current.indexOf(newDesRecord!);
        current.splice(
            newDesIndex + (oldIndex < newIndex ? 1 : 0),
            0,
            toRaw(dragRecord)
        );

        if (!currentParent![rowKey.value]) {
            newSource = currentParent[childrenKey.value];
        }
        return { desRecord, dragRecord, newSource };
    };

    const findRecordByIndex = (...params: number[]) => {
        let count = 0;
        let record = currentDataSource.value[0];
        const result: Array<any> = [];
        const index = Math.max(...params);
        while (count < index) {
            if (params.includes(count + 1)) {
                result[params.indexOf(count + 1)] = record;
            }
            const r = findNextRecord(record);
            record = r;
            count++;
        }
        return result;
    };

    return {
        findPrevRecord,
        findNextRecord,
        findParentRecord,
        findAllRecords,
        findRootRecord,
        findRecordByIndex,
        sortRecord,
        expandMode,
    };
};

const useEnhancedColumns = (
    columns: Ref<ITableColumnType | undefined>,
    colSpanItems: Ref<ColSpanItemType[]>,
    rowSpanItems: Ref<(string | symbol)[]>,
    findPrevRecord: (
        record: Record<string, any>
    ) => Record<string, any> | undefined,
    findNextRecord: (
        record: Record<string, any>
    ) => Record<string, any> | undefined,
    rowSpanKeyResolve: Ref<RowspanKeyResolveType | undefined>,
    addRowSpanMap: (
        parentKey: string,
        parent: any,
        childKey: string,
        child: any
    ) => void,
    rowKey: ComputedRef<string>
) => {
    const enhanceColumns = (col: EnhanceColType) => {
        if ((col as any)[enhancedKey]) {
            return;
        }

        col.key = (col.key || col.dataIndex || uuid(4, 16))?.toString();

        // if (col.ellipsis) {
        //     if (col.customCell) {
        //         const oldCustomCell = col.customCell;
        //         //@ts-ignore
        //         col.customCell = (
        //             data: any,
        //             index?: number,
        //             column?: EnhanceColType
        //         ) => {
        //             const r = oldCustomCell(data, index, column);
        //             return {
        //                 ...r,
        //                 ellipsis: col.ellipsis,
        //                 titleTip: (title: string) => {
        //                     return col.titleTip ? col.titleTip(data) : title;
        //                 },
        //             } as AdditionalProps;
        //         };
        //     } else {
        //         col.customCell = (
        //             data: any,
        //             index?: number,
        //             column?: ColumnType<any>
        //         ) => {
        //             return {
        //                 ellipsis: col.ellipsis,
        //                 titleTip: (title: string) => {
        //                     return col.titleTip ? col.titleTip(data) : title;
        //                 },
        //             } as AdditionalProps;
        //         };
        //     }
        // }

        if (col[visibleKey] === undefined) {
            col[visibleKey] = true;
        }

        if (typeof col.colSpan === "number") {
            const index = realColumns.value.indexOf(col);
            for (let i = 1; i < col.colSpan; i++) {
                realColumns.value[i + index].colSpanTarget = col;
            }
        }

        const customCellFnList: Array<
            (
                data: any,
                index: number,
                column: ColumnType<any>
            ) => AdditionalProps
        > = [];
        if (col.customCell) {
            customCellFnList.push(col.customCell);
        }
        if (col.ellipsis) {
            customCellFnList.push(
                (data: any, index?: number, column?: ColumnType<any>) => {
                    return {
                        ellipsis: col.ellipsis,
                        titleTip: (title: string) => {
                            return col.titleTip ? col.titleTip(data) : title;
                        },
                    } as AdditionalProps;
                }
            );
        }
        if (rowSpanItems.value.includes(col.key)) {
            customCellFnList.push(
                useRowSpanCustomCell(
                    col.key,
                    findPrevRecord,
                    findNextRecord,
                    rowSpanKeyResolve,
                    addRowSpanMap,
                    rowKey
                )
            );
        }
        const colSpanItemList = colSpanCheck(col.key, colSpanItems);
        if (colSpanItemList.length > 0) {
            customCellFnList.push(
                useColSpanCustomCell(col.key, colSpanItemList, visibleColumns)
            );
        }

        if (col.cellStyle) {
            customCellFnList.push(
                (data: any, index?: number, column?: ColumnType<any>) => {
                    const cellStyle: any = {};
                    if (col.cellStyle!.backColor) {
                        if (typeof col.cellStyle!.backColor === "string") {
                            cellStyle.backColor = col.cellStyle!.backColor;
                        } else {
                            cellStyle.backColor =
                                col.cellStyle!.backColor(data);
                        }
                    }
                    if (col.cellStyle!.fontColor) {
                        if (typeof col.cellStyle!.fontColor === "string") {
                            cellStyle.fontColor = col.cellStyle!.fontColor;
                        } else {
                            cellStyle.fontColor =
                                col.cellStyle!.fontColor(data);
                        }
                    }
                    return { cellStyle } as AdditionalProps;
                }
            );
        }
        if (customCellFnList.length > 0) {
            col.customCell = (
                record: Record<string, any>,
                index?: number,
                column?: ColumnType<any>
            ) => {
                const result = customCellFnList.reduce(
                    (prev, next) => ({
                        ...prev,
                        ...next(record, index!, column!),
                    }),
                    {}
                );
                return result;
            };
        }
        col[enhancedKey] = true;
        //@ts-ignore
        if (col.children) {
            //@ts-ignore
            col.children.forEach(item => {
                enhanceColumns(item);
            });
        }
    };

    const realColumns = computed(() => {
        return (columns.value || []) as EnhanceColType[];
    });

    const getVisibleColumns = (cols: EnhanceColType[]) => {
        const list: EnhanceColType[] = [];
        cols.filter(col => (col as any)[visibleKey]).forEach(a => {
            //@ts-ignore
            if (a.children) {
                //@ts-ignore
                const visibleChildren = getVisibleColumns(a.children);
                list.push(...visibleChildren);
            } else {
                list.push(a);
            }
        });
        return list;
    };

    const visibleColumns = computed(() => {
        return getVisibleColumns(realColumns.value);
    });

    const getTableVisibleColumns = (cols: EnhanceColType[]) => {
        const list: EnhanceColType[] = [];
        cols.filter(col => (col as any)[visibleKey]).forEach(a => {
            //@ts-ignore
            if (a.children) {
                //@ts-ignore
                a.children = getTableVisibleColumns(a.children);
            }
            list.push(a);
        });
        return list;
    };

    const tableVisibleColumns = computed(() => {
        return getTableVisibleColumns(realColumns.value);
    });

    watch(
        () => realColumns.value,
        () => {
            realColumns.value.forEach(enhanceColumns);
        },
        { immediate: true }
    );

    const colCheck = (col: ColumnGroupType<any> | ColumnType<any>) => {
        let key = col.key as string;
        const children = (col as ColumnGroupType<any>).children;
        if (Array.isArray(children)) {
            key += children.map(a =>
                colCheck(a as ColumnGroupType<any> | ColumnType<any>)
            );
        }
        return key;
    };
    const oldColumnKeys = ref((columns.value || []).map(colCheck));
    const disposer: Array<() => void> = [];

    disposer.push(
        watch(
            () => realColumns.value.map(colCheck),
            val => {
                const newColumnKeys = val?.filter(
                    a => !oldColumnKeys.value.includes(a)
                );
                newColumnKeys?.forEach(colKey => {
                    const newCol = realColumns.value.find(
                        a => a.key === colKey
                    );
                    if (newCol) {
                        enhanceColumns(newCol);
                    }
                });
            }
        )
    );

    onUnmounted(() => {
        disposer.forEach(d => {
            d();
        });
    });

    return tableVisibleColumns;
};

const useDefaultPagination = (
    pagination: Ref<false | TablePaginationConfig | undefined>
) => {
    const showTotal = (total: string) => `共${total}条数据`;
    const pageSizeOptions = ["10", "30", "50"];
    const finalPagination = computed(() => {
        return pagination.value === false
            ? false
            : {
                  showTotal,
                  position: ["bottomCenter"],
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions,
                  ...pagination.value,
              };
    });
    return finalPagination;
};

const useAutoScroll = (
    rowSelection: Ref<TableRowSelection<any> | undefined>,
    colDefaultWidth: Ref<number>,
    visibleColumns: Ref<any[]>,
    autoScroll: Ref<boolean>
) => {
    const computedColumnsWidth = (columns: any[], defaultWidth: number) => {
        const finalRowSelectionWidth = parseInt(
            rowSelection.value?.columnWidth as unknown as string
        );
        let width =
            0 +
            (Number.isNaN(finalRowSelectionWidth) ? 0 : finalRowSelectionWidth);
        columns.forEach(col => {
            if (Array.isArray(col.children)) {
                width += computedColumnsWidth(col.children, defaultWidth);
            } else if (typeof col.width === "number") {
                width += col.width;
            } else {
                width += defaultWidth;
            }
        });

        return width;
    };

    const scroll = computed(() => {
        if (!autoScroll.value) {
            return null;
        } else {
            return {
                x: computedColumnsWidth(
                    visibleColumns.value || [],
                    colDefaultWidth.value
                ),
                y: "auto",
            };
        }
    });

    return scroll;
};

const useRowSelectCutomRow = (
    selectWhenClick: Ref<boolean>,
    rowKey: Ref<string | GetRowKey<any>>,
    rowSelection: Ref<TableRowSelection<any> | undefined>,
    dataSource: ComputedRef<any[]>,
    rowClickSelectCheck: (record: any) => boolean,
    findParentRecordChildren: (record: Record<string, any>) => any,
    findAllRecords: (selectedRowKeys: string[], rowKey: string) => any[],
    findRootRecord: (record: Record<string, any>) => Record<string, any>,
    expandMode: Ref<boolean>,
    combineRowSelection: ComputedRef<boolean>,
    context: SetupContext,
    tableRef: Ref<any>
) => {
    return (record: Record<string, any>, index: number) => {
        const finalRowKey =
            ((typeof rowKey.value === "function"
                ? rowKey.value(record)
                : rowKey.value) as string) || "key";
        return {
            onClick: (e: Event) => {
                if (
                    selectWhenClick.value &&
                    !rowClickSelectCheck(record) &&
                    rowSelection.value
                ) {
                    const selectedRowKeys =
                        rowSelection.value?.selectedRowKeys || [];
                    const selected =
                        selectedRowKeys.indexOf(record[finalRowKey]) <= -1;
                    const realSource = expandMode.value
                        ? findParentRecordChildren(record)
                        : dataSource.value;
                    const recordIndex = realSource.indexOf(record);
                    const recordKey = realSource[recordIndex][finalRowKey];
                    const keyIndex = selectedRowKeys.indexOf(recordKey);
                    if (rowSelection.value.type === "radio") {
                        selectedRowKeys.length = 0;
                        selectedRowKeys.push(recordKey);
                    } else if (selected && keyIndex === -1) {
                        selectedRowKeys.push(recordKey);
                    } else if (!selected && keyIndex > -1) {
                        selectedRowKeys.splice(keyIndex, 1);
                    }
                    let rootRecord = record;
                    if (combineRowSelection.value) {
                        rootRecord = findRootRecord(record);
                    }
                    if (tableRef.value.table) {
                        tableRef.value.table.triggerSingleSelection(
                            record[rowKey.value as string],
                            selected,
                            combineRowSelection.value
                                ? [
                                      ...(selectedRowKeys as string[]),
                                      rootRecord[rowKey.value as string],
                                  ]
                                : (selectedRowKeys as string[]),
                            e
                        );
                    }
                }
                context.emit("clickRow", record);
            },
        };
    };
};

const useColSpanCustomCell = (
    cellKey: string,
    colSpanItemsList: ColSpanItemType[],
    visibleColumns: Ref<EnhanceColType[]>
) => {
    const columnIndex = computed(() => {
        return visibleColumns.value.findIndex(a => a.key === cellKey);
    });
    return (record: Record<string, any>) => {
        const exist = colSpanItemsList.find(a => a.resolve(record));
        if (exist && columnIndex.value > -1) {
            const prevCol = visibleColumns.value[columnIndex.value - 1];
            if (prevCol === undefined || !exist.list.includes(prevCol.key)) {
                let colspan = 1;
                let nextIndex = columnIndex.value + 1;
                while (
                    visibleColumns.value[nextIndex] !== undefined &&
                    exist.list.includes(visibleColumns.value[nextIndex].key)
                ) {
                    colspan++;
                    nextIndex++;
                }
                return {
                    colspan,
                };
            } else {
                return {
                    colspan: visibleColumns.value.some(
                        a => a.key === prevCol.key
                    )
                        ? 0
                        : undefined,
                };
            }
        } else {
            return {};
        }
    };
};

const useRowSpanCustomCell = (
    cellKey: string | symbol,
    findPrevRecord: (
        record: Record<string, any>
    ) => Record<string, any> | undefined,
    findNextRecord: (
        record: Record<string, any>
    ) => Record<string, any> | undefined,
    rowSpanKeyResolve: Ref<RowspanKeyResolveType | undefined>,
    addRowSpanMap: (
        parentKey: string,
        parent: any,
        childKey: string,
        child: any
    ) => void,
    rowKey: ComputedRef<string>
) => {
    return (record: Record<string, any>, index: number) => {
        const getRecordValue = (r: any) => {
            if (rowSpanKeyResolve.value && rowSpanKeyResolve.value(cellKey)) {
                return rowSpanKeyResolve.value(cellKey)!(r);
            }
            return r[cellKey];
        };
        if (index > -1) {
            const prevRecord = findPrevRecord(record);
            const currentValue = getRecordValue(record);
            if (prevRecord && getRecordValue(prevRecord) === currentValue) {
                return { rowspan: 0 };
            } else {
                let nextRecord = findNextRecord(record);
                let rowspan = 1;
                while (
                    nextRecord &&
                    getRecordValue(nextRecord) === currentValue
                ) {
                    addRowSpanMap(
                        record[rowKey.value],
                        record,
                        nextRecord[rowKey.value],
                        nextRecord
                    );
                    nextRecord = findNextRecord(nextRecord);
                    rowspan++;
                }

                return { rowspan };
            }
        } else {
            return {};
        }
    };
};

const buildChildMap = (
    value: any[],
    recordParentMap: Record<string, any>,
    childrenKey: string,
    rowKey: string,
    parentRecord?: Record<string, any>
) => {
    value.forEach(val => {
        if (parentRecord) {
            recordParentMap[val[rowKey as string]] = parentRecord;
        }
        if (val[childrenKey]) {
            val[childrenKey].forEach((item: any) => {
                recordParentMap[item[rowKey as string]] = val;
                if (item[childrenKey]) {
                    buildChildMap(
                        item[childrenKey],
                        recordParentMap,
                        childrenKey,
                        rowKey,
                        item
                    );
                }
            });
        }
    });
};

export type DragItem = {
    newIndex: number;
    oldIndex: number;
    item: HTMLElement;
};

const useTableRowSort = (
    sortOptions: Sortable.Options,
    tbodyRef: Ref<HTMLTableSectionElement | undefined>,
    enable: Ref<boolean>,
    sortRecord: (
        newIndex: number,
        oldIndex: number
    ) => {
        desRecord: any;
        dragRecord: any;
        newSource: Record<string, any>[];
    },
    onSortChanged: (
        newSource: Record<string, any>[],
        dragRecord: any,
        desRecord: any
    ) => void
) => {
    const sortale: Ref<Sortable | undefined> = ref();
    const initSort = () => {
        if (!sortale.value && tbodyRef.value) {
            sortale.value = new Sortable(tbodyRef.value, {
                handle: ".ant-table-row",
                animation: 150,
                sort: true,
                ...sortOptions,
                onEnd: event => {
                    const { newIndex, oldIndex } = event;
                    const { newSource, dragRecord, desRecord } = sortRecord(
                        newIndex!,
                        oldIndex!
                    );
                    onSortChanged(newSource, dragRecord, desRecord);
                    if (sortOptions.onEnd) {
                        sortOptions.onEnd(event);
                    }
                },
            });
        }
    };
    if (enable.value) {
        onMounted(initSort);
    }
    watch(
        () => enable.value,
        v => {
            if (v) {
                initSort();
            }
        }
    );
};

const buildBodyWrapper =
    (tbodyRef: Ref<HTMLTableSectionElement | undefined>) =>
    (
        props: any,
        { attrs, slots, emit }: { attrs: any; slots: any; emit: any }
    ) =>
        h(
            "tbody",
            { ...props, ...attrs, ...emit, ref: tbodyRef },
            slots?.default()
        );
