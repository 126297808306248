// import { Radio } from "@intasect/ant-design-vue";
import { radioGroupProps } from "@intasect/ant-design-vue/es/radio/Group";
import { defineAsyncComponent, defineComponent } from "vue";
import '@intasect/ant-design-vue/es/radio/style'

const Radio = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/radio'))
const Group = defineAsyncComponent(() => import('@intasect/ant-design-vue/es/radio/Group'))

export const IRadioGroup = defineComponent({
    name: "i-radio-group",
    components: {
        Radio,
        Group
    },
    props: {
        ...radioGroupProps(),
    },
    setup(props) {
        return () => (
            <Group class="i-radio-group" {...props}></Group>
        );
    },
});
